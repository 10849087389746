<template>
    <v-card>
        <v-card-text>                        
            <div id="licencia">
                <header>
                    <div id="logo">
                        <img src="@/assets/Armarria.png" id="imagen" style="margin:1px">
                    </div>
                    <div align="right">
                        <v-btn class="mx-2" 
                            rounded                            
                            color="accent"
                            @click="crearPDF()"
                            >
                            <v-icon dark>
                                Pdf
                            </v-icon>
                        </v-btn>
                    </div>
                    <section>
                        <div id="datos">
                            <p id="cliente">
                                <b>{{$store.state.ent_nombre}}</b><br>{{$store.state.ent_domicilio}}<br>Email: {{$store.state.ent_email}}<br>CIF: {{$store.state.ent_cif}}
                            </p>
                        </div>
                    </section>
                    <br><br><br><br><br>
                    <div id="titulo">Solicitud licencia de {{licenciaAct.socio}}</div>                    
                    <div class="text-center"><b>({{licenciaAct.concepto}})</b></div>
                    <br>                                        
                    <div>
                        <table id="cab_licencia">
                            <tbody>
                                <tr>
                                    <td id="encabezado">
                                        <strong>{{licenciaAct.socio}}</strong><br>                                
                                        <strong>{{licenciaAct.telefono}}</strong> <br>
                                        <strong>{{licenciaAct.email}}</strong>
                                    </td>                                                
                                    <td>
                                        <div  id="fact">
                                            <p>Fecha solicitud:</p>                                                   
                                        </div>                                                    
                                    </td>
                                    <td>
                                        <div id="fact">
                                            <p>{{licenciaAct.fecha}}</p>                                                   
                                        </div>                                        
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>                    
                </header>
                <br>
                
                <br>
                <section>
                    <div>
                        <table id="lin_licencia">
                            <thead>
                                <tr id="fa">
                                    <th style="text-align:center;">TIPO LICENCIA</th>
                                    <th style="text-align:center;">RENOVACIÓN</th>
                                    <th style="text-align:center;">DOCUMENTACIÓN</th>
                                    <th style="text-align:center;">IMPORTE</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style="text-align:center;">{{licenciaAct.tipolicencia}}</td>
                                    <td style="text-align:center;">{{licenciaAct.renovacion}}</td>
                                    <td style="text-align:center;">{{licenciaAct.docuok}}</td>
                                    <td style="text-align:center;">{{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(licenciaAct.importe)}}</td>
                                </tr>
                            </tbody>                                       
                        </table>
                    </div>
                </section>
                <br>
                <br>                            
            </div>                        
            <div class="text-center">
                <v-btn @click="$emit('ocultarLicencia')" color="accent">Volver</v-btn>
            </div>
        </v-card-text>
    </v-card>
</template>
<script>
    import jsPDF from 'jspdf';
    import autoTable from 'jspdf-autotable';    
    export default {
        props: {
        licenciaAct: Object
        },
    data() {
        return {
            logo: require('@/assets/Armarria.png')
            }
        },
    methods:{                 
            crearPDF(){                
                let me=this;
                var doc = new jsPDF('p', 'pt');
                var titulo="Solicitud de " + me.licenciaAct.socio + ' - ' + me.licenciaAct.concepto;
                //Diseñamos la cabecera
                var img=new Image();
                img.src=me.logo; 
                doc.addImage(img, 'JPEG', 60, 20, 40, 50);                
                doc.setFontSize(6);
                doc.text(me.$store.state.ent_nombre,45, 75);
                doc.text(me.$store.state.ent_domicilio,45, 82);
                doc.text("Email: "+me.$store.state.ent_email,45, 89);
                doc.text("CIF: "+me.$store.state.ent_cif,45, 96);
                //Definimos el título                
                doc.setFontSize(12);
                doc.text(titulo, 300, 40,'center');
                //Generamos la tabla  y exportamos
                autoTable(doc, { html: '#cab_licencia',margin: { top: 100 }});
                autoTable(doc, { html: '#lin_licencia', styles: { halign: 'center' }}, {startY: doc.lastAutoTable.finalY + 50});                
                doc.text('Este documento unicamente tiene valor informativo', 300, 800,'center');
                doc.save('SolicitudLicencia_' + me.licenciaAct.socio +'.pdf')
            }
        }       
    }
</script>
