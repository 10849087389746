<template>
    <v-row align-start>
        <v-col>            
            <v-toolbar dark src="https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg" height='80px'>
                <v-toolbar-title>Listado de Inscripciones</v-toolbar-title>
                <v-divider
                    class="mx-2"
                    inset
                    vertical
                ></v-divider>
                <v-spacer></v-spacer>
                    <v-col cols="1">
                    <v-select v-model="SelAño"                        
                    dense
                    @change="listarConceptos"                                                
                    :items="años" label="AÑO:">
                    </v-select>
                </v-col>                    
                <v-spacer></v-spacer>
                <v-col cols="4">
                    <v-select v-model="SelConcepto"                        
                    dense
                    @input="listarInscripciones"                                              
                    :items="conceptos" label="CONCEPTO:">
                    </v-select>
                </v-col>
                <v-spacer></v-spacer>
                <v-text-field class="text-xs-center" v-model="search"  label="Búsqueda" single-line hide-details>                        
                </v-text-field><fa-icon icon="search" style="font-size: 24px; fa-10x;"/>
                <v-spacer></v-spacer>
                <v-col>
                    <v-btn class="mx-2" 
                        rounded                            
                        color="accent"
                        @click="exportPDF()"
                        >
                        <v-icon dark>
                            Pdf
                        </v-icon>
                    </v-btn>
                </v-col> 
                <v-spacer></v-spacer>
            </v-toolbar>
            <br>
            <TablaDatos
                v-bind:opcionesTabla=opcionesTabla                    
                v-bind:items=origenTabla             
                v-bind:headers=headers
                v-bind:search=search                    
            />
        </v-col>
        <v-dialog v-model="adModalProcesando" max-width="300" persistent>
            <Procesando/> 
        </v-dialog>
    </v-row>
</template>
<script>    
    import jsPDF from 'jspdf';
    import autoTable from 'jspdf-autotable'; 
    export default {
        data(){
            return {
                items:[],
                origenTabla:[],
                conceptos:[],
                años:[],
                headers: [                    
                    { text: 'Socio', value: 'socio', sortable: true,class: 'my-header-style' },
                    { text: 'F. Ins.', value: 'fecha', sortable: true,class: 'my-header-style' },
                    { text: 'Nota', value: 'textolibre', sortable: true,class: 'my-header-style' },
                    { text: 'Importe', value: 'importe', sortable: true,class: 'my-header-style' },
                    { text: 'Estado', value: 'idestadopago', sortable: true,class: 'my-header-style' },
                    ],
                search: '',
                SelAño:0,                
                SelConcepto:0,
                logo: require('@/assets/Armarria.png'),
                opcionesTabla:{Seleccion:false, AbrirCerrar:false, ActivarBloquear:false, Visualizar:false, Modificar:false, Eliminar:false, Historico:false, Recibos:false,
		Contabilizar:false, Descargar:false},                                  
            }
        },        
        created() {
            let me=this;
            me.adModalProcesando=1;
            me.listarAños();                         
        },
        methods:{
            listarInscripciones() {
                let me=this;
                me.$http.get('api/Inscripciones/FiltrarPorConcepto/'+me.SelConcepto).then(function(response){
                    me.items=response.data;
                    me.preparaTabla();
                    me.adModalProcesando=0;
                }).catch(function(error){
                    console.log(error);
                });                
            },
            preparaTabla(){
                let me=this;
                me.origenTabla=[];
                me.items.map(function(x){
                    me.origenTabla.push({idinscripciones:x.idinscripciones, socio: x.socio,fecha: me.$fechaCorta(x.fecha,2), textolibre: x.textolibre, importe:x.importe,idestadopago: x.idestadopago});
                });             
            },
            listarAños(){
                let me=this;
                var añosArray=[]; 
                me.$http.get('api/Conceptos/ListarAñosConceptosSimples').then(function(response){
                    añosArray=response.data;
                    añosArray.map(function(x){
                    me.años.push({text: x.año,value:x.año});
                    });
                    me.SelAño=añosArray[0].año;
                    me.listarConceptos();                                     
                }).catch(function(error){
                    console.log(error);
                });
            },
            listarConceptos(){
                let me=this;
                me.adModalProcesando=1;
                me.conceptos=[];
                me.items=[];
                me.SelConcepto="";
                var ConceptosArray=[]; 
                me.$http.get('api/Conceptos/ListarConceptosSimplesAños/'+this.SelAño,{}).then(function(response){
                    ConceptosArray=response.data;
                    ConceptosArray.map(function(x){
                    me.conceptos.push({text: x.descripcion,value:x.idconceptos});
                    });
                    me.SelConcepto=ConceptosArray[0].idconceptos;
                    me.listarInscripciones();
                    me.adModalProcesando=0;              
                }).catch(function(error){
                    console.log(error);
                });
            },
            exportPDF() {
              var me = this;
              me.adModalProcesando=1;
              function clone( obj ) {
                if ( obj === null || typeof obj  !== 'object' ) {
                    return obj;
                }          
                var temp = obj.constructor();
                for ( var key in obj ) {
                    temp[ key ] = clone( obj[ key ] );
                } 
                return temp;
            };
              me.registros=clone(me.items);
              var doc = new jsPDF('p', 'pt');              
              //Colocamos el logo
              var img=new Image();
                img.src=me.logo; 
              doc.addImage(img, 'JPEG', 40, 20, 40, 50);
              //Definimos el título              
              doc.text(me.conceptos.find(element => element.value === me.SelConcepto).text, 300, 40,'center');              
              //Eliminamos algunas columnas que no nos interesan:
              let euroEU = Intl.NumberFormat("es-ES", {
                    style: "currency",
                    currency: "EUR",
                });
                for (var i=0;i<me.registros.length;i++){ 
                  delete me.registros[i].idsocios;
                  delete me.registros[i].idconceptos;                  
                  delete me.registros[i].telefono1;
                  delete me.registros[i].email1;
                  delete me.registros[i].modfecha;
                  delete me.registros[i].moduser;
                  delete me.registros[i].idestadopago;
                  me.registros[i].fecha=me.$fechaCorta(me.registros[i].fecha,2);
                  me.registros[i].importe=euroEU.format(me.registros[i].importe);
                };
              //Preparamos la primera columna para numerar lineas
              for (var i=0;i<me.registros.length;i++){ 
                  me.registros[i].idinscripciones=i+1;
              };              
                //Preparamos la cabecera
              let header=['Nº', 'Socio','Fecha Ins.','Nota','Importe']
              //Prearamos las lineas            
              let values = me.registros.map((elemento) => Object.values(elemento));
              //Generamos la tabla        
              autoTable(doc, {head: [header],body: values, startY: doc.pageCount > 1? doc.autoTableEndPosY():75, styles: { fontSize: 7 }});
              //Preparamos el pie de página              
              const pages = doc.internal.getNumberOfPages();
              const pageWidth = doc.internal.pageSize.width;  //Optional
              const pageHeight = doc.internal.pageSize.height;  //Optional
              doc.setFontSize(9);                     
              for (let j = 1; j < pages + 1 ; j++) {
                    let horizontalPos = pageWidth / 2;  //Can be fixed number
                    let verticalPos = pageHeight - 10;  //Can be fixed number
                    doc.setPage(j);
                    doc.text(`${j} de ${pages}`, horizontalPos, verticalPos, {align: 'center'});
              };
              const nombreConcepto=me.conceptos.find(obj => {return Object.values(obj)[1] === me.SelConcepto}).text;
              me.adModalProcesando=0;
              doc.save('Listado_' + nombreConcepto + '.pdf');
              me.seleccion=[];
            },                                        
        }        
    }
</script>