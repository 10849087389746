<template>
  <v-card>
    <v-row align="center" justify="center">
      <v-col class="text-center" cols="12">
        <v-list width="400px" src="https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg">
          <v-list-item
            v-for="(item,index) in items"
            :key="index"
            @click="menuActionClick(item.action)"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>          
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
  ;
  import jsPDF from 'jspdf';
  import autoTable from 'jspdf-autotable';  
  export default {
    data() {
      return {
        registros:[],
        seleccion:[],
        fecha:'',
        tituloListado:'',
        isLoaded:false,
        logo: require('@/assets/Armarria.png'),
        items: [
          {icon:'mdi-view-headline', title:'Listado alfabético de Soci@s', action:'mostrarListado1'},
          {icon:'mdi-view-headline', title:'Listado de Soci@s por número', action:'mostrarListado2'},
          {icon:'mdi-view-headline', title:'Listado alfabético de Socias', action:'mostrarListado3'},
        ],
      }
    },
    created () {            
      let me=this;
      me.listarRegistros();
        },
    methods:{            
            menuActionClick(action) {
              let me=this;            
              var ahora=new Date();
              me.fecha=ahora.getDate()+'/'+(ahora.getMonth()+1)+'/'+ahora.getFullYear();
              //La siguiente funciona clona dos objetos, convirtiendolos en independientes 
              function clone( obj ) {
                if ( obj === null || typeof obj  !== 'object' ) {
                    return obj;
                }          
                var temp = obj.constructor();
                for ( var key in obj ) {
                    temp[ key ] = clone( obj[ key ] );
                } 
                return temp;
              };
              me.seleccion=clone(me.registros);
              switch (action) {
                case 'mostrarListado1':
                    me.generarListado1();
                    break;
                case 'mostrarListado2':
                    me.generarListado2();
                    break;
                case 'mostrarListado3':
                    me.generarListado3();
                    break;
                case 'mostrarListado4':
                    me.adModalListado4=1;
                    break;                        
                default:
                    me.adModalListado1=1;
              }
            },
            listarRegistros(){
                let me=this;                               
                me.$http.get('api/Socios/ListarInforme').then(function(response){
                  me.registros=response.data;                          
                }).catch(function(error){
                    console.log(error);
                });
            },
            generarListado1(){
                let me=this;
                me.tituloListado="Listado alfabético de Soci@s ("+me.fecha+")";
                me.exportPDF();   
            },
            generarListado2(){
                let me=this;
                me.tituloListado="Listado de Soci@s por número de soci@ ("+me.fecha+")";                
                me.seleccion.sort(function(a, b){ return a.numerosocio > b.numerosocio ? 1 : b.numerosocio > a.numerosocio ? -1 : 0 });
                me.exportPDF();   
            },
            generarListado3(){
                let me=this;
                me.tituloListado="Listado alfabético de Socias ("+me.fecha+")";
                const filtered = me.seleccion.filter(registro => registro.sexo === false);
                me.seleccion=filtered;                
                me.exportPDF();   
            },                 
            exportPDF() {
              var me = this;
              var doc = new jsPDF('p', 'pt');
              //Eliminamos la columna 'sexo'              
              for (var i=0;i<me.seleccion.length;i++){ 
                  delete me.seleccion[i].sexo;
              };              
              //Preparamos la cabecera              
              let header= Object.keys(me.seleccion[0]);
              header[0]='Nº';
              for (var i=1;i<header.length;i++){ 
                  header[i]=header[i].charAt(0).toUpperCase() + header[i].slice(1);;
              };
              //Preparamos la primera columna para numerar lineas
              for (var i=0;i<me.seleccion.length;i++){ 
                  me.seleccion[i].idsocios=i+1;
              };
              //Prearamos las lineas            
              let values = me.seleccion.map((elemento) => Object.values(elemento));
              //Colocamos el logo
              var img=new Image();
              img.src=me.logo;   
              doc.addImage(img, 'JPEG', 40, 20, 40, 50);
              //Definimos el título
              doc.text(me.tituloListado, 300, 40,'center');
              //Generamos la tabla        
              autoTable(doc, {head: [header],body: values, startY: doc.pageCount > 1? doc.autoTableEndPosY():75, styles: { fontSize: 7 }});
              //Preparamos el pie de página
              const pages = doc.internal.getNumberOfPages();
              const pageWidth = doc.internal.pageSize.width;  //Optional
              const pageHeight = doc.internal.pageSize.height;  //Optional
              doc.setFontSize(9);                     
              for (let j = 1; j < pages + 1 ; j++) {
                    let horizontalPos = pageWidth / 2;  //Can be fixed number
                    let verticalPos = pageHeight - 10;  //Can be fixed number
                    doc.setPage(j);
                    doc.text(`${j} de ${pages}`, horizontalPos, verticalPos, {align: 'center'});
              };
              doc.save('Listado.pdf');
              me.seleccion=[];
            },                   
      }
  }
</script>