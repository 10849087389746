<template>
    <v-row align-start>
        <v-col cols="12">
            <v-toolbar dark src="https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg" height='80px'>
                <v-toolbar-title>Listado de Apuntes Contables</v-toolbar-title>
                <v-divider
                class="mx-2"
                inset
                vertical
                ></v-divider>
                <v-spacer></v-spacer>                    
                <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-col cols="2">
                        <v-text-field
                            v-model="dateFormatted1"
                            label="Desde:"
                            hint="DD/MM/AAAA"
                            persistent-hint
                            prepend-icon="mdi-calendar"
                            v-bind="attrs"
                            @blur="date1 = parseDate(dateFormatted1)"
                            v-on="on"
                            readonly
                        ></v-text-field>
                        </v-col>
                    </template>
                    <v-date-picker
                        v-model="date1"
                        no-title
                        @input="menu1 = false"
                        min="2011-11-15"
                        :max="maxDate"
                    ></v-date-picker>
                </v-menu>
                <v-spacer></v-spacer>
                <v-menu
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-col cols="2">
                        <v-text-field
                            v-model="dateFormatted2"
                            label="Hasta:"
                            hint="DD/MM/AAAA"
                            persistent-hint
                            prepend-icon="mdi-calendar"
                            v-bind="attrs"
                            @blur="date2 = parseDate(dateFormatted2)"
                            v-on="on"
                            readonly
                        ></v-text-field>
                        </v-col>
                    </template>
                    <v-date-picker
                        v-model="date2"
                        no-title
                        @input="menu2 = false"
                        :min="minDate"
                        :max="maxDate"
                    ></v-date-picker>
                </v-menu>
                <v-spacer></v-spacer>                
                <v-text-field class="text-xs-center" v-model="search"  label="Búsqueda" single-line hide-details>                        
                </v-text-field><fa-icon icon="search" style="font-size: 24px; fa-10x;"/>                    
                <v-spacer></v-spacer>
                <v-col>
                    <v-btn small class="mx-2" 
                        rounded                            
                        color="accent"
                        @click="crearPDF()"
                        >
                        <v-icon dark>
                            Pdf
                        </v-icon>
                    </v-btn>                   
                </v-col>
                <v-spacer></v-spacer>
            </v-toolbar>
            <br>
            <TablaDatos
                v-bind:opcionesTabla=opcionesTabla                    
                v-bind:items=origenTabla
                v-bind:headers=headers
                v-bind:search=search
                v-on:listarObjetos="listarObjetos"                    
            />
            <v-dialog v-model="adModalProcesando" max-width="300" persistent>
                <Procesando/> 
            </v-dialog>
        </v-col>
    </v-row>
</template>
<script>    
    import jsPDF from 'jspdf';
    import autoTable from 'jspdf-autotable'; 
    export default {
        data(){
            return {
                items:[],
                origenTabla:[],
                adModalProcesando:0,  
                headers: [
                    { text: 'Fecha', value: 'fvalor', sortable: true,class: 'my-header-style' },
                    { text: 'Detalle', value: 'detalle', sortable: true,class: 'my-header-style' },
                    { text: 'Actividad', value: 'concepto', sortable: true,class: 'my-header-style' },
                    { text: 'Grupo Contable', value: 'grupocontable', sortable: true,class: 'my-header-style' },
                    { text: 'Importe', value: 'importe', sortable: true,class: 'my-header-style' },
                    { text: 'Saldo', value: 'saldoposterior', sortable: true,class: 'my-header-style' },                               
                ],
                search: '',
                date1: (new Date().getFullYear()+'-01-01'),                
                dateFormatted1: '01/01/'+(new Date().getFullYear()),
                menu1: false,
                date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                dateFormatted2: this.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
                menu2: false,
                logo: require('@/assets/Armarria.png'),
                opcionesTabla:{Seleccion:false, AbrirCerrar:false, ActivarBloquear:false, Visualizar:false, Modificar:false, Eliminar:false, Historico:false, Recibos:false,
		Contabilizar:false, Descargar:false},                    
            }
        },
        created() {
            let me=this;
            me.listarObjetos();              
        },
        computed: {
            maxDate() {
                const today = new Date();
                // const dd = today.getUTCDate();
                return today.toISOString().substring(0,10);
            },
            minDate() {
                return this.date1;
            },
        },
        watch: {
            date1 (val) {
                this.dateFormatted1 = this.formatDate(this.date1);
                this.listarObjetos();
            },
            date2 (val) {
                this.dateFormatted2 = this.formatDate(this.date2);
                this.listarObjetos();
            },
        },        
        methods:{           
            listarObjetos(){
                let me=this;
                me.adModalProcesando=1;
                me.$http.get('api/ApuntesContables/ListarPorFechas/'+me.date1+'/'+me.date2).then(function(response){
                    me.items=response.data;
                    me.preparaTabla();
                    me.adModalProcesando=0;
                }).catch(function(error){
                    console.log(error);
                });
            },
            preparaTabla(){
                let me=this;
                me.origenTabla=[];
                me.items.map(function(x){
                    me.origenTabla.push({idapuntescontables: x.idapuntescontables,fvalor: me.$fechaCorta(x.fvalor), detalle:x.detalle, concepto:x.concepto, grupocontable: x.grupocontable, importe: x.importe, saldoposterior:new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(x.saldoposterior),eliminable:x.eliminable});
                })                
            },
            formatDate(date) {
                if (!date) return null

                const [year, month, day] = date.split('-')
                return `${day}/${month}/${year}`
            },
            parseDate(date) {
                if (!date) return null

                const [day, month, year] = date.split('/')
                return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
            },
            crearPDF(){                
                var me = this;
                var doc = new jsPDF('l', 'pt');
                var titulo= 'Apuntes contables desde ' + me.dateFormatted1 + ' hasta ' + me.dateFormatted2;
                //Diseñamos la cabecera
                var img=new Image();
                img.src=me.logo;  
                doc.addImage(img, 'JPEG', 60, 20, 40, 50);
                //Definimos el título                
                doc.setFontSize(12);
                doc.text(titulo, 300, 40,'center');
                //Generamos la tabla  y exportamos
                let rows = [];
                let columnHeader = [];
                me.headers.forEach(element => {
                    columnHeader.push(element.text);
                });
                me.items.forEach(element => {
                    var temp = [
                        me.$fechaCorta(element.fvalor,2),
                        element.detalle,
                        element.concepto,
                        element.grupocontable,
                        new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(element.importe),
                        new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(element.saldoposterior),
                    ];                    
                    rows.push(temp);
                });
                autoTable(doc,{head:[columnHeader], body:rows, startY: doc.pageCount > 1? doc.autoTableEndPosY():80,styles:{fontSize:8},columnStyles: {4: {halign: 'right'},5: {halign: 'right'}}});
                //Preparamos el pie de página              
                const pages = doc.internal.getNumberOfPages();
                const pageWidth = doc.internal.pageSize.width;  //Optional
                const pageHeight = doc.internal.pageSize.height;  //Optional
                doc.setFontSize(9);                     
                for (let j = 1; j < pages + 1 ; j++) {
                        let horizontalPos = pageWidth / 2;  //Can be fixed number
                        let verticalPos = pageHeight - 10;  //Can be fixed number
                        doc.setPage(j);
                        doc.text(`${j} de ${pages}`, horizontalPos, verticalPos, {align: 'center'});
                };
                doc.save('ApuntesContables_' + me.dateFormatted1 + '-' + me.dateFormatted2 +'.pdf');
            },                       
        }        
    }
</script>