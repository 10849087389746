<template>
    <v-container fluid>
        <v-row align-start>
            <v-col cols="12">
                <Cabecera
                    v-bind:objeto=ADobjetos
                    v-bind:ocultaNuevo=ocultaNuevo
                    v-bind:ocultaBusqueda=ocultaBusqueda              
                    v-on:Nuevo="adModalNuevoEditar=1"
                    @hook:mounted="activarWatcher" 
                    ref="cabecera"                             
                />
                <br>
                <TablaDatos
                    padre="Pedidos"
                    v-bind:opcionesTabla=opcionesTabla1                    
                    v-bind:items=origenTabla1
                    v-bind:headers=cabConceptos            
                    v-on:mostrarFormActualizar="mostrarFormActualizar"
                    v-on:listarObjetos="listarObjetos1" 
                    v-on:seleccionarFila="seleccionarFila"                    
                />
                <v-container v-if="items2.length" fluid>                    
                    <br>
                    <TablaDatos
                        v-bind:opcionesTabla=opcionesTabla2                    
                        v-bind:items=origenTabla2             
                        v-bind:headers=cabInscripciones
                        v-bind:search=search
                        v-on:visualizarObjeto="visualizarObjeto"
                        v-on:listarObjetos="listarObjetos2" 
                        v-on:editarObjeto="editarObjeto"
                        v-on:mostrarEliminar="mostrarEliminar"                     
                    />
            </v-container>
            </v-col>
        </v-row>        
        <v-dialog v-model="adModalProcesando" max-width="300" persistent>
            <Procesando/> 
        </v-dialog>        
        <v-dialog v-model="adModalNuevoEditar" transition="dialog-bottom-transition" max-width="1500" persistent>
            <v-card>
                <v-card-text>
                    <br>
                    <br>
                    <v-row>
                        <v-col cols="9">                            
                            <v-card :color="'#2051C1'" dark>
                                <v-card-title class="justify-center">
                                    <p class="text-h5 font-weight-black">{{ tituloForm }} </p>                                    
                                </v-card-title>
                            </v-card>
                        </v-col>
                        <v-col cols="3" align="center">
                            <v-btn color="accent" text @click.native="ocultardetalle">Cancelar</v-btn>
                            <v-btn color="accent" @click.native="guardarPedido" :disabled="lineas.length==0">Guardar</v-btn>
                        </v-col>                        
                        <v-col cols="4" >
                            <v-select v-model="regAct.idsocios" dense 
                                :items="socios" label="Socio (1)" @change="validaMensaje=[]" :disabled="editedIndex===1">
                            </v-select>
                        </v-col>
                        <v-col cols="2">
                            <v-text-field label="Fecha Pedido" type="date" :disabled="pagadoPedAct==1"
                                v-model="regAct.fecha">
                            </v-text-field>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="3">
                                <h1><strong>Total: </strong> {{totalpedido=(calcularTotal(lineas)).toFixed(2)}} € </h1>
                        </v-col>                                       
                        <v-col cols="4">
                            <v-select v-model="idArticuloActual"
                                :items="articulosSelect" label="Articulo (2)" dense @change="articuloseleccionado" :disabled="pagadoPedAct==1||regAct.idsocios==''">                        
                            </v-select>
                        </v-col>
                        <v-col cols="2" >
                            <v-select v-model="idTallaActual"
                                :items="tallasSelect" label="Talla (3)" dense
                                :disabled="tallasSelect.length==0||pagadoPedAct==1||idArticuloActual==''" @change="errorArticulo=0"
                                >
                            </v-select>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Nota del pedido" type="text" maxLength="50"
                                v-model="regAct.textolibre" clearable
                                :disabled="regAct.idsocios==''"
                            ></v-text-field>
                        </v-col>                        
                        <v-btn color="accent" @click.native="añadirArticulo()"
                            :disabled="idTallaActual==''"
                        >Añadir artículo</v-btn>
                        <v-col cols="12" v-show="valida">
                                <div class="red--text" v-for="v in validaMensaje" :key="v" v-text="v">
                                </div>
                        </v-col>    
                        <v-spacer></v-spacer>
                        <v-col cols="3" v-if="errorArticulo">
                            <div class="red--text" v-text="errorArticulo">
                            </div>
                        </v-col>
                        <br>
                    </v-row> 
                </v-card-text>
            </v-card>               
            <v-data-table
                :headers="headerLineas"
                :items="lineas"                       
                class="elevation-1"
                :calculate-widths="false"                        
                :items-per-page=6                                               
            >
                <template slot="item" slot-scope="props">
                    <tr>
                    <td>                                                
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    class="ma-2"                                 
                                    x-small
                                    color="accent"
                                    @click="eliminarLinea(lineas,props.item)"
                                    v-bind="attrs"
                                    v-on="on"
                                    :disabled="pagadoPedAct==1"
                                    >
                                    <fa-icon :icon="['fas', 'trash-alt']" size="1x"/>
                                </v-btn>
                            </template>
                            <span>Eliminar</span>
                        </v-tooltip>
                    </td>
                    <td>{{ props.item.descripcion }}</td>
                    <td align ="center">{{ props.item.descripciontalla }}</td>
                    <td><v-text-field v-model="props.item.cantidad" dense maxLength="2" reverse type="number" :disabled="pagadoPedAct==1"></v-text-field></td>                            
                    <td align ="right">{{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(props.item.preciov)}}</td>
                    <td align ="right">{{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(props.item.precioc)}}</td>
                    <td>
                        <v-simple-checkbox :ripple="false"
                            v-model="props.item.chica"
                            :disabled="pagadoPedAct==1"                                    
                        ></v-simple-checkbox>                                                              
                    </td>
                    <td>
                        <v-simple-checkbox :ripple="false"
                            v-model="props.item.entallado"
                            :disabled="pagadoPedAct==1"                                    
                        ></v-simple-checkbox> 
                    </td>
                    <td>
                        <input v-model="props.item.variante" maxLength="15" clearable>                                
                    </td>
                    </tr>                               
                </template>
                <template slot="no-data">
                    <h3>No hay artículos agregados al pedido.</h3>
                </template>
            </v-data-table>                          
        </v-dialog>        
        <v-dialog v-model="adModalActualizarPrecios" max-width="345" persistent>
            <v-card>
                <v-card-title class="headline">¿Actualizar Precios?</v-card-title>                            
                <v-card-text style="font-size:1em">
                    Con este proceso todos los precios de los artículos incluidos en los pedidos de esta campaña (que no esten pagados) serán actualizados
                    respecto a los precios vigentes.
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="accent" text @click="ocultarFormActualizar">
                        Cancelar
                    </v-btn>                                
                    <v-btn color="accent" @click="actualizarPrecios">
                        Aceptar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="adModalPreciosActualizados" max-width="345" persistent>
            <v-card>
                <v-card-title class="headline">Actualización terminada</v-card-title>                            
                <v-card-text style="font-size:1em">
                    {{actuMensaje}}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>                                                     
                    <v-btn color="accent" @click="ocultarFormActualizado">
                        Cerrar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog max-width="1000px" v-model="albaranModal" persistent>
            <Albaran
                :albaranAct="Object.assign({},albaranAct)"                 
                v-on:ocultarAlbaran="ocultarAlbaran"             
            />    
        </v-dialog>
        <v-dialog v-model="adModalEliminar" max-width="500" persistent>
            <ModalTipo1                
                :titulo="'Eliminar ' + ADobjeto"
                :mensaje="'Estás a punto de eliminar ' + ADobjeto + ' <strong>' + 
                    ADnombre + '</strong> ¿Quieres continuar?'"
                v-bind:aceptarCancelar="true"
                v-on:cancelarClick="adModalEliminar=0"
                v-on:aceptarClick="eliminarObjeto"    
            />    
        </v-dialog>
        <v-dialog v-model="adModalRegresarHome" max-width="500" persistent>
            <ModalTipo1                
                titulo="No disponible"
                :mensaje="'No existe plazo abierto para '+ADobjetos"
                v-on:cerrarModal="$router.push({name: 'home'}).catch(() => {})"  
            />    
        </v-dialog>
    </v-container>
</template>
<script>    
    import Albaran from '../Libreria/Modales/Albaran.vue'
    import { calcularTotal} from "../Libreria/Funciones"
    export default {
        components: {
            Albaran
            },
        data(){
            return {
                items1:[],  
                items2:[],  
                socios:[],  
                lineas:[],
                lineasAnt:[],
                articulos:[],
                articulosSelect:[],
                tallas:[],
                tallasSelect:[],
                origenTabla1:[],
                origenTabla2:[],
                regAnt:{},
                albaranAct:{},
                search: '',
                adModalEliminar:0,  
                adModalNuevoEditar: 0,
                adModalProcesando:0,
                adModalActualizarPrecios:0,
                adModalPreciosActualizados:0,
                adModalRegresarHome:0,                
                regAct:{idinscripciones:'',idsocios:'',idconceptos:'',fecha:new Date().toISOString().slice(0,10),
                    textolibre:null,importe:null},
                gratuitoConceptoAct: '',
                editedIndex: -1,
                valida: 0,
                validaMensaje:[],
                idConceptoAct:'',
                pagadoPedAct:0, 
                idArticuloActual:'',
                idTallaActual:'',
                errorArticulo:null,
                totalpedido:0,
                albaranModal:0,
                actuLineas:0,
                actuMensaje:'',                
                ocultaNuevo:true,
                ocultaBusqueda:true,
                ADnombre:'',
                ADobjeto:'Pedido Ropa',
                ADobjetos:'Pedidos Ropa',                
                opcionesTabla1:{Seleccion:true, AbrirCerrar:false, ActivarBloquear:false, Visualizar:false, Modificar:false, Eliminar:false, Historico:false, Recibos:false,
		Contabilizar:false, Descargar:false, ActualizarPrecios:true},
                opcionesTabla2:{Seleccion:false, AbrirCerrar:false, ActivarBloquear:false, Visualizar:true, Modificar:true, Eliminar:true, Historico:false, Recibos:false,
		Contabilizar:false, Descargar:false},                                 
                cabConceptos: [
                    { text: 'Selecciona', value: 'selecciona', sortable: false,class: 'my-header-style' },
                    { text: 'Opciones', value: 'opciones', sortable: false,class: 'my-header-style' }, 
                    { text: 'Año', value: 'año', sortable: true,class: 'my-header-style' },
                    { text: 'Nombre', value: 'descripcion', sortable: true,class: 'my-header-style' },
                    { text: 'Tipo', value: 'tipoconcepto', sortable: false,class: 'my-header-style'  },                                        
                    { text: 'Fin de plazo Pedidos', value: 'fechafin', sortable: true,class: 'my-header-style'  },
                     { text: 'Pedidos', value: 'inscritos', sortable: true,class: 'my-header-style'  },
                    { text: 'Estado', value: 'cerrado', sortable: true,class: 'my-header-style'  }                
                ],
                cabInscripciones: [
                    { text: 'Opciones', value: 'opciones', sortable: false,class: 'my-header-style' },                   
                    { text: 'Apellidos y Nombre', value: 'apellidos', sortable: true,class: 'my-header-style' },
                    { text: 'Nombre', value: 'nombre', align: ' d-none',class: 'my-header-style' },
                    { text: 'Fecha Pedido', value: 'fecha', sortable: true,class: 'my-header-style'  },                    
                    { text: 'Nota', value: 'textolibre', sortable: true,class: 'my-header-style'  },
                    { text: 'Importe', value: 'importe', sortable: true, width: "8%",class: 'my-header-style'  },
                    { text: 'Pagado', value: 'idestadopago', sortable: true,class: 'my-header-style'  }
                ],
                headerLineas: [
                    { text: 'Opciones', value: 'opciones', sortable: false,class: 'my-header-style' },                   
                    { text: 'Artículo', value: 'descripcion', sortable: false, width: '200px',class: 'my-header-style' },
                    { text: 'Talla', value: 'descripciontalla', sortable: false,class: 'my-header-style' },                    
                    { text: 'Cantidad', value: 'cantidad', sortable: false, width: "5%",class: 'my-header-style'},
                    { text: 'Precio Venta', value: 'preciov', sortable: false,class: 'my-header-style' },
                    { text: 'Precio Compra', value: 'precioc', sortable: false,class: 'my-header-style' },
                    { text: 'Chica', value: 'chica', sortable: false,class: 'my-header-style'  },
                    { text: 'Entallado', value: 'entallado', sortable: false,class: 'my-header-style'  },
                    { text: 'Variante', value: 'variante', sortable: false, width: '200px',class: 'my-header-style'  }
                ],
            }
        },        
        computed: {
            tituloForm() {
                let me=this;                
                return me.editedIndex === -1 ? 'Nuevo Pedido' : 'Modificar Pedido de '+me.regAct.socio;
            }                   
        },
        created() {
            let me=this;
            me.adModalProcesando=1;            
            me.listarObjetos1();
            me.listarArticulos();
            me.listarTallas();            
        },
        methods:{
            calcularTotal,
            activarWatcher(){
                let me=this;
                me.$watch(
                "$refs.cabecera.search",
                (new_value, old_value) => (me.search = new_value)
                    );
            },                       
            listarObjetos1(){
                let me=this;
                me.$http.get('api/Conceptos/ListarTiendaAbierto').then(function(response){
                    me.items1=response.data;
                    if (!me.items1.length) {
                        me.adModalProcesando=0;
                        me.adModalRegresarHome=1;
                    }
                    me.preparaTabla1();
                    me.adModalProcesando=0;
                }).catch(function(error){
                    console.log(error);
                });
            },
            preparaTabla1(){
                let me=this;
                me.origenTabla1=[];
                me.items1.map(function(x){
                    me.origenTabla1.push({idconceptos: x.idconceptos,año: x.año, descripcion:x.descripcion,tipoconcepto:x.tipoconcepto, fechafin: me.$fechaCorta(x.fechafin,2), inscritos:x.inscritos, cerrado: x.cerrado});
                })                
            },
            listarObjetos2(){
                let me=this;                                 
                me.$http.get('api/Inscripciones/FiltrarPorConcepto/'+me.idConceptoAct).then(function(response){
                    me.items2=response.data;
                    me.preparaTabla2();
                    me.adModalProcesando=0;                    
                }).catch(function(error){
                    console.log(error);                   
                });
            },
            preparaTabla2(){
                let me=this;
                me.origenTabla2=[];
                me.items2.map(function(x){
                    me.origenTabla2.push({idinscripciones: x.idinscripciones,socio: x.socio, fecha: me.$fechaCorta(x.fecha,2), textolibre: x.textolibre, importe:x.importe, idestadopago: x.idestadopago});
                })                
            },
            listarLineas(id,mostrarAlbaran){
                let me=this;               
                me.$http.get('api/LineasPedidos/ListarPorPedido/'+id).then(function(response){                    
                    me.lineas=response.data;
                    if (mostrarAlbaran) {
                        me.albaranAct.lineas=me.lineas;
                        me.albaranModal=1;
                        me.adModalProcesando=0;
                    }
                }).catch(function(error){
                    console.log(error);
                });
            },
            listarSocios(){
                let me=this; 
                var socioArray=[];                
                me.$http.get('api/Socios/ListarActivos').then(function(response){
                socioArray=response.data;                
                socioArray.map(function(x){
                me.socios.push({text: x.apellidos+', '+x.nombre+' ('+x.numerosocio+')',value:x.idsocios});                 
                    });                        
                }).catch(function(error){
                    console.log(error);                
                });                
            },
            listarArticulos(){
                let me=this;
                me.$http.get('api/Articulos/ListarActivos').then(function(response){
                    me.articulos=response.data;
                    me.articulos.map(function(x){
                    me.articulosSelect.push({text: x.descripcion,value:x.idarticulos});                    
                    });
                }).catch(function(error){
                    console.log(error);
                });               
            },
            listarTallas(){
                let me=this;
                me.$http.get('api/Tallas/Listar').then(function(response){
                    me.tallas=response.data;
                }).catch(function(error){
                    console.log(error);
                });
            },
            actualizarPrecios(){                
                let me=this;
                me.adModalProcesando=1;
                me.adModalActualizarPrecios=0;
                me.$http.get('api/Pedidos/ActualizarPreciosTienda/'+me.regAct.idconceptos).then(function(response){                    
                    me.actuLineas=response.data;
                    if (me.actuLineas==0) {
                        me.actuMensaje="Los precios de todos los artículos eran correctos"
                    }
                    else {
                        me.actuMensaje="Se han actualizado el precio a " + me.actuLineas + " línea(s) de pedido(s)"
                    };
                    me.adModalPreciosActualizados=1;
                }).catch(function(error){
                    console.log(error);
                });                
            },
            ocultarFormActualizado(){
                let me=this;
                me.adModalPreciosActualizados=0;
                me.listarObjetos2();
            },
            ocultarFormEditarNuevo () {
                let me=this;
                me.adModalNuevoEditar = 0;
                me.borrarVariables();                
            },
            editarObjeto(id) {                
                let me=this;
                me.adModalProcesando=1;
                const item=me.items2.find(obj => {return Object.values(obj)[0] === id});
                me.regAct=Object.assign({},item);                
                me.regAnt=Object.assign({},item);
                me.listarLineas(item.idinscripciones);
                me.regAct.fecha=me.$fechaCorta(item.fecha,1);
                if (item.idestadopago==2||item.idestadopago==4) me.pagadoPedAct=1;
                me.editedIndex=1;
                me.adModalProcesando=0;
                me.adModalNuevoEditar=1;
            },
            añadirArticulo(){
                let me=this;
                me.errorArticulo=null;
                if (me.encuentraArticulo()){
                    me.errorArticulo="El artículo ya estaba añadido"
                }
                else{                    
                    me.lineas.push(
                        {idinscripciones: me.regAct.idinscripciones,
                        idarticulos: me.idArticuloActual,
                        descripcion:me.getname(me.idArticuloActual),
                        idtallas:me.idTallaActual,
                        descripciontalla:me.getsize(me.idTallaActual),                        
                        cantidad:1,
                        preciov:me.getpricev(me.idArticuloActual),
                        precioc:me.getpricec(me.idArticuloActual),
                        chica:false,
                        entallado:false,
                        variante:''}
                    );
                    me.ordenarlineas();
                    me.idArticuloActual='';
                    me.idTallaActual='';
                }
            },
            encuentraArticulo(){
                let me=this;
                var sw=0;
                for(var i=0;i<me.lineas.length;i++){
                    if (me.lineas[i].idarticulos==me.idArticuloActual && me.lineas[i].idtallas==me.idTallaActual){
                        sw=1;
                    }
                }
                return sw;
            },
            ordenarlineas(){
                let me=this;
                me.lineas.sort(function(a, b) {
                var nameA = a.descripcion
                var nameB = b.descripcion
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
                });                
            },            
            eliminarLinea(arr,item){
                var i= arr.indexOf(item);
                if (i!==-1){
                    arr.splice(i,1);
                }
            },
            borrarVariables(){
                let me=this;               
                me.regAct={idinscripciones:'',idsocios:'',idconceptos:me.regAct.idconceptos,fecha:new Date().toISOString().slice(0,10),
                    textolibre:null,importe:null};                              
                me.pagadoPedAct=0;              
                me.editedIndex=-1;
                me.validaMensaje=[];
                me.lineas=[];
                me.idArticuloActual='';
                me.idTallaActual='';                                 
                me.editedIndex=-1;
                me.errorArticulo=null;
                me.validaMensaje=[];
                me.totalpedido=0;
                me.albaranAct={};
            },            
            validarFormulario(){ 
                let me=this;               
                me.valida=0;
                me.validaMensaje=[];                                            
                if (me.regAct.fecha.length!=10 || parseInt(me.regAct.fecha.substring(0,4))<me.$añomin || parseInt(me.regAct.fecha.substring(0,4))>me.$añomax){
                    me.validaMensaje.push("El año del FECHA PEDIDO no es correcta");
                }                
                if (me.regAct.idsocios<1){
                    me.validaMensaje.push("Debes seleccionar un SOCIO");
                }
                 if (me.encuentraSocio()){
                    me.validaMensaje.push("Ya existe un pedido para este Socio");
                 }                                              
                if (me.validaMensaje.length){
                    me.valida=1;
                }
                return me.valida;
            },
            seleccionarFila(id){                
                let me=this;
                me.adModalProcesando=1;
                const item=me.items1.find(obj => {return Object.values(obj)[0] === id});
                me.regAct.idconceptos=id;
                me.idConceptoAct=id;
                me.gratuitoConceptoAct=item.gratuito;            
                me.regAct.importe=item.precio;
                me.ocultaBusqueda=false;
                me.ocultaNuevo=false;
                me.listarObjetos2();
                me.listarSocios();               
            },
            encuentraSocio(){
                let me=this;
                var sw=0;
                me.items2.forEach(element => {
                    if (element.idsocios==me.regAct.idsocios && element.idinscripciones!=me.regAct.idinscripciones) sw=1
                });
                return sw;
            },
            guardarPedido () {
                let me=this;
                me.adModalProcesando=1;
                const regTmp=Object.assign({},me.regAct);/* Creamos un objeto temporal para este metodo, que no afecte al exterior */
                if (me.validarFormulario()){
                    me.adModalProcesando=0;
                    return; /* Si no pasa la validación de campos regresamos al formulario */
                } 
                if (regTmp.idinscripciones=='') regTmp.idinscripciones=null;
                regTmp.fecha=me.$fechaLarga(regTmp.fecha);
                if (me.gratuitoConceptoAct==1) regTmp.importe=null;
                regTmp.moduser=me.$store.state.usuario.nombre;
                if (me.$hasChanged(regTmp,me.regAnt) || me.$hasChanged(me.lineas,me.lineasAnt)){
                    regTmp.importe=me.totalpedido;
                    regTmp.lineas=me.lineas;
                    me.$http.put('api/Pedidos/Registrar',regTmp).then(function(response){                        
                        me.borrarVariables();
                        me.listarObjetos1();
                        me.listarObjetos2();
                        me.adModalNuevoEditar=0;
                        me.adModalProcesando=0;                                       
                    }).catch(function(error){
                        console.log(error);
                    });
                    }
                else {
                        me.borrarVariables();
                        me.adModalNuevoEditar=0;
                        me.adModalProcesando=0;
                    }                                         
            },   
            ocultaradModalEliminar(){
                let me=this;
                me.adModalEliminar=0;
            },
            mostrarEliminar(id){
                let me=this;
                const item=me.items2.find(obj => {return Object.values(obj)[0] === id});
                me.ADnombre=item.socio;                
                me.regAct.idinscripciones=id;
                me.adModalEliminar=1;
            },
            eliminarObjeto() {                
                let me=this;
                me.adModalProcesando=1;                                       
                me.$http.put('api/Pedidos/Eliminar/'+me.regAct.idinscripciones,{}).then(function(response){                       
                    me.adModalEliminar=0;
                    me.regAct.idinscripciones="";
                    me.listarObjetos1();
                    me.listarObjetos2();
                    me.adModalProcesando=0;                           
                }).catch(function(error){
                    console.log(error);
                });
            },
            mostrarFormActualizar(id){
                let me=this;
                me.regAct.idconceptos=id;
                me.adModalActualizarPrecios=1;                
            },
            ocultarFormActualizar(){
                let me=this;
                me.adModalActualizarPrecios=0;
                me.adModalProcesando=0;
            },
            ocultardetalle(){
                let me=this;                
                me.adModalNuevoEditar=0;
                me.borrarVariables();
            },
            articuloseleccionado(val){
                let me=this;
                me.tallasSelect=[];
                var datosarticulo=me.articulos.find(datos => datos.idarticulos==val);
                var idsistematallaActual=datosarticulo.idsistematalla;
                me.tallas.forEach(element =>
                    {if (element.idsistematalla==idsistematallaActual) {
                        me.tallasSelect.push({text: element.descripcion,value: element.idtallas})
                    }
                })
                me.errorArticulo=null;                               
            },
            getpricev(id){
                let me=this;
                var datosarticulo=me.articulos.find(datos=> datos.idarticulos==id);
                return datosarticulo.preciov;
            },
            getpricec(id){
                let me=this;
                var datosarticulo=me.articulos.find(datos=> datos.idarticulos==id);
                return datosarticulo.precioc;
            },
            getname(id){
                let me=this;
                var datosarticulo=me.articulos.find(datos=> datos.idarticulos==id);
                return datosarticulo.descripcion;
            },
            getsize(id){
                let me=this;
                var datostalla=me.tallas.find(datos=> datos.idtallas==id);
                return datostalla.descripcion;
            },
            visualizarObjeto(id){
                let me=this;
                me.adModalProcesando=1;
                const item=me.items2.find(obj => {return Object.values(obj)[0] === id});                
                me.albaranAct={socio: item.socio,telefono:item.telefono1,email:item.email1,fecha:me.$fechaCorta(item.fecha),textolibre: item.textolibre, concepto: me.items1.find(element => element.idconceptos==item.idconceptos).descripcion};
                me.listarLineas(id,true);
            },
            ocultarAlbaran(){
                let me=this;
                me.albaranModal=0;
            },                  
        }        
    }
</script>