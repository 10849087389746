<template>
    <v-card>
        <v-card-text>                        
            <div id="albaran">
                <header>
                    <div id="logo">
                        <img src="@/assets/Armarria.png" id="imagen" style="margin:1px">
                    </div>
                    <div align="right">
                        <v-btn class="mx-2" 
                            rounded                            
                            color="accent"
                            @click="crearPDF()"
                            >
                            <v-icon dark>
                                Pdf
                            </v-icon>
                        </v-btn>
                    </div>
                    <section>
                        <div id="datos">
                            <p id="cliente">
                                <b>{{$store.state.ent_nombre}}</b><br>{{$store.state.ent_domicilio}}<br>Email: {{$store.state.ent_email}}<br>CIF: {{$store.state.ent_cif}}
                            </p>
                        </div>
                    </section>
                    <br><br><br><br><br>
                    <div id="titulo">Pedido de {{albaranAct.socio}}</div>                    
                    <div class="text-center"><b>({{albaranAct.concepto}})</b></div>
                    <br>                                        
                    <div>
                        <table id="facliente">
                            <tbody>
                                <tr>
                                    <td id="encabezado">
                                        <strong>{{albaranAct.socio}}</strong><br>                                
                                        <strong>{{albaranAct.telefono}}</strong> <br>
                                        <strong>{{albaranAct.email}}</strong>
                                    </td>                                                                                    
                                    <td>
                                        <div  id="fact">
                                            <p>Fecha Pedido:</p>                                                   
                                        </div>                                                    
                                    </td>
                                    <td>
                                        <div id="fact">
                                            <p>{{albaranAct.fecha}}</p>                                                   
                                        </div>                                        
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>                                                                        
                                    <td>
                                        <div  id="fact">
                                            <p>TOTAL PEDIDO</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div id="fact">
                                            <b>{{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(calcularTotal(albaranAct.lineas))}}</b>
                                        </div>                               
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>                    
                </header>
                <br><br>                
                <section>
                    <div>
                        <table id="facarticulo">
                            <thead>
                                <tr id="fa">
                                    <th>CANT</th>
                                    <th>DESCRIPCION</th>
                                    <th>TALLA</th>
                                    <th>CHICA</th>
                                    <th>ENTALLADO</th>
                                    <th>VARIANTE</th>
                                    <th style="text-align:right;">PRECIO</th>                            
                                    <th style="text-align:right;">TOTAL ARTÍCULO</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="lin in albaranAct.lineas" :key="lin.idlineaspedidos">                                    
                                    <td style="text-align:center;">{{lin.cantidad}}</td>
                                    <td>{{lin.descripcion}}</td>
                                    <td style="text-align:center;">{{lin.descripciontalla}}</td>
                                    <td style="text-align:center;">{{lin.chica ? 'SI':''}}</td>
                                    <td style="text-align:center;">{{lin.entallado ? 'SI':''}}</td>
                                    <td style="text-align:center;">{{lin.variante}}</td>
                                    <td style="text-align:right;">{{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(lin.preciov)}}</td>                            
                                    <td style="text-align:right;">{{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(lin.cantidad*lin.preciov)}}</td>
                                </tr>
                                <br>
                                <tr>
                                    <td>
                                        <p v-if="albaranAct.textolibre">
                                            <b> Nota: </b> {{albaranAct.textolibre}}
                                        </p>
                                    </td>                                    
                                </tr>
                            </tbody>                            
                        </table>
                    </div>
                </section>
                <br>
            </div>                        
            <div class="text-center">
                <v-btn @click="$emit('ocultarAlbaran')" color="accent">Cerrar</v-btn>
            </div>
        </v-card-text>
    </v-card>
</template>
<script>
    import jsPDF from 'jspdf';
    import autoTable from 'jspdf-autotable';
    import { calcularTotal} from "../Funciones";    
    export default {
        props: {
        albaranAct: Object
        },
    data() {
        return {
            logo: require('@/assets/Armarria.png')
            }
        },  
    methods:{
            calcularTotal,                 
            crearPDF(){                
                let me=this;
                var doc = new jsPDF('p', 'pt');
                var titulo="Pedido de " + me.albaranAct.socio + ' - ' + me.albaranAct.concepto;
                //Diseñamos la cabecera
                var img=new Image();
                img.src=me.logo; 
                doc.addImage(img, 'JPEG', 60, 60, 40, 50);                
                doc.setFontSize(6);
                doc.text(me.$store.state.ent_nombre,45, 115);
                doc.text(me.$store.state.ent_domicilio,45, 122);
                doc.text("Email: "+me.$store.state.ent_email,45, 129);
                doc.text("CIF: "+me.$store.state.ent_cif,45, 136);
                //Definimos el título                
                doc.setFontSize(12);
                doc.text(titulo, 300, 40,'center');
                //Generamos la tabla  y exportamos
                autoTable(doc, { html: '#facliente',margin: { top: 140 }});
                autoTable(doc, { html: '#facarticulo', styles: { halign: 'center',fontSize: 8  }}, {startY: doc.lastAutoTable.finalY + 50});
                doc.text('Este documento unicamente tiene valor informativo', 300, 800,'center');
                doc.save('PedidoRopa_' + me.albaranAct.socio +'.pdf')
            }
        }       
    }
</script>
