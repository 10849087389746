<!-- Componente para gestionar tabla auxiliar con 2 campos editables ordinarios, 1 de selección y 
    SIN posibilidad de bloqueo.   -->
<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <Cabecera                    
                    v-bind:objeto=ADobjetos                    
                    v-on:Nuevo="nuevoObjeto"
                    @hook:mounted="activarWatcher" 
                    ref="cabecera"                    
                    />
                <v-dialog v-model="formEditarNuevo" max-width="400px" persistent>
                    <EditarNuevo                    
                        v-bind:formTitle=formTitle 
                        v-bind:regAct=regAct
                        v-bind:camposEditables=camposEditables 
                        v-on:ocultarFormEditarNuevo="ocultarFormEditarNuevo"
                        v-on:guardarObjeto="guardarObjeto"               
                    />    
                </v-dialog>            
                <v-dialog v-model="adModalEliminar" max-width="500" persistent>
                    <ModalTipo1                
                        :titulo="'Eliminar ' + ADobjeto"
                        :mensaje="'Estás a punto de eliminar ' + ADobjeto + ' <strong>' + 
                            ADnombre + '</strong> ¿Quieres continuar?'"
                        v-bind:aceptarCancelar="true"
                        v-on:cancelarClick="adModalEliminar=0"
                        v-on:aceptarClick="eliminarObjeto"    
                    />    
                </v-dialog>
                <v-dialog v-model="adModalImposibleEliminar" max-width="500" persistent>                    
                    <ModalTipo1
                        :titulo="'Imposible eliminar ' + ADobjeto"
                        :mensaje="'Existe(n) <strong>' + ADcontador + '</strong> registro(s) utilizando el dato <strong>' + ADnombre + '</strong>'"                        
                        v-on:cerrarModal="ocultarImposibleEliminar"          
                    />
                </v-dialog>
                <v-dialog v-model="adModalProcesando" max-width="300" persistent>
                    <Procesando/> 
                </v-dialog>
                <v-dialog v-model="adModalTextoRepetido" max-width="500" persistent>
                    <ModalTipo1
                        titulo="Valor Repetido"
                        :mensaje="'El valor <strong>' + ADtexto + '</strong> del campo <strong>' + ADcampo + '</strong> está repetido. Ya existe ese valor para otro' + ADobjeto"                        
                        v-on:cerrarModal="adModalTextoRepetido=0"          
                    />    
                </v-dialog>
                <TablaDatos
                    v-bind:opcionesTabla=opcionesTabla                    
                    v-bind:items=items 
                    v-bind:headers=headers
                    v-bind:search=search
                    v-on:editarObjeto="editarObjeto"
                    v-on:mostrarEliminar="mostrarEliminar"
                    v-on:listarObjetos="listarObjetos"                    
                />                                    
            </v-col>
        </v-row>
    </v-container>
</template>
    <script>
        export default {            
            data(){
                return {                
                    headers: [ 
                        { text: 'Opciones', value: 'opciones', sortable: false,align: 'start',class: 'my-header-style' },
                        { text: 'Descripción', value: 'descripcion', sortable: true,class: 'my-header-style' },
                        { text: 'Tipo', value: 'tipoapunte', sortable: true,class: 'my-header-style' },                              
                    ],
                    items:[],
                    search:'',
                    editedIndex: -1,
                    formEditarNuevo: 0,
                    adModalEliminar:0,
                    adModalImposibleEliminar:0,
                    adModalProcesando:0,
                    adModalTextoRepetido:0,        
                    ADnombre:'',
                    ADcontador:0,
                    ADcampo:'',
                    ADtexto:'',
                    tiposApuntes:[],                
                    regAnt:{},
                    regAct:{idgruposcontables:'',
                        descripcion:'',idtipoapunte:1}, 
                    nombreObjeto:'GruposContables',
                    camposEditables:2, 
                    ADobjeto:'Grupo Contable',
                    ADobjetos:'Grupos Contables',
                    opcionesTabla:{Seleccion:false, AbrirCerrar:false, ActivarBloquear:false, Visualizar:false, Modificar:true, Eliminar:true, Historico:false, Recibos:false,
		Contabilizar:false, Descargar:false},       
                }
            },        
            computed: {
                formTitle () {
                    let me=this;
                    return me.editedIndex === -1 ? 'Nuevo ' + me.ADobjeto : 'Actualizar ' + me.ADobjeto
                }
            },
            created() {
                let me=this;
                me.adModalProcesando=1;
                me.listarTipoApuntes();
                me.listarObjetos();
            },
            methods:{
                activarWatcher(){
                    let me=this;
                    me.$watch(
                    "$refs.cabecera.search",
                    (new_value, old_value) => (me.search = new_value)
                        );
                },
                listarObjetos(){
                    let me=this;
                    me.$http.get('api/' + me.nombreObjeto + '/Listar').then(function(response){
                        me.items=response.data;                        
                       /*  me.items.forEach(element => {
                            delete element.idtipoapunte;
                        }); */
                        me.adModalProcesando=0;
                    }).catch(function(error){
                        console.log(error);
                    });
                },            
                editarObjeto(id) {
                    let me=this;
                    const item=me.items.find(obj => {return Object.values(obj)[0] === id});                    
                    me.regAct=Object.assign({},item);
                    me.regAnt=Object.assign({},item);
                    me.añadirProps();
                    me.editedIndex=1;
                    me.formEditarNuevo = 1
                },
                nuevoObjeto() {
                    let me=this;                                              
                    me.añadirProps();
                    me.formEditarNuevo = 1
                },
                añadirProps(){
                    let me=this;
                    me.regAct= {idgruposcontables:me.regAct.idgruposcontables,
                        descripcion: {
                            tipo:'text',etiqueta:'Descripcion',valor:me.regAct.descripcion,maxlength: 40,required: true
                        },
                        idtipoapunte: {
                            tipo:'sel',opciones:me.tiposApuntes,etiqueta:'Tipo Apunte',valor:me.regAct.idtipoapunte,maxlength:10,required: true
                        }
                    };
                },
                eliminarProps(miObjeto){
                    let me=this;              
                    Object.defineProperties(miObjeto, { 
                        "descripcion": {
                            value: miObjeto.descripcion.valor
                        },
                        "idtipoapunte": {
                            value: miObjeto.idtipoapunte.valor
                        }
                    });
                    return miObjeto;
                },                
                listarApuntesContables(){ 
                    let me=this;   
                    me.$http.get('api/ApuntesContables/ApunteContablePorGrupoContable/'+me.regAct.idgruposcontables,{}).then((response) =>{
                        me.ADcontador=response.data;                                       
                    }).catch(function(error){
                        console.log(error);
                    });
                },                               
                listarTipoApuntes(){                
                    let me=this;
                    var tiposapuntesArray=[]; 
                    me.$http.get('api/TipoApunte/Listar').then(function(response){
                        tiposapuntesArray=response.data;
                        tiposapuntesArray.map(function(x){
                        me.tiposApuntes.push({text: x.descripcion,value:x.idtipoapunte});
                        }); 
                    }).catch(function(error){
                        console.log(error);
                    });
                },            
                eliminarObjeto() {
                    let me=this;
                    me.adModalProcesando=1;                                  
                    if (me.ADcontador==0) {                
                        //Código para borrar
                        me.$http.put('api/' + me.nombreObjeto + '/Eliminar/'+me.regAct.idgruposcontables,{}).then(function(response){                        
                            me.adModalEliminar=0;
                            me.borrarVariables;
                            me.listarObjetos();                           
                        }).catch(function(error){
                            console.log(error);
                        });
                    }
                    else {
                        me.adModalEliminar=0;
                        me.adModalProcesando=0;
                        me.adModalImposibleEliminar=1;
                    }
                },
                ocultarFormEditarNuevo() {                
                    let me=this;
                    me.formEditarNuevo = 0;
                    me.borrarVariables();
                    me.listarObjetos();                
                },
                borrarVariables(){                
                    let me=this;                       
                    me.regAct={idgruposcontables:'', 
                        descripcion:[],idtipoapunte:1};                             
                    me.editedIndex=-1;
                    me.ADnombre='';
                    me.ADcontador=0;
                    me.ADtexto='';
                    me.ADcampo='';                               
                },
                guardarObjeto(regAct) {
                    let me=this;
                    me.formEditarNuevo = 0;
                    me.adModalProcesando=1;
                    regAct=me.eliminarProps(regAct);                    
                    if (me.encuentraRepetición('descripcion',regAct.descripcion)==1) {
                        me.ADtexto=regAct.descripcion;
                        me.ADcampo='Descripción';
                        me.adModalProcesando=0;
                        me.adModalTextoRepetido=1;
                        me.regAct=Object.assign({},regAct);
                        me.añadirProps();
                        me.formEditarNuevo = 1;
                        return;
                    };
                    if (me.editedIndex > -1) {                    
                        //Código para editar
                        if (me.$hasChanged(regAct,me.regAnt)){
                            me.$http.put('api/' + me.nombreObjeto + '/Actualizar',regAct).then(function(response){
                                me.listarObjetos();
                                me.borrarVariables();
                                me.adModalProcesando=0;                                                        
                            }).catch(function(error){
                                console.log(error);
                            });
                        }
                        else {
                            me.borrarVariables();
                            me.listarObjetos();
                            me.adModalProcesando=0;    
                        }
                    } else {
                        //Código para guardar          
                        me.$http.post('api/' + me.nombreObjeto + '/Crear',{ 
                            'descripcion': regAct.descripcion,'idtipoapunte':regAct.idtipoapunte                                            
                        }).then(function(response){
                            me.listarObjetos();
                            me.borrarVariables();
                            me.adModalProcesando=0;                        
                        }).catch(function(error){
                            console.log(error);
                        });
                    }
                },            
                ocultarImposibleEliminar(){
                    let me=this;
                    me.borrarVariables;                
                    me.adModalImposibleEliminar=0;                
                },
                mostrarEliminar(id){
                    let me=this;
                    const item=me.items.find(obj => {return Object.values(obj)[0] === id});
                    me.regAct.idgruposcontables=id; 
                    me.listarApuntesContables();                                
                    me.ADnombre=item.descripcion; 
                    me.adModalEliminar=1;                
                },
                encuentraRepetición(campo,texto){
                    let me=this;
                    var sw=0;
                    for(var i=0;i<me.items.length;i++){
                        if (me.items[i][campo]==texto.toUpperCase() && me.items[i].idgruposcontables!=me.regAct.idgruposcontables){
                            sw=1;    
                        }
                    }               
                    return sw;
                }                      
            }       
        }
    </script>