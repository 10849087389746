<template>
    <v-container fluid>
        <v-row align-start>
            <v-col>
                <Cabecera
                    v-bind:objeto=ADobjetos
                    :ocultaNuevo='true'
                    @hook:mounted="activarWatcher"
                    ref="cabecera"                             
                />
                <TablaDatos
                    v-bind:opcionesTabla=opcionesTabla1                    
                    v-bind:items='origenTabla'
                    v-bind:headers=headers
                    v-bind:search=search                    
                    v-on:mostrarEliminar="recuperarSocio"
                    v-on:listarObjetos="listarSocios"                    
                />
            </v-col>
        </v-row>
        <v-dialog v-model="adModalProcesando" max-width="300" persistent>
            <Procesando/> 
        </v-dialog>
        <v-dialog v-model="adModalEditar" >                        
            <v-card>
                <v-card-title>
                <span class="headline">Recuperar Socio/a</span>
                </v-card-title>                
                <v-card-text>
                <v-container grid-list-md>
                    <v-row wrap>
                    <v-col cols="1">
                        <v-text-field v-model="regAct.numerosocio" label="Nº Socio" readonly>                                        
                        </v-text-field>
                    </v-col>
                    <v-col cols="2">
                        <v-text-field label="Fecha de Ingreso" type="date" 
                        v-model="regAct.ingreso"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="2">
                        <v-text-field v-model="regAct.nombre" label="Nombre" maxLength="25" autofocus clearable>                                        
                        </v-text-field>
                    </v-col>
                    <v-col cols="3">
                        <v-text-field v-model="regAct.apellidos" label="Apellidos" maxLength="50" clearable>                                        
                        </v-text-field>
                    </v-col>
                    <v-col cols="2">
                        <v-select v-model="regAct.idtipodocumento"
                        :items="tiposdocumento" label="Tipo Documento">
                        </v-select>
                    </v-col>
                    <v-col cols="2">
                        <v-text-field v-model="regAct.documento" label="Nº Documento" maxLength=9 clearable>                                        
                        </v-text-field>
                    </v-col>
                    <v-col cols="2"> 
                        <v-switch
                            v-model="regAct.sexo"
                            :label="'Hombre'"
                        ></v-switch>
                    </v-col>
                    <v-col cols="3">
                        <v-text-field v-model="regAct.direccion" label="Domicilio" maxLength="50" clearable>                                        
                        </v-text-field>
                    </v-col>
                    <v-col cols="3">
                        <v-select v-model="regAct.idmunicipios"
                        :items="municipios" label="Municipio">
                        </v-select>
                    </v-col>
                    <v-col cols="2">
                        <v-text-field v-model="regAct.telefono1" label="Teléfono1" maxLength="9" clearable>                                        
                        </v-text-field>
                    </v-col>
                    <v-col cols="2">
                        <v-text-field v-model="regAct.telefono2" label="Teléfono2" maxLength="9" clearable>                                        
                        </v-text-field>
                    </v-col>
                    <v-col cols="3">
                        <v-text-field v-model="regAct.email1" label="Email1" maxLength="50" clearable>                                        
                        </v-text-field>
                    </v-col>
                    <v-col cols="3">
                        <v-text-field v-model="regAct.email2" label="Email2" maxLength="50" clearable>                                        
                        </v-text-field>
                    </v-col>
                    <v-col cols="3">
                        <v-select v-model="regAct.idformapago"
                        :items="formaspago" label="Forma de Pago">
                        </v-select>
                    </v-col>
                    <v-col cols="1"> 
                        <v-switch
                            v-model="regAct.lopd"
                            :label="'LOPD'"
                        ></v-switch>
                    </v-col>
                        <v-col cols="2">
                        <v-text-field label="Fecha de Nacimiento" type="date" 
                        v-model="regAct.fnac"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="2">
                        <v-text-field label="Entidad"
                            v-model="bancoAct"
                            disabled
                        ></v-text-field>
                    </v-col>
                    <v-col cols="3">
                        <v-text-field v-model="regAct.iban" label="IBAN" maxLength=24 clearable>                                        
                        </v-text-field>
                    </v-col>                                
                    <v-col cols="4" v-show="valida">
                        <div class="red--text" v-for="v in validaMensaje" :key="v" v-text="v">
                        </div>
                    </v-col>
                    </v-row>
                </v-container>
                </v-card-text>                
                <v-card-actions>
                    <v-spacer></v-spacer>                                
                    <v-btn color="accent" text @click.native="closeadModalEditar">Cancelar</v-btn>
                    <v-btn color="accent" @click.native="guardarSocio">Guardar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>    
</template>
<script>
    import { validateIBAN} from "../Libreria/Funciones"
    export default {
        data(){
            return {                
                headers: [
                    { text: 'Opciones', value: 'opciones', sortable: false },
                    { text: 'Nº', value: 'numerosocio', sortable: true },
                    { text: 'Apellidos y Nombre', value: 'socio', sortable: false },                                                   
                ],
                items:[],
                municipios:[],
                formaspago:[],
                tiposdocumento:[],
                bancos:[],
                nombrecompleto: '',                 
                search: '',
                editedIndex: -1,
                regAct:{idsocios:null,numerosocio:null,apellidos:null,nombre:null,ingreso:new Date().toISOString().slice(0,10),
                direccion: null,idmunicipios: 26,municipio:null,cpostal:null,telefono1:null,telefono2:null,email1:null,email2:null,
                idformapago:1,lopd: true,fnac:null,sexo: true,eliminado:false,documento:null,idtipodocumento:1,iban:null},
                valida: 0,
                validaMensaje:[],
                formularioBloqueo: 0,
                adModalEditar: 0,
                adAccion: 0,                               
                adModalEliminar:0,
                origenTabla:[],
                ADobjetos:'Socios eliminados',
                opcionesTabla1:{Seleccion:false, AbrirCerrar:false, ActivarBloquear:false, Visualizar:false, Modificar:false, Eliminar:true, Historico:false, Recibos:false,
		Contabilizar:false, Descargar:false},
            }
        },
        computed: {
            bancoAct(){
               let me=this;
               const IBAN=me.regAct.iban;
               if (me.validateIBAN(IBAN)){
                    return me.nombreBanco(IBAN);                        
               }         
            }            
        },        
        created () {
            let me=this;
            me.adModalProcesando=1;
            me.listarSocios();
            me.listarMunicipios();
            me.listarFormaPago();
            me.listarTiposDocumento()
            me.listarBancos()
        },
        methods:{
            validateIBAN,
            activarWatcher(){
                let me=this;
                me.$watch(
                "$refs.cabecera.search",
                (new_value, old_value) => (me.search = new_value)
                    );
            },
            nombreBanco(IBAN){
                let me=this;
                if (me.validateIBAN(IBAN)){
                    const codigo=IBAN.substring(4,8);
                    var entidad=me.bancosArray.find(element => element.codigo === codigo).descripcion;
                }                
                return entidad ? entidad : 'desconocida';
            },
            listarSocios(){
                let me=this;                               
                me.$http.get('api/Socios/ListarEliminados').then(function(response){
                me.items=response.data;
                me.preparaTabla();
                me.adModalProcesando=0;
                }).catch(function(error){
                    console.log(error);
                });
            },
            preparaTabla(){
                let me=this;
                me.origenTabla=[];
                me.items.map(function(x){
                    me.origenTabla.push({idsocios: x.idsocios,numerosocio: x.numerosocio, socio: x.apellidos})                    
                })                
            },
            listarMunicipios(){
                let me=this;
                var municipiosArray=[]; 
                me.$http.get('api/Municipios/Listar').then(function(response){
                    municipiosArray=response.data;
                    municipiosArray.map(function(x){
                    me.municipios.push({text: x.nombre,value:x.idmunicipios});
                    }); 
                }).catch(function(error){
                    console.log(error);
                });
            },
            listarFormaPago(){
                let me=this;
                var formaspagoArray=[]; 
                me.$http.get('api/formapago/Listar').then(function(response){
                    formaspagoArray=response.data;
                    formaspagoArray.map(function(x){
                    me.formaspago.push({text: x.descripcion,value:x.idformapago});
                    }); 
                }).catch(function(error){
                    console.log(error);
                });
            },
            listarTiposDocumento(){
                let me=this;
                var tiposdocumentoArray=[]; 
                me.$http.get('api/tipodocumento/Listar').then(function(response){
                    tiposdocumentoArray=response.data;
                    tiposdocumentoArray.map(function(x){
                    me.tiposdocumento.push({text: x.descripcion,value:x.idtipodocumento});
                    }); 
                }).catch(function(error){
                    console.log(error);
                });
            },
            listarBancos(){
                let me=this;
                var bancosArray=[]; 
                me.$http.get('api/bancos/Listar').then(function(response){
                    bancosArray=response.data;
                    bancosArray.map(function(x){
                    me.bancos.push({text: x.descripcion,value:x.idbancos});
                    }); 
                }).catch(function(error){
                    console.log(error);
                });
            },            
            recuperarSocio(id) {
                let me=this;
                const item=me.items.find(obj => {return Object.values(obj)[0] === id});
                me.regAct=Object.assign({},item);
                me.regAct.apellidos="";
                me.regAct.ingreso=new Date().toISOString().slice(0,10);
                me.regAct.idmunicipios=26;
                me.regAct.idformapago=1;
                me.regAct.lopd=true;
                me.regAct.sexo=true;
                me.regAct.eliminado=false;
                me.regAct.idtipodocumento=1;   
                me.editedIndex=1;
                me.adModalEditar = 1
            },            
            closeadModalEditar (){
                let me=this;
                me.adModalEditar = 0;
                me.borrarVariables();
            },
            borrarVariables(){
                let me=this;
                regAct={idsocios:null,numerosocio:null,apellidos:null,nombre:null,ingreso:new Date().toISOString().slice(0,10),
                direccion: null,idmunicipios: 26,municipio:null,cpostal:null,telefono1:null,telefono2:null,email1:null,email2:null,
                idformapago:1,lopd: true,fnac:null,sexo: true,eliminado:false,documento:null,idtipodocumento:1,iban:null},
                me.validaMensaje=[];
                me.editedIndex=-1;            
            },
            guardarSocio () {
                let me=this;
                me.adModalProcesando=1;
                const regTmp=Object.assign({},me.regAct);/* Creamos un objeto temporal para este metodo, que no afecte al exterior */
                if (me.validarFormulario()){
                    return;
                }
                me.adModalEditar = 0;
                regTmp.ingreso=me.$fechaLarga(regTmp.ingreso);
                if (regTmp.fnac!=null) regTmp.fnac=me.$fechaLarga(regTmp.fnac);
                me.$http.put('api/Socios/Actualizar',regTmp).then(function(response){
                    me.listarSocios();
                    me.borrarVariables();
                    me.adModalProcesando=0;                        
                }).catch(function(error){
                    console.log(JSON.parse(JSON.stringify(error)));
                });                
            },
            validarFormulario(){
                let me=this;                
                me.valida=0;
                me.validaMensaje=[];                
                if (me.regAct.ingreso.length!=10 || parseInt(me.regAct.ingreso.substring(0,4))<2006 || parseInt(me.regAct.ingreso.substring(0,4))>me.$añomax){
                    me.validaMensaje.push("El año de FECHA DE INGRESO no es correcta");
                }               
                if (!me.regAct.idmunicipios){
                    me.validaMensaje.push("Debes seleccionar un MUNICIPIO");
                }
                if (!me.regAct.idformapago){
                    me.validaMensaje.push("Debes seleccionar una FORMA DE PAGO");
                }
                if (!me.regAct.idtipodocumento){
                    me.validaMensaje.push("Debes seleccionar un TIPO DE DOCUMENTO");
                }                
                if (me.validaMensaje.length){
                    me.valida=1;
                }
                return me.valida;
            },
        }        
    }
</script>