import Vue from 'vue'
import Vuex from 'vuex'
import decode from 'jwt-decode'
import router from './router'
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState({
    storage: window.sessionStorage,
  })],
  state: {
    token: null,
    usuario: null,
    ent_identidades: null,
    ent_nombre: null,
    ent_domicilio: null,
    ent_cif: null,
    ent_iban: null,
    ent_idtiposuscripcion: null,
    ent_email: null,
  },
  mutations: {
    setToken(state,token){
      state.token=token
    },
    setUsuario (state,usuario){
      state.usuario=usuario
    }
  },
  actions: {
    guardarToken({commit},token){
      commit("setToken", token)
      commit("setUsuario", decode(token))
      localStorage.setItem("token", token)
    },
    autoLogin({commit}){
      let token = localStorage.getItem("token")
      if (token){        
        commit("setToken", token)
        commit("setUsuario", decode(token))
      }
      router.push({name: 'home'}).catch(() => {});
    },
    salir({commit}){
      commit("setToken", null)
      commit("setUsuario", null)
      localStorage.removeItem("token")
      sessionStorage.clear();
      router.push({name: 'login'}).catch(() => {});
    },
    expirado({commit}){
      commit("setToken", null)
      commit("setUsuario", null)
      localStorage.removeItem("token")
      router.push({name: 'expirado'}).catch(() => {});
    }
  }
})
