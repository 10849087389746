<template>
    <v-container fluid>
        <v-row align-start>
            <v-col cols="12">
                <Cabecera
                    v-bind:objeto=ADobjetos
                    v-bind:ocultaNuevo=ocultaNuevo
                    v-bind:ocultaBusqueda=ocultaBusqueda              
                    v-on:Nuevo="adModalNuevoEditar=1"
                    @hook:mounted="activarWatcher" 
                    ref="cabecera"                             
                    />
                <TablaDatos
                    v-bind:opcionesTabla=opcionesTabla1                    
                    v-bind:items=origenTabla1
                    v-bind:headers=cabConceptos
                    v-on:listarObjetos="listarObjetos1" 
                    v-on:seleccionarFila="seleccionarFila"                    
                />
                <v-container v-if="items2.length" fluid>
                    <v-divider></v-divider>
                    <br>
                    <TablaDatos
                        v-bind:opcionesTabla=opcionesTabla2                    
                        v-bind:items=origenTabla2             
                        v-bind:headers=cabInscripciones
                        v-bind:search=search
                        v-on:listarObjetos="listarObjetos2" 
                        v-on:editarObjeto="editarObjeto"
                        v-on:mostrarEliminar="mostrarEliminar"                     
                    />
                </v-container>                
            </v-col>
        </v-row>        
        <v-dialog v-model="adModalProcesando" max-width="300" persistent>
            <Procesando/> 
        </v-dialog>                                 
        <v-dialog v-model="adModalNuevoEditar" max-width="500px">                           
            <v-card>
                <v-card-title>
                <span class="headline">{{ tituloForm }}</span>
                </v-card-title>                
                <v-card-text>
                <v-container grid-list-md>
                    <v-layout wrap>
                    <v-flex xs12 sm12 md12 >
                        <v-select v-model="regAct.idsocios"
                        :disabled="editedIndex===1"
                        :items="socios" label="Socio" dense>
                        </v-select>
                    </v-flex>
                    <v-flex xs6 sm6 md6>
                        <v-text-field label="Fecha Inscripción" type="date" 
                        v-model="regAct.fecha">
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 sm12 md12>
                            <v-select v-model="regAct.idtipolicencia"
                            :items="tipolicencia" label="Tipo">
                            </v-select>
                    </v-flex>
                    <v-flex xs4 sm4 md4> 
                            <v-switch
                                v-model="regAct.renovacion"
                                :label="'Renovación'"
                            ></v-switch>
                    </v-flex>
                    <v-flex xs4 sm4 md4> 
                            <v-switch
                                v-model="regAct.docuok"
                                :label="'Documentación OK'"
                            ></v-switch>
                    </v-flex>        
                    <v-flex xs12 sm12 md12>
                        <v-text-field label="Nota" type="text" 
                        v-model="regAct.textolibre" autofocus
                        :maxlength=50
                        ></v-text-field>
                    </v-flex>
                    <!-- <v-flex xs4 sm4 md4>
                            <v-text-field type="number" v-model="regAct.importe" suffix="€" label="Importe" v-if="editedIndex==1" :disabled="pagadoInscripcionActual==1">
                            </v-text-field>
                        </v-flex>                                -->
                    <v-flex xs12 sm12 md12 v-show="valida">
                        <div class="red--text" v-for="v in validaMensaje" :key="v" v-text="v">
                        </div>
                    </v-flex>
                    </v-layout>
                </v-container>
                </v-card-text>                
                <v-card-actions>
                    <v-spacer></v-spacer>                                
                    <v-btn color="accent" text @click.native="cerrarFormulario">Cancelar</v-btn>
                    <v-btn color="accent" @click.native="guardarInscripcion">Guardar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="adModalEliminar" max-width="500" persistent>
            <ModalTipo1                
                :titulo="'Eliminar ' + ADobjeto"
                :mensaje="'Estás a punto de eliminar ' + ADobjeto + ' <strong>' + 
                    ADnombre + '</strong> ¿Quieres continuar?'"
                v-bind:aceptarCancelar="true"
                v-on:cancelarClick="adModalEliminar=0"
                v-on:aceptarClick="eliminarObjeto"    
            />    
        </v-dialog>
        <v-dialog v-model="adModalRegresarHome" max-width="500" persistent>
            <ModalTipo1                
                titulo="No disponible"
                :mensaje="'No existe plazo abierto para '+ADobjetos"
                v-on:cerrarModal="$router.push({name: 'home'}).catch(() => {})"  
            />    
        </v-dialog>
    </v-container>
</template>
<script>    
    export default {        
        data(){
            return {
                items1:[],
                items2:[],
                socios:[],
                tipolicencia:[],
                origenTabla1:[],
                origenTabla2:[],
                regAnt:{},
                search: '',
                adModalEliminar:0,
                adModalNuevoEditar: 0,
                adModalProcesando:0,
                adModalRegresarHome:0,
                regAct:{idinscripcioneslicencias:'',idtipolicencia:1,renovacion:true,docuok:false,idinscripciones:'',idsocios:'',idconceptos:'',fecha:new Date().toISOString().slice(0,10),
                    textolibre:null,importe:null},
                gratuitoConceptoAct: '',
                idConceptoAct:'',
                precioConceptoAct:'',
                pagadoInsAct:0,
                editedIndex: -1,
                valida: 0,
                validaMensaje:[],
                ocultaNuevo:true,
                ocultaBusqueda:true,
                ADnombre:'',
                ADobjeto:'Solicitud Licencia',
                ADobjetos:'Solicitudes Licencia',
                opcionesTabla1:{Seleccion:true, AbrirCerrar:false, ActivarBloquear:false, Visualizar:false, Modificar:false, Eliminar:false, Historico:false, Recibos:false,
		Contabilizar:false, Descargar:false},
                opcionesTabla2:{Seleccion:false, AbrirCerrar:false, ActivarBloquear:false, Visualizar:false, Modificar:true, Eliminar:true, Historico:false, Recibos:false,
		Contabilizar:false, Descargar:false},                                 
                cabConceptos: [
                    { text: 'Selecciona', value: 'selecciona', sortable: false,class: 'my-header-style' },
                    { text: 'Año', value: 'año', sortable: true,class: 'my-header-style' },
                    { text: 'Nombre', value: 'descripcion', sortable: true,class: 'my-header-style' },                    
                    { text: 'Precio', value: 'precio', sortable: false,class: 'my-header-style'  },                    
                    { text: 'Fin de la Inscripción', value: 'fechafin', sortable: true,class: 'my-header-style'  },
                    { text: 'Inscritos/as', value: 'inscritos', sortable: true,class: 'my-header-style'  },
                    { text: 'Estado', value: 'cerrado', sortable: true,class: 'my-header-style'  }                 
                ],
                cabInscripciones: [
                    { text: 'Opciones', value: 'opciones', sortable: false,class: 'my-header-style' },                   
                    { text: 'Apellidos y Nombre', value: 'apellidos', sortable: true,class: 'my-header-style' },
                    { text: 'Nombre', value: 'nombre', align: ' d-none',class: 'my-header-style' },                    
                    { text: 'Tipo Licencia', value: 'tipolicencia', sortable: true,class: 'my-header-style'  },
                    { text: 'Renovación', value: 'renovacion', sortable: true,class: 'my-header-style'  },
                    { text: 'Docu. OK', value: 'docuok', sortable: true,class: 'my-header-style'  },
                    { text: 'Fecha Incripción', value: 'fecha', sortable: true,class: 'my-header-style'  },
                    { text: 'Nota', value: 'textolibre', sortable: true,class: 'my-header-style'  },
                    { text: 'Importe', value: 'importe', sortable: true,class: 'my-header-style'  }
                ],
            }
        },
        computed: {
            tituloForm() {
                let me=this;
                return me.editedIndex === -1 ? 'Nueva Solicitud' : 'Modificar Solicitud'
            },                    
        },
        created () {
            let me=this;
            me.adModalProcesando=1;            
            me.listarObjetos1();            
        },
        methods:{
            activarWatcher(){
                let me=this;
                me.$watch(
                "$refs.cabecera.search",
                (new_value, old_value) => (me.search = new_value)
                    );
            },       
            listarObjetos1(){
                let me=this;
                me.$http.get('api/Conceptos/ListarTemporadas').then(function(response){
                    me.items1=response.data;
                    if (!me.items1.length) {
                        me.adModalProcesando=0;
                        me.adModalRegresarHome=1;
                    }
                    me.preparaTabla1();
                    me.adModalProcesando=0;
                }).catch(function(error){
                    console.log(error);
                });
            },
            preparaTabla1(){
                let me=this;
                me.origenTabla1=[];
                me.items1.map(function(x){
                    me.origenTabla1.push({idconceptos: x.idconceptos,año: x.año, descripcion:x.descripcion, precio:x.precio, fechafin: me.$fechaCorta(x.fechafin,2), inscritos:x.inscritos, cerrado: x.cerrado});
                })                
            },
            listarObjetos2(){
                let me=this;                                 
                me.$http.get('api/InscripcionesLicencias/FiltrarPorConceptoLicencias/'+me.idConceptoAct).then(function(response){
                    me.items2=response.data;
                    me.preparaTabla2();
                    me.adModalProcesando=0;                    
                }).catch(function(error){
                    console.log(error);                   
                });
            },
            preparaTabla2(){
                let me=this;
                me.origenTabla2=[];
                me.items2.map(function(x){
                    me.origenTabla2.push({idinscripcioneslicencias: x.idinscripcioneslicencias,socio: x.socio, tipolicencia:x.tipolicencia, renovacion:x.renovacion ? "SI":"NO", docuok:x.docuok ? "SI":"NO", fecha: me.$fechaCorta(x.fecha,2), textolibre: x.textolibre, importe:x.importe, idestadopago: x.idestadopago});
                })                
            },
            listarSocios(){
                let me=this; 
                var socioArray=[];                
                me.$http.get('api/Socios/ListarActivos').then(function(response){
                socioArray=response.data;                
                socioArray.map(function(x){
                me.socios.push({text: x.apellidos+', '+x.nombre+' ('+x.numerosocio+')',value:x.idsocios});                 
                    });                        
                }).catch(function(error){
                    console.log(error);                
                });                
            },
            listarTiposLicencias(){
                let me=this;
                var tipolicenciaArray=[];
                me.$http.get('api/TipoLicencia/Listar').then(function(response){
                tipolicenciaArray=response.data;                
                tipolicenciaArray.filter(function(obj) {return !obj.bloqueado;
                }).map(function(x){me.tipolicencia.push({text: x.descripcion,value:x.idtipolicencia});                        
                });

                }).catch(function(error){
                    console.log(error);
                });
            },
            cerrarFormulario() {
                let me=this;
                me.adModalNuevoEditar = 0;
                me.borrarVariables();                
            },
            editarObjeto(id) {
                let me=this;
                const item=me.items2.find(obj => {return Object.values(obj)[0] === id});
                me.regAct=Object.assign({},item);                
                me.regAnt=Object.assign({},item);
                me.regAct.fecha=me.$fechaCorta(item.fecha,1);
                if (item.idestadopago==2||item.idestadopago==4) me.pagadoInsAct=1;
                me.editedIndex=1;               
                me.adModalNuevoEditar = 1;            
            },
            borrarVariables(){
                let me=this;                
                me.regAct={idinscripcioneslicencias:'',idtipolicencia:1,renovacion:true,docuok:false,idinscripciones:'',idsocios:'',idconceptos:'',fecha:new Date().toISOString().slice(0,10),
                    textolibre:null,importe:null};                              
                me.pagadoInsAct=0;              
                me.editedIndex=-1;
                me.validaMensaje=[];                
            },            
            validarFormulario(){ 
                let me=this;               
                me.valida=0;
                me.validaMensaje=[];                                             
                if (me.regAct.fecha.length!=10 || parseInt(me.regAct.fecha.substring(0,4))<me.$añomin || parseInt(me.regAct.fecha.substring(0,4))>me.$añomax){
                    me.validaMensaje.push("El año del FECHA DE SOLICITU no es correcta");
                }                
                if (me.regAct.idsocios<1){
                    me.validaMensaje.push("Debes seleccionar un SOCIO");
                }
                 if (me.encuentraSocio()){
                    me.validaMensaje.push("Este Socio ya tiene otra Solicitud");
                 }                                 
                if (me.regAct.idtipolicencia<1){
                    me.validaMensaje.push("Falta indicar el tipo de licencia");
                }                       
                if (me.validaMensaje.length){
                    me.valida=1;
                }
                return me.valida;
            },
            seleccionarFila(id){                
                let me=this;
                me.adModalProcesando=1;
                const item=me.items1.find(obj => {return Object.values(obj)[0] === id});
                me.regAct.idconceptos=id;
                me.idConceptoAct=id;
                me.gratuitoConceptoAct=item.gratuito;
                me.precioConceptoAct=item.precio;                
                me.regAct.importe=item.precio;
                me.ocultaBusqueda=false;
                me.ocultaNuevo=false;
                me.listarObjetos2();
                me.listarSocios();
                me.listarTiposLicencias()
            },
            encuentraSocio(){
                let me=this;
                var sw=0;
                me.items2.forEach(element => {
                    if (element.idsocios==me.regAct.idsocios && element.idinscripciones!=me.regAct.idinscripciones) sw=1
                });
                return sw;
            },
            guardarInscripcion () {
                let me=this;
                me.adModalProcesando=1;
                const regTmp=Object.assign({},me.regAct);/* Creamos un objeto temporal para este metodo, que no afecte al exterior */
                if (me.validarFormulario()){
                    me.adModalProcesando=0;
                    return; /* Si no pasa la validación de campos regresamos al formulario */
                }                                                 
                if (me.editedIndex > -1) {                    
                    //Código para editar
                    regTmp.fecha=me.$fechaLarga(regTmp.fecha);
                    if (me.gratuitoConceptoAct==1) regTmp.importe=null;
                    regTmp.moduser=me.$store.state.usuario.nombre;
                    if (me.$hasChanged(regTmp,me.regAnt)){
                        me.$http.put('api/InscripcionesLicencias/Actualizar',regTmp
                        ).then(function(response){                        
                        me.listarObjetos2();
                        me.borrarVariables();
                        me.adModalNuevoEditar=0;
                        me.adModalProcesando=0;                        
                    }).catch(function(error){
                        console.log(error);
                    });
                        }
                    else    {
                        me.borrarVariables();
                        me.adModalNuevoEditar=0;
                        me.adModalProcesando=0;
                    }
                } else {
                    //Código para guardar la inscripción                    
                    regTmp.fecha=me.$fechaLarga(regTmp.fecha);
                    regTmp.moduser=me.$store.state.usuario.nombre;
                    regTmp.idconceptos=me.idConceptoAct;
                    regTmp.importe=me.precioConceptoAct;
                    if (me.gratuitoConceptoAct) regTmp.importe=null;                     
                    me.$http.post('api/InscripcionesLicencias/Crear',regTmp).then(function(response){        
                        me.borrarVariables();
                        me.listarObjetos1();                        
                        me.listarObjetos2();
                        me.adModalNuevoEditar=0;
                        me.adModalProcesando=0;                        
                    }).catch(function(error){
                        console.log(error);
                    });
                }
            },   
            ocultarFormEliminar(){
                let me=this;
                me.adModalEliminar=0;
            },
            mostrarEliminar(id){
                let me=this;
                const item=me.items2.find(obj => {return Object.values(obj)[0] === id});
                me.ADnombre=item.socio;                
                me.regAct.idinscripcioneslicencias=id;
                me.adModalEliminar=1;
            },
            eliminarObjeto(){                
                let me=this;
                me.adModalProcesando=1;                                       
                me.$http.put('api/InscripcionesLicencias/Eliminar/'+me.regAct.idinscripcioneslicencias,{}).then(function(response){                       
                    me.adModalEliminar=0;
                    me.regAct.idinscripciones="";
                    me.listarObjetos1();
                    me.listarObjetos2();
                    me.adModalProcesando=0;                           
                }).catch(function(error){
                    console.log(error);
                });
            }
        }        
    }
</script>
