<template>
    <v-container fill-height fluid>
        <v-row align="center" justify="center">
            <v-col cols="5">
                <v-card>
                    <v-toolbar dark color="primary">
                        <fa-icon icon="key" style="font-size: 24px; fa-10x;"/>
                        <v-spacer></v-spacer>
                        <v-toolbar-title>
                            Acceso al Sistema
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-card-text>
                        <v-row align="center">
                            <v-col cols="1">
                                <fa-icon icon="user" style="font-size: 24px;"/>
                            </v-col>
                            <v-col cols="10">                                
                                <v-text-field v-model="email" color="accent" placeholder="Usuario" maxLength="50" required>
                                </v-text-field>
                                <v-spacer></v-spacer>
                            </v-col>
                        </v-row>
                        <v-row align="center">
                            <v-col cols="1">
                                <fa-icon icon="lock" style="font-size: 24px;"/>
                            </v-col>
                            <v-col cols="7">                                
                                <v-text-field v-model="password" type="password" color="accent" placeholder="Password" required>
                                </v-text-field>        
                            </v-col>
                        </v-row>
                        <v-col class="red--text" v-if="error">
                            {{error}}
                        </v-col>
                    </v-card-text>
                    <v-card-actions class="px-3 pb-3">
                        <v-spacer></v-spacer>
                        <v-col text-xs-right>                            
                            <v-btn @click="ingresar" color="accent">Acceder</v-btn>                            
                        </v-col>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog
            v-model="dialog"
            hide-overlay
            persistent
            width="300"
            >
            <v-card
                color="primary"
                dark
            >
                <v-card-text style="font-size:1em">
                Itxaron mesedez
                <v-progress-linear
                    indeterminate
                    color="white"
                    class="mb-0"
                ></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="adModalUsuarioBloqueado" max-width="500" persistent>                    
            <ModalTipo1
                :titulo="'Usuario bloqueado'"
                :mensaje="'El usuario <strong>' + email + '</strong> se encuentra bloqueado, contacta con el administrador'"                        
                v-on:cerrarModal="adModalUsuarioBloqueado=0"          
            />
        </v-dialog>        
    </v-container>
</template>
<script>
import axios from 'axios'
export default {
    data(){
        return {
            adModalUsuarioBloqueado: 0,
            email: '',
            password: '',
            error: null,
            dialog: false
        }
    },
    methods :{
        ingresar(){
            let me=this;
            me.error=null;
            if (me.password=='') me.error="No has indicado CONTRASEÑA";
            if (me.email=='') me.error="No has indicado NOMBRE DE USUARIO";            
            if (me.error!=null) return;
            me.dialog = true;                     
            axios.post('api/Usuarios/Login', {email: me.email, password: me.password})
            .then(respuesta => {
                if (respuesta.data=='bloqueado'){
                    me.dialog= false;
                    me.adModalUsuarioBloqueado=1;
                }                                
                return respuesta.data;                                
            })
            .then(data => {       
                me.$store.dispatch("guardarToken", data.token);                                                
                me.$router.push({ name: 'home' }).catch(err => {
                console.log(err);                    
                });                
            })   
        },        
    }    
}
</script>
<style>
  .v-text-field input {
    font-size: 1.3em;
  }
</style>