import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
/* import Vuetify from 'vuetify/lib/framework'; */
import es from 'vuetify/lib/locale/es'
import colors from 'vuetify/lib/util/colors'
Vue.use(Vuetify);

export default new Vuetify({
  theme: {
      options: {
        customProperties: true,
      },
    themes: {
      light: {
        primary: colors.blue.base,//colors.blue.base
        secondary: colors.green.base,//colors.green.base
        accent: colors.orange.base,//colors.orange.base
        error: colors.red.base,//colors.red.base
        info: colors.teal.base,//colors.teal.base
        success: colors.grey.base,//colors.grey.base
        warning: colors.amber.base//colors.amber.base
      },
    },
  },
    lang: {
      locales: { es },
      current: 'es',
    },
    icons: {
      iconfont: 'mdi',
    },
});
