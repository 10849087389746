<template>
    <v-row align-start>
        <v-col>            
            <v-toolbar dark src="https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg" height='80px'>
                <v-toolbar-title>Solicitudes de Licencias</v-toolbar-title>
                <v-divider
                    class="mx-2"
                    inset
                    vertical
                ></v-divider>
                <v-spacer></v-spacer>
                    <v-col cols="1">
                    <v-select v-model="SelAño"                        
                    dense
                    @change="listarTemporadas"                                                
                    :items="años" label="AÑO:">
                    </v-select>
                </v-col>                    
                <v-spacer></v-spacer>
                <v-col cols="3">
                    <v-select v-model="SelTemporada"                        
                    dense
                    @input="listarSolicitudes"                                              
                    :items="temporadas" label="TEMPORADA:">
                    </v-select>
                </v-col>
                <v-spacer></v-spacer>
                <fa-icon icon="search" style="font-size: 24px; fa-10x;"/>
                <v-text-field class="text-xs-center" v-model="search" label="Búsqueda" single-line hide-details clearable>                        
                </v-text-field>
                <v-spacer></v-spacer>
                <v-col>
                    <v-btn class="mx-2" 
                        rounded                            
                        color="accent"
                        @click="exportPDF()"
                        >
                        <v-icon dark>
                            Pdf
                        </v-icon>
                    </v-btn>
                </v-col> 
                <v-spacer></v-spacer>
            </v-toolbar>
            <br>
            <TablaDatos
                v-bind:opcionesTabla=opcionesTabla                    
                v-bind:items=origenTabla             
                v-bind:headers=headers
                v-bind:search=search                    
            />
        </v-col>
        <v-dialog v-model="adModalProcesando" max-width="300" persistent>
            <Procesando/> 
        </v-dialog>
    </v-row>
</template>
<script>    
    import jsPDF from 'jspdf';
    import autoTable from 'jspdf-autotable'; 
    export default {
        data(){
            return {
                items:[],
                origenTabla:[], 
                headers: [                    
                    { text: 'Socio', value: 'socio', sortable: true,class: 'my-header-style' },
                    { text: 'Tipo Licencia', value: 'tipolicencia', sortable: true,class: 'my-header-style' },
                    { text: 'Renovación', value: 'renovacion', sortable: true,class: 'my-header-style' },
                    { text: 'Docum.', value: 'dokuok', sortable: true,class: 'my-header-style' },
                    { text: 'F. Ins.', value: 'fecha', sortable: true,class: 'my-header-style' },
                    { text: 'Nota', value: 'textolibre', sortable: true,class: 'my-header-style' },                                   ],
                search: '', 
                años:[],
                SelAño:0,
                temporadas:[],
                SelTemporada:0,
                logo: require('@/assets/Armarria.png'),
                opcionesTabla:{Seleccion:false, AbrirCerrar:false, ActivarBloquear:false, Visualizar:false, Modificar:false, Eliminar:false, Historico:false, Recibos:false,
		Contabilizar:false, Descargar:false},                                  
            }
        },        
        created(){
            let me=this;
            me.adModalProcesando=1;
            me.listarAños();                         
        },
        methods:{
            listarSolicitudes() {
                let me=this;
                me.$http.get('api/InscripcionesLicencias/FiltrarPorConceptoLicencias/'+me.SelTemporada).then(function(response){
                    me.items=response.data;
                    me.preparaTabla();
                    me.adModalProcesando=0;
                }).catch(function(error){
                    console.log(error);
                });                
            },
            preparaTabla(){
                let me=this;
                me.origenTabla=[];
                me.items.map(function(x){
                    me.origenTabla.push({idinscripciones:x.idinscripciones, socio: x.socio,tipolicencia:x.tipolicencia,renovacion:x.renovacion ? "SI":"NO",docuok:x.docuok ? "SI":"NO",fecha: me.$fechaCorta(x.fecha,2), textolibre: x.textolibre});
                });             
            },
            listarAños(){
                let me=this;
                var añosArray=[]; 
                me.$http.get('api/Conceptos/ListarAñosTemporadas').then(function(response){
                    añosArray=response.data;
                    añosArray.map(function(x){
                    me.años.push({text: x.año,value:x.año});
                    });
                    me.SelAño=añosArray[0].año;
                    me.listarTemporadas();                 
                }).catch(function(error){
                    console.log(error);
                });
            },
            listarTemporadas(){
                let me=this;
                me.temporadas=[];
                me.items=[];
                me.SelTemporada="";
                var ConceptosArray=[]; 
                me.$http.get('api/Conceptos/ListarTemporadasAños/'+me.SelAño,{}).then(function(response){
                    ConceptosArray=response.data;
                    ConceptosArray.map(function(x){
                    me.temporadas.push({text: x.descripcion,value:x.idconceptos});
                    });
                    me.SelTemporada=ConceptosArray[0].idconceptos;
                    me.listarSolicitudes();              
                }).catch(function(error){
                    console.log(error);
                });
            },
            exportPDF() {
              let me = this;
              function clone( obj ) {
                if ( obj === null || typeof obj  !== 'object' ) {
                    return obj;
                }          
                var temp = obj.constructor();
                for ( var key in obj ) {
                    temp[ key ] = clone( obj[ key ] );
                } 
                return temp;
            };
              me.registros=clone(me.items);
              var doc = new jsPDF('p', 'pt');              
              //Colocamos el logo
              var img=new Image();
                img.src=me.logo;
              doc.addImage(img, 'JPEG', 40, 20, 40, 50);
              //Definimos el título              
              doc.text(me.temporadas.find(element => element.value === me.SelTemporada).text, 300, 40,'center');              
              //Eliminamos algunas columnas que no nos interesan:
              let euroEU = Intl.NumberFormat("es-ES", {
                    style: "currency",
                    currency: "EUR",
                });
                for (var i=0;i<me.registros.length;i++){ 
                  delete me.registros[i].idsocios;
                  delete me.registros[i].idconceptos;                                   
                  delete me.registros[i].modfecha;
                  delete me.registros[i].moduser;
                  delete me.registros[i].idestadopago;
                  delete me.registros[i].idtipolicencia;
                  delete me.registros[i].idinscripciones;
                  me.registros[i].fecha=me.$fechaCorta(me.registros[i].fecha,2);
                  me.registros[i].importe=euroEU.format(me.registros[i].importe);
                  if (me.registros[i].renovacion) me.registros[i].renovacion='Si'; else me.registros[i].renovacion='No';
                  if (me.registros[i].docuok) me.registros[i].docuok='Si'; else me.registros[i].docuok='No';
                };
              //Preparamos la primera columna para numerar lineas
              for (var i=0;i<me.registros.length;i++){ 
                  me.registros[i].idinscripcioneslicencias=i+1;
              };              
                //Preparamos la cabecera
              let header=['Nº', 'Socio','Tipo Licencia','Ren.','Doc.','Fecha Ins.','Nota','Importe']
              //Prearamos las lineas            
              let values = me.registros.map((elemento) => Object.values(elemento));
              //Generamos la tabla        
              autoTable(doc, {head: [header],body: values, startY: doc.pageCount > 1? doc.autoTableEndPosY():75, styles: { fontSize: 7 }});
              //Preparamos el pie de página              
              const pages = doc.internal.getNumberOfPages();
              const pageWidth = doc.internal.pageSize.width;  //Optional
              const pageHeight = doc.internal.pageSize.height;  //Optional
              doc.setFontSize(9);                     
              for (let j = 1; j < pages + 1 ; j++) {
                    let horizontalPos = pageWidth / 2;  //Can be fixed number
                    let verticalPos = pageHeight - 10;  //Can be fixed number
                    doc.setPage(j);
                    doc.text(`${j} de ${pages}`, horizontalPos, verticalPos, {align: 'center'});
              };
              doc.save('Listado.pdf');
              me.seleccion=[];
            },                                        
        }        
    }
</script>