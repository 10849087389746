<template>
    <v-card>
        <br>
        <div class="center">
            <fa-icon :icon="['fas', 'envelope']" size="4x" class="icon-cog iconcolor"/>
        </div>
        <v-card-title class="justify-center">Destinatari@s seleccionados</v-card-title>                            
        <v-card-text style="font-size:1em" class="text-center">
            <v-data-table
                :headers="headers"
                :items="socios"                             
                class="elevation-1"
                dense
                hide-default-footer
                disable-pagination      
                >
                <template slot="item" slot-scope="props">
                    <tr>
                        <td align="left">{{ props.index+1}}</td>
                        <td align="left">{{ props.item.apellidos}}, {{ props.item.nombre}} ({{ props.item.numerosocio}})</td>
                        <td align="left">{{ props.item.email1}}</td>
                        <td align="left">{{ props.item.email2}}</td>
                    </tr>
                </template>
                <template v-slot:no-data>
                    No existen socios
                </template>
            </v-data-table>
        </v-card-text>
        <v-card-actions class="justify-center">
            <v-spacer></v-spacer>            
            <v-btn color="accent" text @click="$emit('obtenerEmails',1)">
                Obtener archivo
            </v-btn>
            <v-spacer></v-spacer>                                
            <v-btn color="accent" text @click="$emit('obtenerEmails',2)">
                Copiar al portapapeles
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="accent" @click="$emit('cerrarModal')">
                Cerrar
            </v-btn>
            <v-spacer></v-spacer>
        </v-card-actions>
    </v-card>
</template>
<script>    
    export default {
        data() {
            return {
                headers: [                    
                { text: 'Nº', value: 'numerosocio', sortable: true,width: "1%"},
                { text: 'Soci@', value: 'socio', sortable: true,width: "35%"},
                { text: 'Nombre', value: 'nombre', align: ' d-none' },
                { text: 'Email 1', value: 'email1', sortable: true,width: "10%"},
                { text: 'Email 2', value: 'email2', sortable: true,width: "10%"}                                                      
                        ],}
                },
        props: {
        socios: Array
        }       
    }
</script>