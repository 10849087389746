<template>
    <v-container fill-height fluid>
    <v-row>
        <v-spacer></v-spacer>
        <v-col cols="4">            
            <v-card>
                <v-toolbar dark color="primary">
                    <v-toolbar-title>
                        Fin de la sesión
                    </v-toolbar-title>
                </v-toolbar>
                <v-card-title class="justify-center"> La sesión ha expirado por inactividad
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="$router.push({ name: 'login' })" color="accent">volver</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-col>
        <v-spacer></v-spacer>
    </v-row>
    </v-container>
</template>
<script>
export default {
    data(){
        return {                        
        }
    }    
}
</script>