<template>
    <v-row align-start>
        <v-col cols="12">
            <v-toolbar dark src="https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg" height='80px'>
                <v-toolbar-title>Recibos Remesados</v-toolbar-title>
                <v-divider
                    class="mx-2"
                    inset
                    vertical
                ></v-divider>
                <v-spacer></v-spacer>
                <v-col cols="1">
                    <v-select v-model="SelAño"                        
                    dense
                    @change="listarRemesas"                                                
                    :items="anos" label="AÑO:">
                    </v-select>
                </v-col>                    
                <v-spacer></v-spacer>
                <v-col cols="3">
                    <v-select v-model="SelRemesa"                        
                    dense
                    @input="listarRecibos"                                              
                    :items="remesas" label="REMESA:">
                    </v-select>
                </v-col>
                <v-spacer></v-spacer>
                <v-text-field class="text-xs-center" v-model="search"  label="Búsqueda" single-line hide-details>                        
                </v-text-field><fa-icon icon="search" style="font-size: 24px; fa-10x;"/>
                <v-spacer></v-spacer>
                <v-col cols="4">
                    <v-btn small class="mx-2" 
                        rounded                            
                        color="accent"
                        @click="crearPDFsimple()"
                        >
                        <v-icon dark>
                            Pdf
                        </v-icon>
                    </v-btn>
                    <v-btn class="mx-2" 
                        rounded                            
                        color="accent"
                        @click="crearPDFbanco()"
                        :disabled="$store.state.usuario.rol=='Vocal'"
                        >
                        <v-icon small dark>
                            Pdf Banco
                        </v-icon>
                    </v-btn>
                    <v-btn class="mx-2" 
                        rounded                            
                        color="accent"
                        @click="adModalFechaCobro=1"
                        :disabled="$store.state.usuario.rol=='Vocal'"
                        >
                        <v-icon small dark>
                            F. SEPA
                        </v-icon>
                    </v-btn>
                    <v-btn class="mx-2" 
                        rounded                            
                        color="accent"
                        @click="adModalFechaPago=1"
                        :disabled="$store.state.usuario.rol=='Vocal'"
                        >
                        <v-icon small dark>
                            F. SEPA(Transf.)
                        </v-icon>
                    </v-btn>
                </v-col>
                <v-spacer></v-spacer> 
            </v-toolbar>
        </v-col>
        <v-col>
            <br>
            <v-data-table
                ref="myTable"
                :headers="headers"
                :items="recibos"
                :search="search"
                class="elevation-1"
                dense>
                <template slot="item" slot-scope="props">
                    <tr>                    
                    <td>
                        <div v-if="props.item.recibo">                            
                            {{ props.item.recibo }}
                        </div>
                        <div v-else>
                            Inex.
                        </div>
                    </td>                    
                    <td style="text-align:right">{{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(props.item.importe)}}</td>
                    <td>{{ props.item.socio }}</td>                                       
                    <td>
                            <div v-if="props.item.idestadopago==1 || props.item.idestadopago==3">
                                <span class="red--text">{{props.item.estadopago}}</span>
                            </div>
                            <div v-else>
                                <span class="green--text">{{props.item.estadopago}}</span>
                            </div>
                    </td>
                    <td style="text-align:center">
                        <div v-if="props.item.fvalor">
                            <span>{{ $fechaCorta(props.item.fvalor,2) }}</span>
                        </div>
                        <div v-else>
                            <span> - </span>
                        </div>
                    </td>                    
                    <td>{{ props.item.remesa }}</td>                    
                    </tr>                 
                </template>                
            </v-data-table>
        </v-col>
        <v-dialog v-model="adModalFechaCobro" max-width="500">
            <ModalTipo2                
                titulo="Fecha de Cobro"
                mensaje="¿Cual va a ser la fecha de cobro?"
                tipoModal="Fecha"
                icono="calendar"
                v-on:cancelarClick="adModalFechaCobro=0"
                v-on:aceptarClick="crearFicheroSepa"    
            />
        </v-dialog>
        <v-dialog v-model="adModalFechaPago" max-width="500">
            <ModalTipo2                
                titulo="Fecha de Pago"
                mensaje="¿Cual va a ser la fecha de pago?"
                tipoModal="Fecha"
                icono="calendar"
                v-on:cancelarClick="adModalFechaPago=0"
                v-on:aceptarClick="crearFicheroSepaPago"    
            />
        </v-dialog>                    
    </v-row>
</template>
<script>    
    import jsPDF from 'jspdf';
    import autoTable from 'jspdf-autotable'
    export default {
        data(){
            return {
                recibos:[],            
                formularioEditar: false,
                headers: [
                    { text: 'Recibo', value: 'recibo', sortable: true,class: 'my-header-style' },
                    { text: 'Importe', value: 'importe', sortable: true, align:"center",class: 'my-header-style',align: 'center' },
                    { text: 'Socio', value: 'socio', sortable: true,class: 'my-header-style' },                                    
                    { text: 'Estado', value: 'estadopago', sortable: true,class: 'my-header-style' },
                    { text: 'Fecha Valor', value: 'fvalor', sortable: true,class: 'my-header-style' }],
                search: '',  
                anos:[],
                SelAño:0,
                remesas:[],
                SelRemesa:0,
                adModalFechaCobro:0,
                adModalFechaPago:0,
                bancosArray:[],
                logo: require('@/assets/Armarria.png'),                                               
            }
        },
        created () {
            let me=this;
            me.listarAños();
            me.listarBancos();                       
        },
        methods:{
            nombreBanco(IBAN){        
                let me=this;
                const codigo=IBAN.substring(4,8);         
                var entidad=me.bancosArray.find(element => element.codigo === codigo).descripcion;
                return entidad ? entidad : 'desconocida';                
            },            
            listarRecibos() {
                let me=this;
                me.$http.get('api/Recibos/RecibosPorRemesa/'+this.SelRemesa).then(function(response){
                    me.recibos=response.data;
                }).catch(function(error){
                    console.log(error);
                });                
            },
            listarBancos(){
                let me=this;                 
                me.$http.get('api/bancos/Listar').then(function(response){
                    me.bancosArray=response.data;
                }).catch(function(error){
                    console.log(error);
                });
            },
            listarAños(){
                let me=this;                
                var anosArray=[]; 
                me.$http.get('api/Remesas/ListarAnos').then(function(response){
                    anosArray=response.data;                 
                    anosArray.map(function(x){
                    me.anos.push({text:x.año,value:x.año});
                    });                    
                    me.SelAño=anosArray[0].año;                    
                    me.listarRemesas();                 
                }).catch(function(error){
                    console.log(error);
                });
            },
            listarRemesas(){                
                let me=this;
                me.remesas=[];
                me.recibos=[];
                me.SelRemesa="";
                var remesasArray=[]; 
                me.$http.get('api/Remesas/RemesasAños/'+me.SelAño,{}).then(function(response){
                    remesasArray=response.data;
                    remesasArray.map(function(x){
                    me.remesas.push({text: x.clave + ' ' + x.concepto,value:x.idremesas});
                    });
                    me.SelRemesa=remesasArray[0].idremesas;
                    me.listarRecibos();              
                }).catch(function(error){
                    console.log(error);
                });
            },
            crearPDFsimple(){                
                var me = this;
                var doc = new jsPDF('p', 'pt');
                var titulo= 'Remesa ' + me.remesas.filter(p => p.value == me.SelRemesa)[0].text;
                //Diseñamos la cabecera
                var img=new Image();
                img.src=me.logo;
                doc.addImage(img, 'JPEG', 60, 20, 40, 50);                
                doc.setFontSize(6);
                doc.text(me.$store.state.ent_nombre,45, 75);
                doc.text(me.$store.state.ent_domicilio,45, 82);
                doc.text("Email: "+me.$store.state.ent_email,45, 89);
                doc.text("CIF: "+me.$store.state.ent_cif,45, 96);
                //Definimos el título                
                doc.setFontSize(12);
                doc.text(titulo, 300, 40,'center');
                //Generamos la tabla  y exportamos
                var source =  this.$refs["myTable"];
                let rows = [];
                let columnHeader = [];
                me.headers.forEach(element => {
                    columnHeader.push(element.text);
                });
                source.items.forEach(element => {
                    var fvalor=null;
                    if (element.fvalor) {
                        fvalor=me.$fechaCorta(element.fvalor,2);
                    }
                    var temp = [
                        element.recibo,
                        new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(element.importe),
                        element.socio,
                        element.estadopago,
                        fvalor
                    ];                    
                    rows.push(temp);
                });               
                doc.setFontSize(5);
                autoTable(doc,{head:[columnHeader], body:rows, startY: doc.pageCount > 1? doc.autoTableEndPosY():100,styles:{fontSize:8}});
                //Preparamos el pie de página              
                const pages = doc.internal.getNumberOfPages();
                const pageWidth = doc.internal.pageSize.width;  //Optional
                const pageHeight = doc.internal.pageSize.height;  //Optional
                doc.setFontSize(9);                     
                for (let j = 1; j < pages + 1 ; j++) {
                        let horizontalPos = pageWidth / 2;  //Can be fixed number
                        let verticalPos = pageHeight - 10;  //Can be fixed number
                        doc.setPage(j);
                        doc.text(`${j} de ${pages}`, horizontalPos, verticalPos, {align: 'center'});
                };
                doc.save('listadoDeRecibos.pdf')
            },
            crearPDFbanco(){                
                var me = this;
                var doc = new jsPDF('l', 'pt');
                var titulo= 'Remesa ' + me.remesas.filter(p => p.value == me.SelRemesa)[0].text;
                var concepto=me.remesas.filter(p => p.value == me.SelRemesa)[0].text.substring(10);
                var remesa=me.remesas.filter(p => p.value == me.SelRemesa)[0].text.substring(0,9);
                remesa=remesa.substring(0,6)+'-'+remesa.substring(7,9);
                //Diseñamos la cabecera
                var img=new Image();
                img.src=me.logo;
                doc.addImage(img, 'JPEG', 60, 20, 40, 50);                
                doc.setFontSize(6);
                doc.text(me.$store.state.ent_nombre,45, 75);
                doc.text(me.$store.state.ent_domicilio,45, 82);
                doc.text("Email: "+me.$store.state.ent_email,45, 89);
                doc.text("CIF: "+me.$store.state.ent_cif,45, 96);
                //Definimos el título                
                doc.setFontSize(12);
                doc.text(titulo, 300, 40,'center');
                //Generamos la tabla  y exportamos                   
                let rows = [];
                let columnHeader = ['Recibo','Importe','Socio','Documento','Concepto','Banco','IBAN'];               
                me.recibos.forEach(element => {
                    var temp = [
                        element.recibo,
                        new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(element.importe),
                        element.socio,
                        element.documento,
                        concepto,
                        me.nombreBanco(element.iban),
                        element.iban
                    ];                    
                    rows.push(temp);
                });               
                doc.setFontSize(15);                
                console.error = () => {};
                autoTable(doc,{head:[columnHeader], body:rows, startY: doc.pageCount > 1? doc.autoTableEndPosY():100,styles:{fontSize:7},columnStyles: {0: {cellWidth: 80},1: {halign: 'right',cellWidth: 50},2: {cellWidth: 150},3: {cellWidth: 70},4: {cellWidth: 220},5: {cellWidth: 80},6: {cellWidth: 110}}});
                //Preparamos el pie de página              
                const pages = doc.internal.getNumberOfPages();
                const pageWidth = doc.internal.pageSize.width;  //Optional
                const pageHeight = doc.internal.pageSize.height;  //Optional
                doc.setFontSize(9);                                     
                for (let j = 1; j < pages + 1 ; j++) {
                        let horizontalPos = pageWidth / 2;  //Can be fixed number
                        let verticalPos = pageHeight - 10;  //Can be fixed number
                        doc.setPage(j);
                        doc.text(`${j} de ${pages}`, horizontalPos, verticalPos, {align: 'center'});
                };                 
                doc.save('Remesa'+remesa+'.pdf')
            },
            crearFicheroSepa(fechaCobro) {                
                let me=this;
                me.adModalFechaCobro=0; 
                fechaCobro=me.$fechaLarga(fechaCobro);
                var remesa=me.remesas.filter(p => p.value == me.SelRemesa)[0].text.substring(0,9);
                remesa=remesa.substring(0,6)+'-'+remesa.substring(7,9);
                var lineas=[],totImportes=0,totAdeudos=0;
                /* CABECERA DEL PRESENTADOR cp*/
                const cp1='01';/* Código de registro 2*/
                const cp2='19143';/* Versión del cuaderno 5*/
                const cp3='001';/* Número de dato 3*/
                var cp4='ES96000G95401832'/* Identificador del presentador 35*/
                cp4 += me.esp(35-cp4.length);
                var cp5='URGOZO ITE';/* Nombre del presentador 70*/
                cp5 += me.esp(70-cp5.length);
                const hoy = new Date();
                const cp6=hoy.getFullYear()+(("0" + (hoy.getMonth() + 1)).slice(-2))+("0"+(hoy.getUTCDate())).slice(-2);/* Fecha de creación del fichero - la de hoy*/
                var cp7='PRE'+cp6+'08000000000'+remesa;/* Identificación del fichero - 35 */
                cp7 += me.esp(35-cp7.length);
                const cp8='3008';/* Entidad receptora 4*/
                const cp9='0272';/* Oficina receptora 4*/
                const cp10=me.esp(434);/* CABECERA DEL PRESENTADOR Libre 434*/
                lineas.push(cp1+cp2+cp3+cp4+cp5+cp6+cp7+cp8+cp9+cp10);

                /* REGISTROS DEL BLOQUE DE ADEUDOS rba*/
                const rba1='02';/* Código de registro 2*/
                const rba2='19143';/* Versión del cuaderno 5*/
                const rba3='002';/* Número de dato 3*/
                var rba4='ES96000G95401832'/* Identificador del acreedor 35*/
                rba4 += me.esp(35-rba4.length);
                const rba5=fechaCobro.getFullYear()+(("0" + (fechaCobro.getMonth() + 1)).slice(-2))+("0"+(fechaCobro.getUTCDate())).slice(-2);/* Fecha de cobro - OJO VARIABLE -  8*/
                var rba6='URGOZO ITE';/* Nombre del acreedor 70*/
                rba6 += me.esp(70-rba6.length);
                const rba7=me.esp(50);/* Dirección acreedor 50*/
                const rba8=me.esp(50);/* Dirección acreedor 50*/
                const rba9=me.esp(40);/* Dirección acreedor 40*/
                const rba10=me.esp(2);/* País del acreedor Libre 2*/
                var rba11='ES3830080272443792583027';/* Cuenta del acreedor 34*/
                rba11 += me.esp(34-rba11.length);
                const rba12=me.esp(301);/* Libre 301*/
                lineas.push(rba1+rba2+rba3+rba4+rba5+rba6+rba7+rba8+rba9+rba10+rba11+rba12);

                /* REGISTROS INDIVIDUALES DE ADEUDOS ria*/
                me.recibos.forEach(x => {
                    const ria1='03';/* Código de registro 2*/
                    const ria2='19143';/* Versión del cuaderno 5*/
                    const ria3='003';/* Número de dato 3*/
                    var ria4=(new String(x.recibo)).toUpperCase();/* Referencia del adeudo 35*/
                    ria4 += me.esp(35-ria4.length);
                    var ria5=(new String(x.recibo)).toUpperCase();/* Referencia única del mandato 35*/
                    ria5 += me.esp(35-ria5.length);
                    const ria6='RCUR';/* Secuencia del adeudo 4*/
                    const ria7=me.esp(4);/* Categoría de propósito 4*/                    
                    const ria8=me.numeroTextDec(x.importe,11);/* Importe del adeudo 11*/                    
                    totImportes += x.importe;
                    const ria9=cp6;/* Fecha de firma del mandato */
                    const ria10=me.esp(11);/* Entidad del deudor 11*/                    
                    var ria11=(new String(x.socio).substring(0,(new String(x.socio)).indexOf('('))).toUpperCase();/* Nombre del deudor 70*/
                    ria11 += me.esp(70-ria11.length);
                    const ria12=me.esp(50);/* Dirección deudor 50*/
                    const ria13=me.esp(50);/* Dirección deudor 50*/
                    const ria14=me.esp(40);/* Dirección deudor 40*/
                    const ria15=me.esp(2);/* País del deudor 2 LIBRE*/
                    const ria16=me.esp(1);/* Tipo de Identificación del deudor LIBRE*/
                    const ria17=me.esp(36);/* Identificación del deudor 36  LIBRE*/
                    const ria18=me.esp(35);/* Identificación del deudor emisor código 35*/
                    const ria19='A';/* Identificador de la cuenta del deudor 1*/
                    var ria20=(new String(x.iban)).toUpperCase();/* Cuenta del deudor 34*/
                    ria20 += me.esp(34-ria20.length);
                    const ria21=me.esp(4);/* Propósito del adeudo 4*/
                    var ria22=(new String(x.concepto)).toUpperCase();/* Concepto 140*/
                    ria22 += me.esp(140-ria22.length);
                    const ria23=me.esp(19);/* Libre 19*/
                    lineas.push(ria1+ria2+ria3+ria4+ria5+ria6+ria7+ria8+ria9+ria10+ria11+ria12+ria13+ria14+ria15+ria16+ria17+ria18+ria19+ria20+ria21+ria22+ria23);
                    totAdeudos += 1;
                });
                totImportes=Math.round((totImportes + Number.EPSILON) * 100) / 100;                
                /* REGISTROS DE TOTALES DE ACREEDOR POR FECHA DE COBRO rtafc*/
                 const rtafc1='04';/* Código de registro 2*/
                 var rtafc2='ES96000G95401832';/* Identificador del acreedor 35*/
                 rtafc2 += me.esp(35-rtafc2.length);
                 const rtafc3=rba5;/* Fecha de cobro 8*/
                 const rtafc4=me.numeroTextDec(totImportes,17);/* Total de importes 17*/
                 const rtafc5=me.numeroText(totAdeudos,8);/* Número de adeudos 8*/
                 const rtafc6=me.numeroText(totAdeudos+2,10);/* Total de registros 10*/
                 const rtafc7=me.esp(520);/* Libre 520*/
                 lineas.push(rtafc1+rtafc2+rtafc3+rtafc4+rtafc5+rtafc6+rtafc7);

                /* REGISTROS DE TOTALES DE ACREEDOR rta*/
                const rta1='05';
                var rta2='ES96000G95401832';
                rta2 += me.esp(35-rta2.length);/* Identificador del acreedor 35*/
                const rta3=me.numeroTextDec(totImportes,17);/* Total de importes 17*/
                const rta4=me.numeroText(totAdeudos,8);/* Número de adeudos 8*/
                const rta5=me.numeroText(totAdeudos+3,10);/* Total de registros 10*/
                const rta6=me.esp(528);/* Libre 528*/
                lineas.push(rta1+rta2+rta3+rta4+rta5+rta6);

                /* REGISTRO DE TOTALES GENERAL rtg*/
                const rtg1='99';
                const rtg2=me.numeroTextDec(totImportes,17);/* Total de importes 17*/
                const rtg3=me.numeroText(totAdeudos,8);/* Número de adeudos 8*/
                const rtg4=me.numeroText(totAdeudos+5,10);/* Total de registros 10*/
                const rtg5=me.esp(563);/* Libre 563*/
                lineas.push(rtg1+rtg2+rtg3+rtg4+rtg5);
                var saltoLinea = "\n"
                var conteArchivo = '';
                lineas.forEach(function (contenido) {
                    conteArchivo += contenido + saltoLinea;
                })
                /* CON LA SIGUIENTE OPERACIÓN SE CORRIGE EL PROBLEMA DE LAS "Ñ,Á,É,Í,Ó,Ú" */
                conteArchivo=conteArchivo.replace(/Ñ/gi, "N");
                conteArchivo=conteArchivo.replace(/Á/gi, "A");
                conteArchivo=conteArchivo.replace(/É/gi, "E");
                conteArchivo=conteArchivo.replace(/Í/gi, "I");
                conteArchivo=conteArchivo.replace(/Ó/gi, "O");
                conteArchivo=conteArchivo.replace(/Ú/gi, "U");
                conteArchivo=conteArchivo.replace(/Ü/gi, "U");
                me.descargar("Recibos_"+rba5+".c19",conteArchivo);
                fechaCobro='';                        
            },
            crearFicheroSepaPago(fechaPago) {         
                let me=this;                
                me.adModalFechaPago=0; 
                fechaPago=me.$fechaLarga(fechaPago);
                var remesa=me.remesas.filter(p => p.value == me.SelRemesa)[0].text.substring(0,9);
                remesa=remesa.substring(0,6)+'-'+remesa.substring(7,9);
                var lineas=[],totImportes=0,totAdeudos=0;
                /* CABECERA DE ORDENANTE cp*/
                const cp1='01';/* Código de registro 2*/
                const cp2='ORD'/* Código de operación 3*/
                const cp3='34145';/* Versión del cuaderno 5*/
                const cp4='001';/* Número de dato 3*/
                const cp5='G95401832'/* Identificador del ordenante 9* DNI de AITOR o podría ser el CIF G95401832 */
                const cp6='000'  /*Identificación del Ordenante: Sufijo 3*/
                const hoy = new Date();
                const cp7=hoy.getFullYear()+(("0" + (hoy.getMonth() + 1)).slice(-2))+("0"+(hoy.getUTCDate())).slice(-2);/* Fecha de creación del fichero - la de hoy*/
                const cp8=fechaPago.getFullYear()+(("0" + (fechaPago.getMonth() + 1)).slice(-2))+("0"+(fechaPago.getUTCDate())).slice(-2);/* Fecha de pago - OJO VARIABLE -  8*/
                const cp9='A';/* Tipo de identificador de la cuenta del ordenante 1*/
                var cp10='ES3830080272443792583027';/* Cuenta del acreedor 34*/
                cp10 += me.esp(34-cp10.length);
                const cp11='0'; /*Detalle del cargo 1*/
                var cp12='URGOZO T.E.';/* Nombre del presentador 70*/
                cp12 += me.esp(70-cp12.length);
                const cp13=me.esp(50);/* Dirección deudor 50*/
                const cp14=me.esp(50);/* Dirección deudor 50*/
                const cp15=me.esp(40);/* Dirección deudor 40*/
                const cp16=me.esp(2);/* País del deudor 2 LIBRE*/
                const cp17=me.esp(311);/* LIBRE 311*/
                lineas.push(cp1+cp2+cp3+cp4+cp5+cp6+cp7+cp8+cp9+cp10+cp11+cp12+cp13+cp14+cp15+cp16+cp17);                

                /* REGISTROS DEL BLOQUE DE TRANSFERENCIAS rta*/
                const rta1='02';/* Código de registro 2*/
                const rta2='SCT';/* Código de Operación 3*/
                const rta3='34145';/* Versión del cuaderno 5*/
                const rta4='G95401832'/* Identificador del ordenante 9* DNI de AITOR o podría ser el CIF G95401832 */
                const rta5='000'  /*Identificación del Ordenante: Sufijo 3*/
                const rta6=me.esp(578);/* LIBRE 578*/
                lineas.push(rta1+rta2+rta3+rta4+rta5+rta6);                

                /* REGISTROS BENEFICIARIO TRANSFERENCIA rbt*/
                me.recibos.forEach(x => {
                    const rbt1='03';/* Código de registro 2*/
                    const rbt2='SCT';/* Código de Operación 3*/
                    const rbt3='34145';/* Versión del cuaderno 5*/
                    const rbt4='002';/* Número de dato 3*/                    
                    var rbt5=(new String(x.recibo)).toUpperCase();/* Referencia del ordenante 35*/
                    rbt5 += me.esp(35-rbt5.length);
                    const rbt6='A';/* Tipo de identificador de la cuenta del beneficiario 1*/
                    var rbt7=(new String(x.iban)).toUpperCase();/* Cuenta del beneficiario 34*/
                    rbt7 += me.esp(34-rbt7.length);
                    const rbt8=me.numeroTextDec(-x.importe,11);/* Importe de la transferencia 11*/                    
                    totImportes += -x.importe;
                    const rbt9='3';/* Clave de gastos: 3 = Gastos compartidos 1*/
                    const rbt10=me.esp(11);/* BIC Entidad del Beneficiario-no indicar 11*/
                    var rbt11=(new String(x.socio).substring(0,(new String(x.socio)).indexOf('('))).toUpperCase();/* Nombre del beneficiario 70*/
                    rbt11 += me.esp(70-rbt11.length);
                    const rbt12=me.esp(50);/* Dirección Beneficiario 50*/
                    const rbt13=me.esp(50);/* Dirección Beneficiario 50*/
                    const rbt14=me.esp(40);/* Dirección Beneficiario 40*/
                    const rbt15=me.esp(2);/* País del Beneficiario 2 LIBRE*/
                    var rbt16=(new String(x.concepto)).toUpperCase();/* Concepto enviado por el Ordenante al Beneficiario 140*/
                    rbt16 += me.esp(140-rbt16.length);
                    const rbt17=me.esp(35);/* Identificación de la instrucción 35*/
                    const rbt18='SALA';/* Tipo de transferencia 4 me.esp(4)*/
                    const rbt19=me.esp(4);/* Propósito de la transferencia 4*/
                    const rbt20=me.esp(99);/* Libre 4*/
                    lineas.push(rbt1+rbt2+rbt3+rbt4+rbt5+rbt6+rbt7+rbt8+rbt9+rbt10+rbt11+rbt12+rbt13+rbt14+rbt15+rbt16+rbt17+rbt18+rbt19+rbt20);
                    totAdeudos += 1; 
                });
                totImportes=Math.round((totImportes + Number.EPSILON) * 100) / 100;

                /* REGISTROS DE TOTALES DE TRANSFERENCIAS SEPA*/
                const rtt1='04';/* Código de Registro 2*/
                const rtt2='SCT';/* Código de Operación 3*/
                const rtt3=me.numeroTextDec(totImportes,17);/* Total de importes 17*/
                const rtt4=me.numeroText(totAdeudos,8);/* Número de registros 8*/
                const rtt5=me.numeroText(totAdeudos+2,10);/* Total de registros 10*/
                const rtt6=me.esp(560);/* Libre 560*/
                lineas.push(rtt1+rtt2+rtt3+rtt4+rtt5+rtt6);
                /* REGISTRO DE TOTALES GENERAL rtg*/
                const rtg1='99';
                const rtg2='ORD';/* Código de Operación 3*/
                const rtg3=me.numeroTextDec(totImportes,17);/* Total de importes 17*/
                const rtg4=me.numeroText(totAdeudos,8);/* Número de adeudos 8*/
                const rtg5=me.numeroText(totAdeudos+4,10);/* Total de registros 10*/
                const rtg6=me.esp(560);/* Libre 560*/
                lineas.push(rtg1+rtg2+rtg3+rtg4+rtg5+rtg6);
                var saltoLinea = "\n"
                var conteArchivo = '';
                lineas.forEach(function (contenido) {
                    conteArchivo += contenido + saltoLinea;
                })
                /* CON LA SIGUIENTE OPERACIÓN SE CORRIGE EL PROBLEMA DE LAS "Ñ,Á,É,Í,Ó,Ú" */
                conteArchivo=conteArchivo.replace(/Ñ/gi, "N");
                conteArchivo=conteArchivo.replace(/Á/gi, "A");
                conteArchivo=conteArchivo.replace(/É/gi, "E");
                conteArchivo=conteArchivo.replace(/Í/gi, "I");
                conteArchivo=conteArchivo.replace(/Ó/gi, "O");
                conteArchivo=conteArchivo.replace(/Ú/gi, "U");
                conteArchivo=conteArchivo.replace(/Ü/gi, "U");
                conteArchivo=conteArchivo.replace(/\//g, "-");
                me.descargar("Transferrencias_"+cp8+".c34",conteArchivo);
                fechaPago='';                        
            },
            descargar(filename, text) {
                /* opcion 1 */
                var element = document.createElement('a');
                element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
                element.setAttribute('download', filename);
                element.style.display = 'none';
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
            },
            esp(numero) {                
                var espacios=[];                
                return ' '.repeat(numero);
            },
            numeroTextDec(importe,largo) {
                var resultado='';
                var convString= new String(importe);
                var decimales='',enteros='';
                if (convString.indexOf('.')==-1) {
                    decimales='00';
                    enteros=convString;
                    resultado=Array(largo-convString.length-2).fill('0').join('')+enteros+decimales;
                }
                else {
                    decimales=convString.substring(convString.indexOf('.')+1);                   
                    enteros=convString.substring(0,convString.length-decimales.length-1);
                    resultado=Array(largo-convString.length+decimales.length-1).fill('0').join('')+enteros;
                    if (decimales.length==1){
                        decimales += '0';                        
                    }
                    resultado+=decimales;
                }
                return resultado;
            },
            numeroText(importe,largo) {
                var resultado='';
                var convString= new String(importe);
                resultado=Array(largo-convString.length).fill('0').join('')+convString;
                return resultado;
            }                                                                                                   
        }        
    }
</script>