import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Socio from './components/Identidad/Socio.vue'
import RecuperarSocio from './components/Identidad/RecuperarSocio.vue'
import Listados_Identidad from './components/Identidad/Listados_Identidad.vue'
import Concepto from './components/Actividad/Concepto.vue'
import Inscripcion from './components/Actividad/Inscripcion.vue'
import List_ConceptosSimples from './components/Actividad/List_ConceptosSimples.vue'
import ConceptoLicencia from './components/Licencia/ConceptoLicencia.vue'
import Solicitud from './components/Licencia/Solicitud.vue'
import List_Licencias from './components/Licencia/List_Licencias.vue'
import Articulo from './components/Tienda/Articulo.vue'
import ConceptoTienda from './components/Tienda/ConceptoTienda.vue'
import Pedido from './components/Tienda/Pedido.vue'
import List_Pedidos from './components/Tienda/List_Pedidos.vue'
import Recibo from './components/Ingresos y Gastos/Recibo.vue'
import List_RecibosRemesados from './components/Ingresos y Gastos/List_RecibosRemesados.vue'
import Remesa from './components/Ingresos y Gastos/Remesa.vue'
import Factura from './components/Ingresos y Gastos/Factura.vue'
import List_Facturas from './components/Ingresos y Gastos/List_Facturas.vue'
import CuotaAnual from './components/Ingresos y Gastos/CuotaAnual.vue'
import Apunte from './components/Contabilidad/Apunte.vue'
import List_Apuntes from './components/Contabilidad/List_Apuntes.vue'
import InformeEconomico from './components/Contabilidad/InformeEconomico.vue'
import EnviarCorreo from './components/Comunicacion/EnviarCorreo.vue'
import Banco from './components/Auxiliar/Banco.vue'
import FormaPago from './components/Auxiliar/FormaPago.vue'
import EstadoPago from './components/Auxiliar/EstadoPago.vue'
import Municipio from './components/Auxiliar/Municipio.vue'
import SistemaTalla from './components/Auxiliar/SistemaTalla.vue'
import Talla from './components/Auxiliar/Talla.vue'
import TipoConcepto from './components/Auxiliar/TipoConcepto.vue'
import TipoDocumento from './components/Auxiliar/TipoDocumento.vue'
import TipoLicencia from './components/Auxiliar/TipoLicencia.vue'
import TipoApunte from './components/Auxiliar/TipoApunte.vue'
import GruposContables from './components/Auxiliar/GruposContables.vue'
import Rol from './components/Sistema/Rol.vue'
import Usuario from './components/Sistema/Usuario.vue'
import Login from './components/Sistema/Login.vue'
import Expirado from './components/Sistema/Expirado.vue'
import Sistema from './components/Sistema/Sistema.vue'
import LoginFault from './components/Sistema/LoginFault.vue'
import store from './store'
Vue.use(Router)
var router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta :{        
        presidente: true,
        secretario: true,
        tesorero: true,
        vocal: true,
        socio: true,
        administrador :true,
      }
    },
    {
      path: '/Sistema/loginfault',
      name: 'loginfault',
      component: LoginFault,
      meta :{ 
        libre :true,
      }
    },
    {
      path: '/Identidad/socio',
      name: 'socios',
      component: Socio,
      meta :{
        administrador :true,
        presidente: true,
        secretario: true,
        tesorero: true,
        vocal:true,
        socio:false
      }
    },
    {
      path: '/Identidad/recuperarsocio',
      name: 'recuperarsocio',
      component: RecuperarSocio,
      meta :{
        administrador :true,
        presidente: false,
        secretario: false,
        tesorero: false,
        vocal:false,
        socio:false
      }
    },
    {
      path: '/Identidad/Listados_Identidad',
      name: 'listados_identidad',
      component: Listados_Identidad,
      meta :{
        administrador :true,
        presidente: true,
        secretario: true,
        tesorero: true,
        vocal:true,
        socio:false
      }
    },    
    {
      path: '/Actividad/inscripciones',
      name: 'inscripciones',
      component: Inscripcion,
      meta :{
        administrador :true,
        presidente: true,
        secretario: true,
        tesorero: true,
        vocal:true,
        socio:false
      }
    },    
    {
      path: '/Actividad/conceptos',
      name: 'conceptos',
      component: Concepto,
      meta :{
        administrador :true,
        presidente: true,
        secretario: true,
        tesorero: false,
        vocal:false,
        socio:false
      }
    },
    {
      path: '/Actividad/lis_conceptossimples',
      name: 'list_conceptossimples',
      component: List_ConceptosSimples,
      meta :{
        administrador :true,
        presidente: true,
        secretario: true,
        tesorero: true,
        vocal:true,
        socio:false
      }
    },
    {
      path: '/Licencia/solicitud',
      name: 'solicitudes',
      component: Solicitud,
      meta :{
        administrador :true,
        presidente: true,
        secretario: true,
        tesorero: true,
        vocal:true,
        socio:false
      }
    },  
    {
      path: '/Licencia/conceptoslicencias',
      name: 'conceptoslicencias',
      component: ConceptoLicencia,
      meta :{
        administrador :true,
        presidente: true,
        secretario: true,
        tesorero: false,
        vocal:false,
        socio:false
      }
    },    
    {
      path: '/Licencia/list_licencias',
      name: 'list_licencias',
      component: List_Licencias,
      meta :{
        administrador :true,
        presidente: true,
        secretario: true,
        tesorero: true,
        vocal:true,
        socio:false
      }
    },
    {
      path: '/Tienda/conceptostienda',
      name: 'conceptostienda',
      component: ConceptoTienda,
      meta :{
        administrador :true,
        presidente: true,
        secretario: true,
        tesorero: false,
        vocal:false,
        socio:false
      }
    },
    {
      path: '/Tienda/articulos',
      name: 'articulos',
      component: Articulo,
      meta :{
        administrador :true,
        presidente: true,
        secretario: true,
        tesorero: false,
        vocal:false,
        socio:false
      }
    },
    {
      path: '/Tienda/pedidos',
      name: 'pedidos',
      component: Pedido,
      meta :{
        administrador :true,
        presidente: true,
        secretario: true,
        tesorero: true,
        vocal:true,
        socio:false
      }
    },
    {
      path: '/Tienda/list_pedidos',
      name: 'list_pedidos',
      component: List_Pedidos,
      meta :{
        administrador :true,
        presidente: true,
        secretario: true,
        tesorero: true,
        vocal:true,
        socio:false
      }
    },
    {
      path: '/Ingresos y Gastos/recibos',
      name: 'recibos',
      component: Recibo,
      meta :{
        administrador :true,
        presidente: true,
        secretario: true,
        tesorero: true,
        vocal:false,
        socio:false
      }
    },
    {
      path: '/Ingresos y Gastos/remesas',
      name: 'remesas',
      component: Remesa,
      meta :{
        administrador :true,
        presidente: true,
        secretario: true,
        tesorero: true,
        vocal:false,
        socio:false
      }
    },
    {
      path: '/Ingresos y Gastos/list_recibosremesados',
      name: 'list_recibosremesados',
      component: List_RecibosRemesados,
      meta :{
        administrador :true,
        presidente: true,
        secretario: true,
        tesorero: true,
        vocal:true,
        socio:false
      }
    },
    {
      path: '/Ingresos y Gastos/facturas',
      name: 'facturas',
      component: Factura,
      meta :{
        administrador :true,
        presidente: true,
        secretario: true,
        tesorero: true,
        vocal:false,
        socio:false
      }
    },
    {
      path: '/Ingresos y Gastos/list_facturas',
      name: 'list_facturas',
      component: List_Facturas,
      meta :{
        administrador :true,
        presidente: true,
        secretario: true,
        tesorero: true,
        vocal:true,
        socio:false
      }
    },
    {
      path: '/Ingresos y Gastos/cuotaanual',
      name: 'cuotaanual',
      component: CuotaAnual,
      meta :{
        administrador :true,
        presidente: true,
        secretario: true,
        tesorero: true,
        vocal:false,
        socio:false
      }
    },    
    {
      path: '/Contabilidad/apunte',
      name: 'apunte',
      component: Apunte,
      meta :{
        administrador :true,
        presidente: true,
        secretario: true,
        tesorero: true,
        vocal:false,
        socio:false
      }
    },
    {
      path: '/Contabilidad/list_apuntes',
      name: 'list_apuntes',
      component: List_Apuntes,
      meta :{
        administrador :true,
        presidente: true,
        secretario: true,
        tesorero: true,
        vocal:true,
        socio:false
      }
    },
    {
      path: '/Contabilidad/informeeconomico',
      name: 'informeeconomico',
      component: InformeEconomico,
      meta :{
        administrador :true,
        presidente: true,
        secretario: true,
        tesorero: true,
        vocal:true,
        socio:false
      }
    },
    {
      path: '/Comunicacion/EnviarCorreo',
      name: 'enviarcorreo',
      component: EnviarCorreo,
      meta :{
        administrador :true,
        presidente: true,
        secretario: true,
        tesorero: true,
        vocal:false,
        socio:false
      }
    },
    {
      path: '/Auxiliar/banco',
      name: 'banco',
      component: Banco,
      meta :{
        administrador :true,
        presidente: true,
        secretario: true,
        tesorero: true,
        vocal:false,
        socio:false
      }
    },
    {
      path: '/Auxiliar/formapago',
      name: 'formapago',
      component: FormaPago,
      meta :{
        administrador :true,
        presidente: true,
        secretario: true,
        tesorero: true,
        vocal:false,
        socio:false
      }
    },
    {
      path: '/Auxiliar/estadopago',
      name: 'estadopago',
      component: EstadoPago,
      meta :{
        administrador :true,
        presidente: true,
        secretario: true,
        tesorero: true,
        vocal:false,
        socio:false
      }
    },
    {
      path: '/Auxiliar/municipio',
      name: 'municipio',
      component: Municipio,
      meta :{
        administrador :true,
        presidente: true,
        secretario: true,
        tesorero: true,
        vocal:true,
        socio:false
      }
    },
    {
      path: '/Auxiliar/tipoconcepto',
      name: 'tipoconcepto',
      component: TipoConcepto,
      meta :{
        administrador :true,
        presidente: true,
        secretario: true,
        tesorero: false,
        vocal:false,
        socio:false
      }
    },
    {
      path: '/Auxiliar/tipodocumento',
      name: 'tipodocumento',
      component: TipoDocumento,
      meta :{
        administrador :true,
        presidente: true,
        secretario: true,
        tesorero: false,
        vocal:false,
        socio:false
      }
    },
    {
      path: '/Auxiliar/tipolicencia',
      name: 'tipolicencia',
      component: TipoLicencia,
      meta :{
        administrador :true,
        presidente: true,
        secretario: true,
        tesorero: false,
        vocal:false,
        socio:false
      }
    },            
    {
      path: '/Auxiliar/sistematalla',
      name: 'sistematalla',
      component: SistemaTalla,
      meta :{
        administrador :true,
        presidente: true,
        secretario: true,
        tesorero: false,
        vocal:false,
        socio:false
      }
    },
    {
      path: '/Auxiliar/talla',
      name: 'talla',
      component: Talla,
      meta :{
        administrador :true,
        presidente: true,
        secretario: true,
        tesorero: false,
        vocal:false,
        socio:false
      }
    },   
    {
      path: '/Auxiliar/tipoapunte',
      name: 'tipoapunte',
      component: TipoApunte,
      meta :{
        administrador :true,
        presidente: true,
        secretario: true,
        tesorero: true,
        vocal:false,
        socio:false
      }
    },
    {
      path: '/Contabilidad/gruposcontables',
      name: 'gruposcontables',
      component: GruposContables,
      meta :{
        administrador :true,
        presidente: true,
        secretario: true,
        tesorero: true,
        vocal:false,
        socio:false
      }
    },               
    {
      path: '/Sistema/roles',
      name: 'roles',
      component: Rol,
      meta :{
        administrador :true,
        presidente: false,
        secretario: false,
        tesorero: false,
        vocal:false,
        socio:false
      }
    },
    {
      path: '/Sistema/usuarios',
      name: 'usuarios',
      component: Usuario,
      meta :{
        administrador :true,
        presidente :true,
        secretario: true,
        tesorero: false,
        vocal:false,
        socio:false 
      }
    },
    {
      path: '/Sistema/sistema',
      name: 'sistema',
      component: Sistema,
      meta :{
        administrador :true,
        presidente :false,
        secretario: false,
        tesorero: false,
        vocal:false,
        socio:false 
      }
    },
    {
      path: '/Sistema/login',
      name: 'login',
      component: Login,
      meta : {
        libre: true
      }
    },
    {
      path: '/Sistema/expirado',
      name: 'expirado',
      component: Expirado,
      meta :{ 
        libre :true,
      }
    },    
  ]
})

router.beforeEach((to, from, next) => { 
   
  if (to.matched.some(record => record.meta.libre)){
    next()
  } else if (store.state.usuario && store.state.usuario.rol== 'Administrador'){
    if (to.matched.some(record => record.meta.administrador)){
      next()
    }
  }else if (store.state.usuario && store.state.usuario.rol== 'Presidente'){
    if (to.matched.some(record => record.meta.presidente)){
      next()
    }
  }else if (store.state.usuario && store.state.usuario.rol== 'Secretario'){
    if (to.matched.some(record => record.meta.secretario)){
      next()
    }
  }else if (store.state.usuario && store.state.usuario.rol== 'Tesorero'){
    if (to.matched.some(record => record.meta.tesorero)){
      next()
    }
  }else if (store.state.usuario && store.state.usuario.rol== 'Vocal'){
    if (to.matched.some(record => record.meta.vocal)){
      next()
    }
  }else if (store.state.usuario && store.state.usuario.rol== 'Socio '){
    if (to.matched.some(record => record.meta.socio)){
      next()
    }  
  } else{
    next({      
      name: 'login'
    })
  }
})
export default router