<template>
    <v-row align-start>
        <v-col>
            <Cabecera
                v-bind:objeto=ADobjetos
                v-bind:ocultaNuevo=true
                v-bind:años=años                
                v-bind:conceptos=conceptos
                v-bind:SelConcepto=SelConcepto
                v-bind:añadirRemesa="Remesables>0 && RemesaAbierta!=''"
                v-bind:nuevaRemesa="Remesables>0"
                v-bind:remesaAbierta=RemesaAbierta
                v-on:listarObjetos="listarObjetos"
                v-on:mostrarRemesar="mostrarRemesar"
                @hook:mounted="activarWatcher"
                ref="cabecera"
                v-model="SelAño"                             
            />
            <br>
            <TablaDatos
                padre="Recibos"
                v-bind:opcionesTabla=opcionesTabla                    
                v-bind:items='origenTabla'
                v-bind:headers=headers
                v-bind:search=search
                v-on:editarObjeto="editarObjeto"
                v-on:mostrarRegistrar="mostrarRegistrar"
                v-on:listarObjetos="listarObjetos"
                v-on:visualizarObjeto="visualizarObjeto"
            />
        </v-col>            
        <v-dialog v-model="adModalRemesar" max-width="500">
            <ModalTipo1                
                :titulo= "rmAccion==1 ? '¿Generar una remesa nueva?' : '¿Añadir a remesa ' + RemesaAbierta"
                :mensaje="rmAccion==1 ? 'Se ha(n) seleccionado <strong>' + Remesables + '</strong> recibo(s) que se añadirá(n) a una nueva remesa'
                                    :'Se ha(n) seleccionado <strong>' + Remesables + '</strong> recibo(s) que se añadirá(n) a la remesa <strong>' + RemesaAbierta + '</strong>'"
                v-bind:aceptarCancelar="true"
                v-on:cancelarClick="adModalRemesar=0"
                v-on:aceptarClick="remesar"    
            />
        </v-dialog>            
        <v-dialog v-model="adModalRegistrar" max-width="500" persistent>
            <ModalTipo2                
                :titulo= "adAccion==1 ? 'Registrar el cobro del recibo' : 'Registrar la devolución del recibo'"
                :mensaje="adAccion==1 ? '¿Quieres registrar el cobro del recibo <strong>' + adRecibo + '</strong>?'
                                    :'¿Quieres registrar la devolución del recibo <strong>' + adRecibo + '</strong>'"
                tipoModal="Cobro"
                :icono="adAccion==1 ? 'hand-holding-usd':'undo'"
                v-bind:GrupoContableCobro=GrupoContableCobro
                v-bind:idgrupocontableAct=regAct.idgruposcontables                
                v-on:cancelarClick="adModalRegistrar=0"
                v-on:aceptarClick="registrarAccion"    
            />
        </v-dialog>
        <v-dialog v-model="adModalProcesando" max-width="300" persistent>
            <Procesando/> 
        </v-dialog>
        <v-dialog v-model="adModalEditar" max-width="600px" persistent>
            <EditarNuevo
                v-if="adModalEditar"                    
                formTitle='Modificar Recibo' 
                :regAct=regAct
                v-bind:camposEditables=camposEditables 
                v-on:ocultarFormEditarNuevo="ocultarFormularioEditar"
                v-on:guardarObjeto="guardarObjeto"               
            />
        </v-dialog>
        <v-dialog v-model="addMostrarRecibo" max-width="1000px" persistent>
            <v-card dense>
                <v-card-title>
                    <v-alert v-if="regAct.idestadopago==1" width="100%"
                        color="blue"
                        elevation="7"
                        type="info"
                    >P E N D I E N T E</v-alert>
                    <v-alert v-if="regAct.idestadopago==2" width="100%"
                        color="green"
                        elevation="7"
                        type="info"
                    >P A G A D O</v-alert>
                    <v-alert v-if="regAct.idestadopago==4" width="100%"
                        color="orange"
                        elevation="7"
                        type="info"
                    >R E M E S A D O</v-alert>
                    <v-alert v-if="regAct.idestadopago==3" width="100%"
                        color="red"
                        elevation="7"
                        type="info"
                    >D E V U E L T O</v-alert>
                <span class="headline">Detalles Recibo</span>
                </v-card-title>                
                <v-card-text>
                    <v-container>
                        <v-row dense no-gutters>
                            <v-col cols="3" >
                                <v-text-field v-model="regAct.recibo" label="Recibo" readonly dense class="text-h5">                                        
                                </v-text-field>
                            </v-col>
                            <v-divider
                                class="mx-4"
                                vertical
                            ></v-divider>
                            <v-col cols="2" class="text-align: center">
                                <v-text-field :value="importeform" label="Importe" readonly dense class="text-h5 text-align: center">                                         
                                </v-text-field>
                            </v-col>
                            <v-divider
                                class="mx-4"
                                vertical
                            ></v-divider>
                            <v-col cols="2">
                                <v-text-field label="Fecha Valor"  
                                v-model="regAct.fvalor" readonly dense class="text-h6"
                                ></v-text-field>
                            </v-col>                            
                            <v-col cols="8">
                                <v-text-field v-model="regAct.concepto" label="Concepto" readonly dense class="text-h6">                                        
                                </v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field v-model="regAct.formapago" label="Forma de Pago" readonly dense class="text-h6">                                        
                                </v-text-field>
                            </v-col>                                                        
                            <v-col cols="4" v-if="regAct.idformapago==1">
                                <v-text-field v-model="regAct.remesa" label="Remesa" readonly dense class="text-h6">                                        
                                </v-text-field>
                            </v-col>
                            <v-col cols="8">
                                <v-text-field v-model="regAct.socio" label="Socio/a" readonly dense class="text-h6">                                        
                                </v-text-field>
                            </v-col>
                            <v-divider
                                class="mx-4"
                                vertical
                            ></v-divider>
                            <v-col cols="5" v-if="regAct.idformapago==1">
                                <v-text-field label="Entidad"
                                v-model="bancoAct"
                                readonly dense class="text-h6"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="5" v-if="regAct.idformapago==1">
                                <v-text-field v-model="regAct.iban" label="IBAN" maxLength="24" readonly dense class="text-h6">                                        
                                </v-text-field>
                            </v-col>                                
                        </v-row>
                    </v-container>               
                </v-card-text>                
                <v-card-actions>
                    <v-spacer></v-spacer>                                
                    <v-btn color="accent" dark class="mb-2" @click.native="ocultarvisualizarObjeto">Cerrar</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="adModalErrorIban" max-width="430" persistent>
            <ErrorIban 
                v-bind:errorIban=errorIban
                v-on:cerrarModal="adModalErrorIban=0"          
            />                
        </v-dialog>
    </v-row>
</template>
<script>    
    import ErrorIban from '../Libreria/Modales/ErrorIban.vue'
    import { validateIBAN} from "../Libreria/Funciones"
    export default {
        components: {
            ErrorIban,                                
        },
        data(){
            return {
                items:[],            
                adModalEditar: 0,
                headers: [
                    { text: 'Opciones', value: 'opciones', sortable: false,class: 'my-header-style' },
                    { text: 'Recibo', value: 'recibo', sortable: true,class: 'my-header-style' },
                    { text: 'Importe', value: 'importe', sortable: true,class: 'my-header-style' },
                    { text: 'Socio', value: 'socio', sortable: true,class: 'my-header-style' },
                    { text: 'Concepto', value: 'concepto', sortable: true,class: 'my-header-style' },
                    { text: 'Forma de Pago', value: 'formapago', sortable: true,class: 'my-header-style' },                    
                    { text: 'Estado del Pago', value: 'estadopago', sortable: true,class: 'my-header-style' },
                    { text: 'Fecha Valor', value: 'fvalor', sortable: true,class: 'my-header-style' },
                    { text: 'Remesa', value: 'remesa', sortable: true,class: 'my-header-style' },],
                search: '',                               
                valida: 0,
                validaMensaje:[],                
                formaspago:[],
                bancos:[],
                GrupoContableCobro:[],
                años:[],
                conceptos:[],
                bancosArray:[],
                errorIban:[],
                origenTabla:[],
                adAccion: 0,
                rmAccion:0,                
                adModalRegistrar: 0,
                adModalRemesar: 0,
                adModalErrorIban: 0,
                addMostrarRecibo:0,
                adModalProcesando:0,
                adRecibo: '',
                regAct:{idrecibos:'',idgruposcontables:null,detalle:'',idinscripciones:'',recibo:'',importe:'',concepto:'',
                    idformapago:'',idremesas:'',remesa:'',idestadopago:'',estadopago:'',fvalor:'',iban:''},
                regAnt:{},
                SelAño:'',                
                SelConcepto:0,
                Remesables:0,
                RemesaAbierta:'',
                idRemesaAbierta:'',
                ADobjetos:'Recibos',
                camposEditables:8,
                opcionesTabla:{Seleccion:false, AbrirCerrar:false, ActivarBloquear:false, Visualizar:true, Modificar:true, Eliminar:false, Historico:false, Recibos:false,
		Contabilizar:false, Descargar:false, Cobrar:true, Devolver:true},                                                    
            }
        },
        computed: {
            importeform() {
                let me=this;
                return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(me.regAct.importe)
            },
            bancoAct(){
               let me=this;
               const IBAN=me.regAct.iban;
               if (me.validateIBAN(IBAN)){
                    return me.nombreBanco(IBAN);                        
               }
               else {
                    return null
               }
            },
        },
        watch: {
            SelAño(val) {
                let me=this;
                me.adModalProcesando=1;
                me.listarConceptos();
            }  
        },        
        created() {
            let me=this;
            me.adModalProcesando=1;            
            me.listarAños();
            me.listarBancos();
            me.listarFormaPago();
            me.listarGrupoContableCobro();                           
        },
        methods:{
            validateIBAN,                        
            activarWatcher(){
                let me=this;
			    me.$watch(
			    "$refs.cabecera.search",
			    (new_value, old_value) => (me.search = new_value)
                );                
            },
            nombreBanco(IBAN){
                let me=this;
                const codigo=IBAN.substring(4,8);
                var entidad=me.bancosArray.find(element => element.codigo === codigo);
                return entidad ? entidad.descripcion : 'desconocida';
            },
            listarObjetos(SelConcepto) {
                let me=this;
                if (SelConcepto) me.SelConcepto=SelConcepto;
                me.$http.get('api/Recibos/RecibosPorConcepto/'+me.SelConcepto).then(function(response){
                    me.items=response.data;
                    me.preparaTabla();
                    me.buscarRemesas();
                    me.adModalProcesando=0;
                }).catch(function(error){
                    console.log(error);
                });                
            },
            preparaTabla(){
                let me=this;
                me.origenTabla=[];
                me.items.map(function(x){
                    me.origenTabla.push({idobjeto: x.idrecibos,recibo:x.recibo,importe:x.importe,socio:x.socio,concepto:x.concepto,formapago:x.formapago,idestadopago:x.idestadopago,fvalor: me.$fechaCorta(x.fvalor,2), remesa:x.remesa});
                })                
            },
            añadirProps(){
                let me=this;
                me.regAct= {idrecibos:me.regAct.idrecibos,
                recibo: {
                    tipo:'text',etiqueta:'Recibo',valor:me.regAct.recibo,maxlength: 10,required: true,disabled: true
                },
                importe: {
                    tipo:'money',etiqueta:'Importe',valor:me.regAct.importe,maxlength: 2,required: true,disabled: true
                },
                estadopago: {
                    tipo:'text',etiqueta:'Estado del pago',valor:me.regAct.estadopago,maxlength: 15,required: true,disabled: true
                },
                socio: {
                    tipo:'text',etiqueta:'Socio',valor:me.regAct.socio,maxlength: 40,required: true,disabled: true
                },
                idformapago: {
                    tipo:'sel',opciones:me.formaspago,etiqueta:'Forma de Pago',valor:me.regAct.idformapago,maxlength: 15,required: true,disabled: false
                },                               
                concepto: {
                    tipo:'text',etiqueta:'Concepto',valor:me.regAct.concepto,maxlength: 35,required: true,disabled: true
                },                                
                entidad: {
                    tipo:'text',etiqueta:'Entidad',valor:me.bancoAct,maxlength: 30,required: false,disabled: true
                },
                iban: {
                    tipo:'text',etiqueta:'IBAN',valor:me.regAct.iban,maxlength: 24,required: false,disabled: false
                },idestadopago:me.regAct.idestadopago
                };
            },
            eliminarProps(miObjeto){
                let me=this;
                miObjeto= {idrecibos:miObjeto.idrecibos,recibo:miObjeto.recibo.valor,idformapago:miObjeto.idformapago.valor,iban:miObjeto.iban.valor,
                            idestadopago:miObjeto.idestadopago};                
                return miObjeto;
            },
            listarAños(){
                let me=this;
                var añosArray=[]; 
                me.$http.get('api/Recibos/ListarAños').then(function(response){
                    añosArray=response.data;
                    añosArray.map(function(x){
                    me.años.push({text: x.año,value:x.año});
                    });
                    me.SelAño=añosArray[0].año;
                    me.listarConceptos();              
                }).catch(function(error){
                    console.log(error);
                });
            },
            listarConceptos(){
                let me=this;
                me.conceptos=[];
                me.items=[];
                me.SelConcepto=0;
                var ConceptosArray=[]; 
                me.$http.get('api/Conceptos/ListarConceptosAños/'+me.SelAño,{}).then(function(response){
                    ConceptosArray=response.data;
                    ConceptosArray.map(function(x){
                    me.conceptos.push({text: x.descripcion,value:x.idconceptos});
                    });
                    me.SelConcepto=ConceptosArray[0].idconceptos;
                    me.listarObjetos();              
                }).catch(function(error){
                    console.log(error);
                });
            },
            listarFormaPago(){
                let me=this;
                var formaspagoArray=[]; 
                me.$http.get('api/formapago/Listar').then(function(response){
                    formaspagoArray=response.data;
                    formaspagoArray.map(function(x){
                    me.formaspago.push({text: x.descripcion,value:x.idformapago});
                    }); 
                }).catch(function(error){
                    console.log(error);
                });
            },
            listarBancos(){
                let me=this;                 
                me.$http.get('api/bancos/Listar').then(function(response){
                    me.bancosArray=response.data;                     
                }).catch(function(error){
                    console.log(error);
                });
            },
            listarGrupoContableCobro(){
                let me=this;
                var GrupoContableCobroArray=[]; 
                me.$http.get('api/GruposContables/GrupoContableCobro').then(function(response){
                    GrupoContableCobroArray=response.data;
                    GrupoContableCobroArray.map(function(x){
                    me.GrupoContableCobro.push({text: x.descripcion,value:x.idgruposcontables});
                    });                    
                }).catch(function(error){
                    console.log(error);
                });
            },
            editarObjeto(id) {
                let me=this;                
                me.cargarVariables(id)
                me.añadirProps();            
                me.adModalEditar = 1
            },
            ocultarFormularioEditar() {
                let me=this;
                me.adModalEditar = 0;
                me.borrarVariables();
            },
            visualizarObjeto(id) {
                let me=this;            
                me.cargarVariables(id)
                me.regAct.fvalor=me.$fechaCorta(me.regAct.fvalor,1);
                me.addMostrarRecibo = 1;
            },
            ocultarvisualizarObjeto() {
                let me=this;
                me.addMostrarRecibo = 0;
                me.borrarVariables();
            },
            guardarObjeto(regAct) {                               
                let me=this;                
                var regTmp=Object.assign({},regAct);/* Creamos un objeto temporal para este metodo, que no afecte al exterior */                
                me.adModalProcesando=1;                
                if (me.adAccion==0) regTmp=me.eliminarProps(regTmp);                      
                if (regTmp.idformapago==1 && !me.validateIBAN(regTmp.iban)){
                    me.errorIban.push(regTmp.recibo);
                    me.adModalProcesando=0;                                        
                    me.adModalErrorIban=1;
                    return
                }                
                if (regTmp.idformapago!=1){                    
                    regTmp.iban=null;
                }
                if (me.adAccion==2) regTmp.fvalor=null;
                regTmp.fvalor= regTmp.fvalor===null ? regTmp.fvalor:me.$fechaLarga(regTmp.fvalor);
                if (me.$hasChanged(regTmp,me.regAnt)){
                    me.$http.put('api/Recibos/Actualizar',regTmp).then(function(response){
                        if (me.adAccion!=1) {
                            me.borrarVariables();
                            me.listarObjetos();
                            me.adModalEditar=0;
                            me.adModalProcesando=0;
                        }                                                                        
                        }).catch(function(error){
                            console.log(error);
                        });
                        }
                        else {
                            me.adModalEditar=0;                    
                            me.adModalProcesando=0;
                        }              
            },            
            mostrarRegistrar(id){                
                let me=this;                
                me.cargarVariables(id)
                me.adModalRegistrar=1;
                me.adRecibo=me.regAct.recibo;                
                if (me.regAct.idestadopago==1||me.regAct.idestadopago==3){
                    me.adAccion=1;
                }
                else if (me.regAct.idestadopago==2){
                    me.adAccion=2;
                }
                else{
                    me.adModalRegistrar=0;
                }
            },
            registrarAccion(fvalor,grupocontable){
                let me=this;
                me.adModalRegistrar=0;
                me.regAct.fvalor=fvalor;
                me.regAct.idgruposcontables=grupocontable;
                if (me.adAccion==1){                   
                    me.regAct.detalle='Cobro Recibo '+me.regAct.recibo;                
                    me.regAct.idestadopago=2;
                    me.guardarObjeto(me.regAct);                        
                    me.crearApunte();
                }
                else {                
                    me.regAct.detalle='Devolución Recibo '+me.regAct.recibo;
                    me.regAct.idestadopago=3;               
                    me.regAct.idremesas=null;
                    me.regAct.importe=me.regAct.importe*(-1);
                    me.crearApunte();
                    me.regAct.importe=me.regAct.importe*(-1);
                    me.guardarObjeto(me.regAct);
                }
            },
            crearApunte() {                
                let me=this;                   
                me.$http.post('api/ApuntesContables/Crear',{
                    'fvalor':me.$fechaLarga(me.regAct.fvalor),
                    'detalle':me.regAct.detalle,
                    'idgruposcontables':me.regAct.idgruposcontables,                
                    'importe':me.regAct.importe,
                    'idconceptos':me.regAct.idconceptos,
                    'eliminable':false,                                           
                }).then(function(response){
                    if (me.adAccion==1) {
                        me.borrarVariables();
                        me.listarObjetos();
                        me.adModalEditar=0;
                        me.adModalProcesando=0;    
                    }
                }).catch(function(error){
                    console.log(error);
                });    
            },            
            cargarVariables(id){
                let me=this;
                const item=me.items.find(obj => {return Object.values(obj)[0] === id});
                me.regAnt= {idrecibos:item.idrecibos,recibo:item.recibo,idformapago:item.idformapago,iban:item.iban,
                            idestadopago:item.idestadopago,fvalor:me.$fechaCorta(item.fvalor,1)};                
                me.regAct=Object.assign({},item);                    
            },
            borrarVariables(){
                let me=this;
                me.regAct={idgruposcontables:null,detalle:'',idrecibos:'',idinscripciones:'',recibo:'',importe:'',concepto:'',
                    idformapago:'',idremesas:'',remesa:'',idestadopago:'',estadopago:'',fvalor:'',iban:''};
                me.adRecibo='';
                me.adAccion= 0;                                  
            },
            buscarRemesas(){
                let me=this;
                var idmayor=0;
                me.Remesables=0;
                me.RemesaAbierta='';
                me.idRemesaAbierta='';
                me.errorIban=[]; 
                me.items.forEach(element => {
                    if (element.idformapago==1 && (element.idestadopago==1 || element.idestadopago==3)) {
                        if (!me.validateIBAN(element.iban)) {
                            me.errorIban.push(element.recibo);
                        }
                        else {
                            me.Remesables+=1;
                        }
                    }
                    if (element.fvalor==null && element.idremesas !=null && element.idremesas>idmayor) {
                        idmayor=element.idremesas;
                        me.RemesaAbierta=element.remesa;
                        me.idRemesaAbierta=element.idremesas;
                    }                    
                });
                if (me.errorIban.length){
                    me.adModalErrorIban=1;   
                    me.Remesables=0;
                    me.RemesaAbierta='';
                    me.idRemesaAbierta='';
                }
            },
            mostrarRemesar(accion){
                let me=this;
                me.rmAccion=accion;
                me.adModalRemesar=1;
            },
            remesar(){
                let me=this;
                if (me.rmAccion==1) {
                    me.$http.put('api/Remesas/Crear/'+me.SelConcepto).then(function(response){
                    me.adModalRemesar=0;
                    me.listarObjetos();                    
                    }).catch(function(error){
                        console.log(error);
                    });
                }
                else
                {
                    me.$http.put('api/Remesas/Asignar/'+me.idRemesaAbierta).then(function(response){
                    me.adModalRemesar=0;
                    me.listarObjetos();                    
                    }).catch(function(error){
                        console.log(error);
                    });
                }
            }                                                            
        }        
    }
</script>
