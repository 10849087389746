<template>
    <v-card>
        <br>
        <div class="center">
            <fa-icon :icon="['fas', 'exclamation']" size="3x" class="icon-cog iconcolor"/>
        </div>
        <v-card-title class="headline">Error(es) en código(s) IBAN</v-card-title>
        <v-card-text style="font-size:1em">
            <br><br>
            Se ha(n) localizado <strong>{{errorIban.length}}</strong> recibo(s) con el código IBAN incorrecto:
            <br><br>
            <ul id="example-1">
                <li v-for="item in errorIban">
                    {{ item }}
                </li>
            </ul>
        </v-card-text>        
        <v-card-actions class="justify-center">            
            <v-btn color="accent" @click="$emit('cerrarModal')">
                Cerrar
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>   
    export default {
        props: {
        errorIban: Array
        }       
    }
</script>