<template>
    <v-container fluid>    
        <v-row>            
            <Cabecera
                v-bind:objeto=ADobjetos
                v-bind:ocultaNuevo="viendohistorico==1||viendorecibos==1"                                  
                v-on:Nuevo="adModalNuevoEditar=1"
                @hook:mounted="activarWatcher"
                ref="cabecera"                             
            />
            <div v-show="!viendohistorico&&!viendorecibos" style="width:100%;">
                <br>            
                <TablaDatos
                    v-bind:opcionesTabla=opcionesTabla1                    
                    v-bind:items='origenTabla1'
                    v-bind:headers=headers
                    v-bind:search=search
                    v-on:editarObjeto="editarObjeto"
                    v-on:mostrarEliminar="mostrarEliminar"
                    v-on:listarObjetos="listarObjetos"
                    v-on:visualizarObjeto="visualizarObjeto"
                    v-on:activarHistorico="activarHistorico"
                    v-on:activarRecibos="activarRecibos"
                    />
            </div>
            <v-container fluid v-if="viendohistorico" grid-list-xs class="pa-4 white"  align-center justify-space-between row fill-height>            
            <v-row>
                <br>
                <v-col cols="10" align="center">
                    <v-card>
                        <span class="headline">Histórico de Inscripciones y Conceptos de <strong>{{this.nombrecompleto}}</strong></span>
                    </v-card>
                </v-col>
                <v-col cols="2" align="center">
                    <v-btn color="accent" @click.native="ocultarHistorico">Volver</v-btn><br>
                </v-col>
            </v-row>
            <v-row>
                <br><br><br>
                <!-- Aqui dibujamos la tabla del histórico de inscripciones -->
                <TablaDatos
                    v-bind:opcionesTabla=opcionesTabla2                    
                    v-bind:items=origenTabla2
                    v-bind:headers=headers1                
                    v-bind:search=search                
                    v-on:listarObjetos="listarObjetos"
                    v-on:visualizarObjeto="visualizarObjeto"                
                />
            </v-row>
            </v-container>
            <v-container fluid v-if="viendorecibos" grid-list-xs class="pa-4 white"  align-center justify-space-between row fill-height>                            
                <v-row>
                    <br>
                    <v-col cols="10" align="center">
                        <v-card>
                            <span class="headline">Histórico de Recibos de <strong>{{this.nombrecompleto}}</strong></span>
                        </v-card>
                    </v-col>
                    <v-col cols="2" align="center">
                        <v-btn color="accent" @click.native="ocultarRecibos">Volver</v-btn>
                    </v-col>
                </v-row> 
                <v-row>
                    <br><br><br>               
                    <!-- Aqui dibujamos la tabla del histórico de recibos -->                
                    <TablaDatos
                        v-bind:opcionesTabla=opcionesTabla3                    
                        v-bind:items=origenTabla3     
                        v-bind:headers=headers2
                        v-bind:search=search                    
                        v-on:listarObjetos="listarObjetos"                    
                    />                    
                </v-row>
            </v-container>
        </v-row>
        <v-dialog v-model="adModalNuevoEditar" v-if="!viendohistorico&&!viendorecibos" persistent>            
            <v-card>
                <v-card-title>
                <span class="headline">{{ formTitle }}</span>
                </v-card-title>                
                <v-card-text>
                    <v-container grid-list-md>
                        <v-row wrap>
                            <v-col cols="1">
                                <v-text-field v-model="regAct.numerosocio" label="Nº Socio" autofocus maxLength="3">                                        
                                </v-text-field>
                            </v-col>
                            <v-col cols="2">
                                <v-text-field label="Fecha de Ingreso" type="date" 
                                v-model="regAct.ingreso"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="2">
                                <v-text-field v-model="regAct.nombre" label="Nombre" maxLength="25" clearable>                                        
                                </v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field v-model="regAct.apellidos" label="Apellidos" maxLength="50" clearable>                                        
                                </v-text-field>
                            </v-col>
                            <v-col cols="2">
                                <v-select v-model="regAct.idtipodocumento"
                                :items="tiposdocumento" label="Tipo Documento">
                                </v-select>
                            </v-col>
                            <v-col cols="2">
                                <v-text-field v-model="regAct.documento" label="Nº Documento" maxLength=9 clearable>                                        
                                </v-text-field>
                            </v-col>
                            <v-col cols="2"> 
                                <v-switch
                                    v-model="regAct.sexo"
                                    :label="'Hombre'"
                                ></v-switch>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field v-model="regAct.direccion" label="Domicilio" maxLength="50" clearable>                                        
                                </v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-select v-model="regAct.idmunicipios"
                                    :items="municipios" label="Municipio">
                                </v-select>
                                <v-btn color="accent" text @click.native="añadirPropsMun();formNuevoMunicipio=1">Nuevo municipio</v-btn>
                            </v-col>
                            
                            <v-col cols="2">
                                <v-text-field v-model="regAct.telefono1" label="Teléfono1" maxLength="9" clearable>                                        
                                </v-text-field>
                            </v-col>
                            <v-col cols="2">
                                <v-text-field v-model="regAct.telefono2" label="Teléfono2" maxLength="9" clearable>                                        
                                </v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field v-model="regAct.email1" label="Email1" maxLength="50" clearable>                                        
                                </v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field v-model="regAct.email2" label="Email2" maxLength="50" clearable>                                        
                                </v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-select v-model="regAct.idformapago"
                                :items="formaspago" label="Forma de Pago">
                                </v-select>
                            </v-col>
                            <v-col cols="2"> 
                                <v-switch
                                    v-model="regAct.lopd"
                                    :label="'LOPD'"
                                ></v-switch>
                            </v-col>
                            <v-col cols="2">
                                <v-text-field label="Fecha de Nacimiento" type="date" 
                                v-model="regAct.fnac"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field label="Entidad"
                                v-model="bancoAct"
                                disabled
                                ></v-text-field>
                            </v-col>
                            <v-col cols="4">
                                <v-text-field v-model="regAct.iban" label="IBAN" maxLength=24 clearable>                                        
                                </v-text-field>
                            </v-col>                                
                            <v-col cols="6" v-show="valida">
                                <div class="red--text" v-for="v in validaMensaje" :key="v" v-text="v">
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>                
                <v-card-actions>
                    <v-spacer></v-spacer>                                
                    <v-btn color="accent" text @click.native="closeadModalNuevoEditar">Cancelar</v-btn>
                    <v-btn color="accent" @click.native="guardarSocio">Guardar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="adModalProcesando" max-width="300" persistent>
            <Procesando/> 
        </v-dialog>
        <v-dialog max-width="1000px" v-model="albaranModal" persistent>
            <Albaran
                v-bind:albaranAct="Object.assign({},albaranAct)"                 
                v-on:ocultarAlbaran="albaranModal=0"             
            />
        </v-dialog>
        <v-dialog max-width="1000px" v-model="licenciaModal" persistent>
            <Licencia
                :licenciaAct="Object.assign({},licenciaAct)"                 
                v-on:ocultarLicencia="licenciaModal=0"             
            />
        </v-dialog> 
        <v-dialog max-width="1200px" v-model="fichaModal" persistent>
            <FichaSocio
                :fichaAct="Object.assign({},fichaAct)"                 
                v-on:ocultarFicha="fichaModal=0"             
            />
        </v-dialog>    
        <v-dialog v-model="adModalEliminar" max-width="500" persistent>
            <ModalTipo1                
                :titulo="'Eliminar ' + ADobjeto"
                :mensaje="'Estás a punto de eliminar ' + ADobjeto + ' <strong>' + 
                    ADnombre + '</strong> ¿Quieres continuar?'"
                v-bind:aceptarCancelar="true"
                v-on:cancelarClick="adModalEliminar=0"
                v-on:aceptarClick="eliminarObjeto"    
            />
        </v-dialog>
        <v-dialog v-model="adModalRecibosActualizado" max-width="500" persistent>            
            <ModalTipo1
                titulo="Recibo(s) actualizado(s)"
                :mensaje="'Se ha actualizado el código IBAN también a <strong>' + recibosAct + '</strong> recibo(s) pendiente(s) de pago'"                        
                v-on:cerrarModal="adModalRecibosActualizado=0"          
            />
        </v-dialog>
        <v-dialog v-model="formNuevoMunicipio" max-width="410px" persistent>
            <EditarNuevo                    
                formTitle='Nuevo Municipio'
                v-bind:regAct=regActMun
                v-bind:camposEditables=3 
                v-on:ocultarFormEditarNuevo="ocultarformNuevoMunicipio"
                v-on:guardarObjeto="guardarMunicipio"               
            />    
        </v-dialog>
        <v-dialog v-model="adModalTextoRepetido" max-width="500" persistent>
            <ModalTipo1
                titulo="Valor Repetido"
                :mensaje="'El valor <strong>' + ADtexto + '</strong> del campo <strong>' + ADcampo + '</strong> está repetido. Ya existe ese municipio'"                        
                v-on:cerrarModal="adModalTextoRepetido=0"          
            />    
        </v-dialog>
    </v-container>
</template>
<script>        
    import Albaran from '../Libreria/Modales/Albaran.vue'
    import Licencia from '../Libreria/Modales/Licencia.vue'   
    import { validateIBAN} from "../Libreria/Funciones"        
    import jsPDF from 'jspdf'
    import autoTable from 'jspdf-autotable'
    import * as moment from 'moment'  
    export default {
        components: {                        
            Albaran,
            Licencia                      
        },
        data(){
            return {                
                headers: [
                    { text: 'Opciones', value: 'opciones', sortable: false,class: 'my-header-style' },
                    { text: 'Nº', value: 'numerosocio', sortable: true,class: 'my-header-style' },
                    { text: 'Apellidos y Nombre', value: 'socio', sortable: true,class: 'my-header-style'},                    
                    { text: 'Municipio', value: 'municipio', sortable: true,class: 'my-header-style' },
                    { text: 'Edad', value: 'edad', sortable: true,class: 'my-header-style'  },
                    { text: 'Sexo', value: 'sexo', sortable: true,class: 'my-header-style'  },
                    { text: 'Antigüedad', value: 'antiguedad', sortable: true,  class: 'my-header-style'}                                   
                ],
                headers1: [
                    { text: 'Opciones', value: 'opciones', sortable: false,class: 'my-header-style' },
                    { text: 'Año', value: 'año', sortable: true,class: 'my-header-style' },
                    { text: 'Concepto', value: 'descripcionconcepto', sortable: true,class: 'my-header-style' },
                    { text: 'Tipo Concepto', value: 'descripciontipo', sortable: true,class: 'my-header-style' },
                    { text: 'F. Evento', value: 'fecha', sortable: true,class: 'my-header-style'  },
                    { text: 'Nota', value: 'textolibre', sortable: true,class: 'my-header-style'  },
                    { text: 'Importe', value: 'importe', sortable: true,class: 'my-header-style'  },
                    { text: 'Estado', value: 'idestadopago', sortable: true,class: 'my-header-style'  }                                    
                ],
                headers2: [                    
                    { text: 'Año', value: 'año', sortable: true,class: 'my-header-style' },
                    { text: 'Recibo', value: 'recibo', sortable: true,class: 'my-header-style' },
                    { text: 'Concepto', value: 'concepto', sortable: true,class: 'my-header-style' },
                    { text: 'Importe', value: 'importe', sortable: true,class: 'my-header-style'},
                    { text: 'Forma Pago', value: 'formapago', sortable: true,class: 'my-header-style'  },
                    { text: 'Remesa', value: 'remesa', sortable: true,class: 'my-header-style'  },
                    { text: 'F. Valor', value: 'fvalor', sortable: true,class: 'my-header-style'  },                    
                    { text: 'Estado', value: 'idestadopago', sortable: true,class: 'my-header-style'  },                                                 
                ],
                adModalProcesando:0,
                adModalNuevoEditar: 0,
                adModalEliminar:0,
                adModalRecibosActualizado:0,
                formNuevoMunicipio:0,
                adModalTextoRepetido:0,
                fichaModal:0,
                albaranModal:0,
                licenciaModal:0,                
                editedIndex: -1,
                viendohistorico:0,
                viendorecibos:0,                
                items1:[],
                items2:[],
                items3:[],
                lineas:[],                
                bancosArray:[],
                origenTabla1:[],
                origenTabla2:[],
                origenTabla3:[],
                municipios:[],
                formaspago:[],
                tiposdocumento:[],
                bancos:[],
                albaranAct:{},
                licenciaAct:{},
                fichaAct:{},
                nombrecompleto: '',                 
                search: '',
                searchMem: '',
                regAct:{idsocios:null,numerosocio:null,apellidos:null,nombre:null,ingreso:new Date().toISOString().slice(0,10),
                direccion: null,idmunicipios: 26,municipio:null,cpostal:null,telefono1:null,telefono2:null,email1:null,email2:null,
                idformapago:1,lopd: true,fnac:null,sexo: true,eliminado:false,documento:null,idtipodocumento:1,iban:null},
                regAnt:{},
                ibanAnt: '',
                valida: 0,
                validaMensaje:[],                
                adAccion: 0,
                recibosAct:'',               
                ADobjeto:'Socio/a',
                ADobjetos:'Socios/as',
                ADnombre:'',
                logo: require('@/assets/Armarria.png'),
                regActMun:{idmunicipios:'',
                    nombre:[],codigopostal:[],territorio:[]},
                ADcampo:'',
                ADtexto:'',
                opcionesTabla1:{Seleccion:false, AbrirCerrar:false, ActivarBloquear:false, Visualizar:true, Modificar:true, Eliminar:true, Historico:true, Recibos:true,
		Contabilizar:false, Descargar:false},
                opcionesTabla2:{Seleccion:false, AbrirCerrar:false, ActivarBloquear:false, Visualizar:true, Modificar:false, Eliminar:false, Historico:false, Recibos:false,
		Contabilizar:false, Descargar:false},
                opcionesTabla3:{Seleccion:false, AbrirCerrar:false, ActivarBloquear:false, Visualizar:false, Modificar:false, Eliminar:false, Historico:false, Recibos:false,
		Contabilizar:false, Descargar:false},                            
            }
        },
        computed: {
            formTitle() {
                return this.editedIndex === -1 ? 'Nuevo/a Socio/a' : 'Actualizar Socio/a'
            },
            bancoAct(){
               let me=this;
               const IBAN=me.regAct.iban;
               if (me.validateIBAN(IBAN)){
                    return me.nombreBanco(IBAN);                        
               }         
            }      
        },        
        created() {
            let me=this;
            me.adModalProcesando=1;                   
            me.listarObjetos();
            me.listarMunicipios();
            me.listarFormaPago();
            me.listarTiposDocumento()
            me.listarBancos()            
        },
        methods:{
            validateIBAN,
            activarWatcher(){
                let me=this;
                me.$watch(
                "$refs.cabecera.search",
                (new_value, old_value) => (me.search = new_value)
                    );
            },
            nombreBanco(IBAN){
                let me=this;
                if (me.validateIBAN(IBAN)){
                    const codigo=IBAN.substring(4,8);
                    var entidad=me.bancosArray.find(element => element.codigo === codigo).descripcion;
                }                
                return entidad ? entidad : 'desconocida';
            },
            visualizarObjeto(id){
                let me=this;
                /* Comprobamos si quiere ver la ficha del pedido o licencia ... */                
                if (me.viendohistorico==1) {
                    me.mostrarDetalleInscripcion(id);
                    return;
                };
                /* ... por el contrario quiere ver la ficha de socio */               
                const item=me.items1.find(obj => {return Object.values(obj)[0] === id});                
                me.fichaAct={nombresocio: item.socio,numerosocio: item.numerosocio, ingreso: me.$fechaCorta(item.ingreso),
                direccion: item.direccion, municipio: item.municipio, cpostal: item.cpostal, telefono1: item.telefono11==null ? '':item.telefono1,
                telefono2: item.telefono2==null ? '':item.telefono2, email1: item.email1==null ? '':item.email1, email2: item.email2==null ? '':item.email2, formapago: item.formapago,
                lopd: item.lopd ? "Bai/Si":"Ez/No", fnac: item.fnac==null ? 'No consta':me.$fechaCorta(item.fnac), edad: item.fnac==null ? '':'(' + me.$calcularEdad(item.fnac)+' urte/años)',
                sexo: item.sexo,documento: item.documento, iban: item.iban, 
                banco: me.nombreBanco(item.iban)};
                me.fichaModal=1;
            },
            listarObjetos(){
                let me=this;
                me.adModalProcesando=1;                               
                me.$http.get('api/Socios/ListarDetalles').then(function(response){
                me.items1=response.data;
                me.preparaTabla1();
                me.adModalProcesando=0;
                }).catch(function(error){
                    console.log(error);
                });
            },
            preparaTabla1(){
                let me=this;
                me.origenTabla1=[];
                me.items1.map(function(x){if(x.eliminado === false){
                    me.origenTabla1.push({idsocios: x.idsocios,numerosocio: x.numerosocio, socio: x.socio, municipio:x.municipio, edad: me.$calcularEdad(me.$fechaCorta(x.fnac,1)), sexo: x.sexo ? 'Hombre':'Mujer', antiguedad: me.$calcularEdad(me.$fechaCorta(x.ingreso,1)) })
                    }
                })                
            },
            listarMunicipios(){
                let me=this;
                var municipiosArray=[]; 
                me.$http.get('api/Municipios/Listar').then(function(response){
                    municipiosArray=response.data;
                    municipiosArray.map(function(x){
                    me.municipios.push({text: x.nombre,value:x.idmunicipios});
                    }); 
                }).catch(function(error){
                    console.log(error);
                });
            },
            listarFormaPago(){
                let me=this;
                var formaspagoArray=[]; 
                me.$http.get('api/formapago/Listar').then(function(response){
                    formaspagoArray=response.data;
                    formaspagoArray.map(function(x){
                    me.formaspago.push({text: x.descripcion,value:x.idformapago});
                    }); 
                }).catch(function(error){
                    console.log(error);
                });
            },
            listarTiposDocumento(){
                let me=this;
                var tiposdocumentoArray=[]; 
                me.$http.get('api/tipodocumento/Listar').then(function(response){
                    tiposdocumentoArray=response.data;
                    tiposdocumentoArray.map(function(x){
                    me.tiposdocumento.push({text: x.descripcion,value:x.idtipodocumento});
                    }); 
                }).catch(function(error){
                    console.log(error);
                });
            },
            listarBancos(){
                let me=this;                 
                me.$http.get('api/bancos/Listar').then(function(response){
                    me.bancosArray=response.data;
                }).catch(function(error){
                    console.log(error);
                });
            },
            listarHistorico(){
                let me=this;                                              
                me.$http.get('api/Inscripciones/FiltrarPorSocio/'+me.regAct.idsocios).then(function(response){
                    me.items2=response.data;
                    me.preparaTabla2()                
                }).catch(function(error){
                    console.log(error);
                });
            },
            preparaTabla2(){
                let me=this;
                me.origenTabla2=[];
                me.items2.map(function(x){
                    me.origenTabla2.push({idinscripciones: x.idinscripciones,año: x.año, descripcionconcepto: x.descripcionconcepto, descripciontipo: x.descripciontipo, fechaevento: me.$fechaCorta(x.fechaevento,1), textolibre: x.textolibre,importe: x.importe, idestadopago: x.idestadopago, simple:x.simple, idtipoconcepto:x.idtipoconcepto})
                })                
            },
            listarRecibos(){
                let me=this;                                              
                me.$http.get('api/Recibos/FiltrarSocio/'+me.regAct.idsocios).then(function(response){
                me.items3=response.data;
                me.preparaTabla3();                
                }).catch(function(error){
                    console.log(error);
                });
            },
            preparaTabla3(){
                let me=this;
                me.origenTabla3=[];
                me.items3.map(function(x){
                    me.origenTabla3.push({idrecibos:x.idrecibos, año: x.año, recibo: x.recibo, concepto: x.concepto, importe: x.importe, formapago: x.formapago, remesa: x.remesa, fvalor: me.$fechaCorta(x.fvalor,1), idestadopago: x.idestadopago})
                })               
            },
            editarObjeto(id) {
                let me=this;
                const item=me.items1.find(obj => {return Object.values(obj)[0] === id});
                me.regAct=Object.assign({},item);
                me.regAct.ingreso=me.$fechaCorta(item.ingreso,1);
                me.regAct.fnac=me.$fechaCorta(item.fnac,1);
                me.regAnt=Object.assign({},me.regAct);
                me.ibanAnt=item.iban;
                me.editedIndex=1;
                me.adModalNuevoEditar = 1
            },
            eliminarObjeto () {                
                let me=this;
                me.adModalProcesando=1;                                
                me.$http.put('api/Socios/Eliminar',{                        
                        'idsocios':me.regAct.idsocios,
                        'numerosocio':me.regAct.numerosocio,
                        'moduser':me.$store.state.usuario.nombre
                    }).then(function(response){                       
                    me.adModalEliminar=0;   
                    me.nombrecompleto="";
                    me.regAct.idsocios="";
                    me.listarObjetos();
                    me.adModalProcesando=0;                           
                }).catch(function(error){
                    console.log(error);
                });                                
            },
            closeadModalNuevoEditar () {
                let me=this;
                me.adModalNuevoEditar = 0;
                me.borrarVariables();
            },
            borrarVariables(){
                let me=this;
                me.albaranAct={};
                me.licenciaAct={};
                me.fichaAct={};
                me.regAct={idsocios:null,numerosocio:null,apellidos:null,nombre:null,ingreso:new Date().toISOString().slice(0,10),
                direccion: null,idmunicipios: 26,municipio:null,cpostal:null,telefono1:null,telefono2:null,email1:null,email2:null,
                idformapago:1,lopd: true,fnac:null,sexo: true,eliminado:false,documento:null,idtipodocumento:1,iban:null};
                me.regAnt={};
                me.ibanAnt="";
                me.validaMensaje=[];
                me.editedIndex=-1;
                me.items2=[];
                me.recibosAct='';
                me.ADnombre=''
            },
            guardarSocio () {
                let me=this;
                me.adModalProcesando=1;
                const regTmp=Object.assign({},me.regAct);/* Creamos un objeto temporal para este metodo, que no afecte al exterior */
                if (me.validarFormulario()){
                    me.adModalProcesando=0;
                    return; /* Si no pasa la validación de campos regresamos al formulario */
                };
                if (regTmp.iban=='') regTmp.iban=null;
                regTmp.ingreso=me.$fechaLarga(regTmp.ingreso);
                regTmp.fnac=me.$fechaLarga(regTmp.fnac);
                regTmp.moduser=me.$store.state.usuario.nombre;                
                if (me.editedIndex > -1) {
                    //Código para editar
                    var cambiaIban=0;
                    if (regTmp.iban!=me.ibanAnt) cambiaIban=1;       
                    if (me.$hasChanged(me.regAct,me.regAnt)){
                        me.$http.put('api/Socios/Actualizar',regTmp).then(function(response){
                        if (cambiaIban==1) me.actIban(regTmp.idsocios);
                        me.borrarVariables();
                        me.adModalNuevoEditar=0;
                        me.listarObjetos();
                        me.adModalProcesando=0;                        
                    }).catch(function(error){
                        console.log(error);
                    });
                    }
                    else {
                        me.borrarVariables();
                        me.adModalNuevoEditar=0;
                        me.adModalProcesando=0;
                    }                                        
                } else {
                    //Código para guardar
                    me.$http.post('api/Socios/Crear',regTmp).then(function(response){
                        me.borrarVariables();
                        me.adModalNuevoEditar=0;
                        me.listarObjetos();
                        me.adModalProcesando=0;
                    }).catch(function(error){
                        console.log(JSON.parse(JSON.stringify(error)));
                    });
                }
            },
            validarFormulario(){
                let me=this;                
                me.valida=0;
                me.validaMensaje=[];                
                if (me.regAct.ingreso.length!=10 || parseInt(me.regAct.ingreso.substring(0,4))<2006 || parseInt(me.regAct.ingreso.substring(0,4))>me.$añomax){
                    me.validaMensaje.push("El año de FECHA DE INGRESO no es correcta");
                }
                if (me.regAct.fnac!=null && !moment(me.$fechaLarga(me.regAct.fnac), 'DD/MM/YYYY',true).isValid()){
                    me.validaMensaje.push("La FECHA DE NACIMIENTO no es correcta");
                }               
                if (!me.regAct.idmunicipios){
                    me.validaMensaje.push("Debes seleccionar un MUNICIPIO");
                }
                if (!me.regAct.idformapago){
                    me.validaMensaje.push("Debes seleccionar una FORMA DE PAGO");
                }
                if (!me.regAct.idtipodocumento){
                    me.validaMensaje.push("Debes seleccionar un TIPO DE DOCUMENTO");
                }
                if (!me.regAct.numerosocio){
                    me.validaMensaje.push("No has indicado el NÚMERO DE SOCIO");
                }
                if (me.encuentraNumeroSocio()){
                    me.validaMensaje.push("Ya existe otro socio con ese NUMERO DE SOCIO");
                }
                if (me.regAct.iban && !me.validateIBAN(me.regAct.iban)){
                    me.validaMensaje.push("El IBAN introducido no es correcto");
                }   
                if (me.validaMensaje.length){
                    me.valida=1;
                }
                return me.valida;
            },
            encuentraNumeroSocio(){
                let me=this;
                var sw=0;                
                me.items1.forEach(element => {
                    if (element.numerosocio==me.regAct.numerosocio && element.idsocios!=me.regAct.idsocios) sw=1
                });
                return sw;
            },
            mostrarEliminar(id){
                let me=this;
                const item=me.items1.find(obj => {return Object.values(obj)[0] === id});                
                me.regAct.idsocios=id;
                me.regAct.numerosocio=item.numerosocio;
                me.ADnombre=item.socio + '(' + item.numerosocio + ')';
                me.adModalEliminar=1;
            },
            activarHistorico(id){
                let me=this;
                me.searchMem=me.$refs.cabecera.search;
                const item=me.items1.find(obj => {return Object.values(obj)[0] === id});
                me.regAct.idsocios=id;                  
                me.nombrecompleto=item.socio +' ('+item.numerosocio+')';
                me.regAct.telefono1=item.telefono1;
                me.regAct.email1=item.email1;
                me.listarHistorico();                                                  
                me.viendohistorico=1;
                me.$refs.cabecera.search='';                
            },            
            ocultarHistorico(){
                let me=this;
                me.items2=[];
                me.viendohistorico=0;
                me.$refs.cabecera.search=me.searchMem;                
            },
            activarRecibos(id){
                let me=this;
                me.searchMem=me.$refs.cabecera.search;
                const item=me.items1.find(obj => {return Object.values(obj)[0] === id});
                me.regAct.idsocios=id;                  
                me.nombrecompleto=item.socio+' ('+item.numerosocio+')';                
                me.listarRecibos();                                                  
                me.viendorecibos=1;
                me.$refs.cabecera.search='';
            },
            ocultarRecibos(){
                 let me=this;
                me.items3=[];
                me.viendorecibos=0;
                me.$refs.cabecera.search=me.searchMem;                
            },
            listarLineas(id,mostrarAlbaran){
                let me=this;                
                me.$http.get('api/LineasPedidos/ListarPorPedido/'+id).then(function(response){                    
                    me.lineas=response.data;
                    if (mostrarAlbaran) {
                        me.albaranAct.lineas=me.lineas;
                        me.albaranModal=1;
                        me.adModalProcesando=0;
                    }
                }).catch(function(error){
                    console.log(error);
                });
            },
            mostrarDetalleInscripcion(id){
                let me=this;
                me.adModalProcesando=1;
                const item=me.items2.find(obj => {return Object.values(obj)[0] === id});
                if (item.idtipoconcepto==3){
                    me.albaranAct={socio: me.nombrecompleto,telefono:me.regAct.telefono1,email:me.regAct.email1,fecha:me.$fechaCorta(item.fecha),textolibre: item.textolibre, concepto: item.descripcionconcepto};
                    me.listarLineas(id,true);
                }
                else {
                    me.licenciaAct={socio: me.nombrecompleto,telefono:me.regAct.telefono1,email:me.regAct.email1,fecha:me.$fechaCorta(item.fecha),textolibre: item.textolibre, concepto: item.descripcionconcepto};                    
                    me.listarDetalleLicencia(id);                  
                }                
            },
            listarDetalleLicencia(id){
                let me=this;                
                me.$http.get('api/InscripcionesLicencias/ListarDetalle/'+id).then(function(response){                    
                    var detallelicencia=response.data;
                    me.licenciaAct.tipolicencia=detallelicencia[0].tipolicencia;
                    me.licenciaAct.renovacion=detallelicencia[0].renovacion ? 'SI':'NO';
                    me.licenciaAct.docuok=detallelicencia[0].docuok ? 'SI':'NO';
                    me.licenciaAct.importe=detallelicencia[0].importe;
                    me.licenciaModal=1;
                    me.adModalProcesando=0;
                }).catch(function(error){
                    console.log(error);
                });                
            },
            actIban(socioId){
                let me=this;                                         
                me.$http.get('api/Recibos/ActualizarIban/'+socioId).then(function(response){
                me.recibosAct=response.data;
                if (me.recibosAct>0) me.adModalRecibosActualizado=1;
                }).catch(function(error){
                    console.log(error);
                });                
            },
            ocultarformNuevoMunicipio() {                
                let me=this;
                me.formNuevoMunicipio = 0;
                me.regActMun={idmunicipios:'',
                    nombre:[],codigopostal:[],territorio:[]},
                me.ADcampo='';
                me.ADtexto='';               
            },
            guardarMunicipio(regAct) {
                let me=this;
                me.adModalProcesando=1;
                me.formNuevoMunicipio = 0;
                var existeRepeticion=false;
                regAct=me.eliminarPropsMun(regAct);                
                if (me.encuentraRepetición('idmunicipios','text',regAct.nombre)==1) {
                    me.ADtexto=regAct.nombre;
                    me.ADcampo='Nombre';
                    me.adModalProcesando=0;
                    me.adModalTextoRepetido=1;
                    me.regAct=Object.assign({},regAct);
                    me.añadirPropsMun();
                    me.formNuevoMunicipio = 1;
                    return;
                };
                //Código para guardar          
                me.$http.post('api/Municipios/Crear',{ 
                    'nombre': regAct.nombre,'codigopostal': regAct.codigopostal,'territorio': regAct.territorio                                            
                }).then(function(response){
                    me.municipios=[];                    
                    me.listarMunicipios();
                    me.regActMun={idmunicipios:'',
                    nombre:[],codigopostal:[],territorio:[]},
                    me.ADcampo='';
                    me.ADtexto='';
                    me.regAct.idmunicipios=response.data;                    
                    me.adModalProcesando=0;                     
                }).catch(function(error){
                    console.log(error);
                });
            },
            añadirPropsMun(){
                let me=this;
                me.regActMun= {idmunicipios:me.regActMun.idmunicipios,
                nombre: {
                    tipo:'text',etiqueta:'Nombre',valor:me.regActMun.nombre,maxlength: 30,required: true
                },
                codigopostal: {
                    tipo:'text',etiqueta:'Código Postal',valor:me.regActMun.codigopostal,maxlength: 5,required: true
                },
                territorio: {
                    tipo:'text',etiqueta:'Territorio',valor:me.regActMun.territorio,maxlength: 25,required: true
                }
                };
            },
            eliminarPropsMun(miObjeto){
                let me=this;              
                Object.defineProperties(miObjeto, { 
                    "nombre": {
                        value: miObjeto.nombre.valor
                    },
                    "codigopostal": {
                        value: miObjeto.codigopostal.valor
                    },
                    "territorio": {
                        value: miObjeto.territorio.valor
                    }
                });
                return miObjeto;
            },
            encuentraRepetición(id,campo,texto){
                let me=this;
                var sw=0;                
                for(var i=0;i<me.municipios.length;i++){                    
                    if (me.municipios[i][campo].toUpperCase()==texto.toUpperCase() && me.municipios[i][id]!=me.regAct[id]){
                        sw=1;    
                    }
                }               
                return sw;
            }            
        }        
    }
</script>