<template>
    <v-container fluid>
        <v-row align-start>
            <v-col>
                <Cabecera
                    v-bind:objeto=ADobjetos
                    v-bind:ocultaNuevo=true
                    @hook:mounted="activarWatcher"
                    ref="cabecera"                             
                />
                <TablaDatos
                    padre="Remesas"
                    v-bind:opcionesTabla=opcionesTabla1                    
                    v-bind:items='origenTabla1'
                    v-bind:headers=headerRemesas
                    v-bind:search=search
                    v-on:editarObjeto="editarObjeto"
                    v-on:mostrarEliminar="mostrarEliminarRemesa"
                    v-on:mostrarRegistrar="mostrarCobro"
                />                
            </v-col>
            <v-dialog v-model="adModalProcesando" max-width="300" persistent>
                <Procesando/> 
            </v-dialog>
            <v-dialog v-model="adModalCobrar" max-width="430" persistent>
                <ModalTipo2
                    v-if="adModalCobrar==1"                
                    titulo= "¿Registrar el cobro de la remesa?"
                    :mensaje="'Estás a punto de registrar el cobro de la remesa <strong>' + adRemesa + '</strong>?'"
                    tipoModal="Cobro"
                    icono="hand-holding-usd"
                    v-bind:GrupoContableCobro=GrupoContableCobro
                    v-bind:idgrupocontableAct=regAct.idgruposcontables
                    v-on:cancelarClick="adModalCobrar=0"
                    v-on:aceptarClick="registrarCobro"    
                />
            </v-dialog>
            <v-dialog v-model="adModalEditar" max-width="900px" persistent>
                <v-card>
                    <v-card-title>
                        <span class="headline">Modificar Remesa <strong>{{ adRemesa }}</strong></span>
                    </v-card-title>                                    
                    <v-card-text>
                        <v-container grid-list-md>
                            <v-row wrap>
                                <TablaDatos
                                    v-bind:opcionesTabla=opcionesTabla2                    
                                    v-bind:items='origenTabla2'
                                    v-bind:headers=headerRecibos
                                    v-on:mostrarEliminar="mostrarDesvincularRecibo"
                                />
                            </v-row>
                        </v-container>
                    </v-card-text>                
                    <v-card-actions>
                        <v-spacer></v-spacer>                                
                        <v-btn color="accent" dark class="mb-2" text @click.native="ocultarFormularioEditar">Cerrar</v-btn>
                        
                    </v-card-actions>
                </v-card>
            </v-dialog>            

            <v-dialog v-model="adModalEliminarRemesa" max-width="500" persistent>
                <ModalTipo1                
                    titulo="¿Eliminar esta Remesa?"
                    :mensaje="'Estás a punto de eliminar toda la Remesa <strong>' + adRemesa + '</strong>, los recibos pasarán a estado PENDIENTE'"
                    v-bind:aceptarCancelar="true"
                    v-on:cancelarClick="adModalEliminarRemesa=0"
                    v-on:aceptarClick="eliminarObjeto"    
                />
            </v-dialog>
            <v-dialog v-model="adModalDesvincularRecibo" max-width="500">
                <ModalTipo1                
                    titulo="¿Desvincular este Recibo?"
                    :mensaje="'Estás a punto de desvincular el Recibo <strong>' + adRecibo + '</strong>, de la Remesa <strong>' + adRemesa + '</strong>'"
                    v-bind:aceptarCancelar="true"
                    v-on:cancelarClick="adModalDesvincularRecibo=0"
                    v-on:aceptarClick="desvincularRecibo"    
                />
            </v-dialog> 
        </v-row>
    </v-container>
</template>
<script>    
    export default {
        data(){
            return {                
                headerRemesas: [
                    { text: 'Opciones', value: 'opciones', sortable: false,class: 'my-header-style' },
                    { text: 'Clave', value: 'clave', sortable: true,class: 'my-header-style' },
                    { text: 'Concepto', value: 'concepto', sortable: true,class: 'my-header-style' },                                                            
                    { text: 'Banco', value: 'banco', sortable: true,class: 'my-header-style' },
                    { text: 'Recibos', value: 'recibos', sortable: true,class: 'my-header-style' },
                    { text: 'Importe', value: 'importe', sortable: true,class: 'my-header-style' },
                    { text: 'Fecha Valor', value: 'fvalor', sortable: true,class: 'my-header-style' },],
                headerRecibos: [
                    { text: 'Opciones', value: 'opciones', sortable: false,class: 'my-header-style' },
                    { text: 'Recibo', value: 'recibo', sortable: true,class: 'my-header-style' },
                    { text: 'Socio', value: 'socio', sortable: true,class: 'my-header-style' },                                                            
                    { text: 'Importe', value: 'importe', sortable: true,class: 'my-header-style' },
                    { text: 'Banco', value: 'banco', sortable: true,class: 'my-header-style' },],
                adModalEditar: 0,
                adModalEliminarRemesa:'',
                adModalDesvincularRecibo:'',                
                adModalCobrar: 0,
                adModalProcesando:0,
                search: '',
                items:[],
                recibos:[],
                GrupoContableCobro:[],
                origenTabla1:[],
                origenTabla2:[],
                bancosArray:[],
                adRemesa: '',
                adRecibo: '',                
                idrecibosAct:'',
                reciboAct:'',
                importeAct:'',
                regAct:{idremesas:'',clave:'',fvalor:new Date().toISOString().slice(0,10),idgruposcontables:'',idconceptos:'',iban:''},
                ADobjetos:'Remesas bancarias',
                opcionesTabla1:{Seleccion:false, AbrirCerrar:false, ActivarBloquear:false, Visualizar:false, Modificar:true, Eliminar:true, Historico:false, Recibos:false,
		Contabilizar:false, Descargar:false, Cobrar:true, Devolver:false},
                opcionesTabla2:{Seleccion:false, AbrirCerrar:false, ActivarBloquear:false, Visualizar:false, Modificar:false, Eliminar:true, Historico:false, Recibos:false,
		Contabilizar:false, Descargar:false, Cobrar:false, Devolver:false},                
            }
        },
        created(){
            let me=this;
            me.adModalProcesando=1;
            me.listarBancos()
            me.listarObjetos();                        
            me.listarGrupoContableCobro();
        },
        methods:{           
            nombreBanco(IBAN){
                let me=this;
                if (IBAN==null) return 'desconocido'
                const codigo=IBAN.substring(4,8);
                var entidad=me.bancosArray.find(element => element.codigo === codigo);
                return entidad ? entidad.descripcion : 'desconocido';
            },
            listarBancos(){
                let me=this;                 
                me.$http.get('api/bancos/Listar').then(function(response){
                    me.bancosArray=response.data;                     
                }).catch(function(error){
                    console.log(error);
                });
            },
            activarWatcher(){
                let me=this;
			    me.$watch(
			    "$refs.cabecera.search",
			    (new_value, old_value) => (me.search = new_value)
                );
            },            
            listarObjetos(){
                let me=this;
                me.$http.get('api/Remesas/Listar').then(function(response){
                    me.items=response.data;
                    me.preparaTabla1()
                    me.adModalProcesando=0;
                }).catch(function(error){
                    console.log(error);
                });
            },
            preparaTabla1(){
                let me=this;
                me.origenTabla1=[];
                me.items.map(function(x){
                    me.origenTabla1.push({idobjeto: x.idremesas,clave:x.clave,concepto:x.concepto,banco: me.nombreBanco(x.iban),recibos:x.recibos,importe:x.importe,fvalor: me.$fechaCorta(x.fvalor,2)});
                })                
            },
            listarRecibos() {
                let me=this;
                me.adModalProcesando=1;
                me.$http.get('api/Recibos/RecibosPorRemesa/'+me.regAct.idremesas).then(function(response){
                    me.recibos=response.data;
                    me.preparaTabla2()
                    me.adModalProcesando=0;
                }).catch(function(error){
                    console.log(error);
                });                
            },
            preparaTabla2(){
                let me=this;
                me.origenTabla2=[];
                me.recibos.map(function(x){
                    me.origenTabla2.push({idrecibos: x.idrecibos,recibo:x.recibo,socio:x.socio,importe:x.importe,banco: me.nombreBanco(x.iban)});
                })                
            },     
            listarGrupoContableCobro(){
                let me=this;
                var GrupoContableCobroArray=[]; 
                me.$http.get('api/GruposContables/GrupoContableCobro').then(function(response){
                    GrupoContableCobroArray=response.data;
                    GrupoContableCobroArray.map(function(x){
                    me.GrupoContableCobro.push({text: x.descripcion,value:x.idgruposcontables});
                    });                    
                }).catch(function(error){
                    console.log(error);
                });
            },
            editarObjeto(id) {
                let me=this;                
                me.cargarVariables(id);
                me.listarRecibos();
                me.adRemesa=me.regAct.clave;            
                me.adModalEditar = 1;
            },
            ocultarFormularioEditar() {
                let me=this;
                me.adModalEditar = 0;                
                me.borrarVariables();
                me.listarObjetos();
            },            
            guardarRemesa() {
                let me=this;
                me.adModalProcesando=1;                
                me.$http.put('api/Remesas/Contabilizar',{
                        'idremesas':me.regAct.idremesas,
                        'clave':me.regAct.clave,
                        'fvalor':me.$fechaLarga(me.regAct.fvalor),
                        'iban':me.regAct.iban,                        
                        'idgruposcontables':me.regAct.idgruposcontables,
                        'idconceptos':me.regAct.idconceptos,                                                                   
                    }).then(function(response){ 
                        me.borrarVariables();
                        me.listarObjetos();  
                    }).catch(function(error){
                    console.log(error);
                });               
            },
            mostrarEliminarRemesa(id){
                let me=this;
                const item=me.items.find(obj => {return Object.values(obj)[0] === id});
                me.adModalEliminarRemesa=1;
                me.adRemesa=item.clave;
                me.regAct.idremesas=item.idremesas;
            },
            mostrarDesvincularRecibo(id){
                let me=this;
                const item=me.recibos.find(obj => {return Object.values(obj)[0] === id});
                me.adModalDesvincularRecibo=1;
                me.adRemesa=me.regAct.clave;
                me.adRecibo=item.recibo;
                me.idrecibosAct=item.idrecibos;
                me.reciboAct=item.recibo;
            },  
            eliminarObjeto(){                
                let me=this;
                me.adModalProcesando=1;
                me.$http.put('api/Remesas/Eliminar/'+me.regAct.idremesas,{}).then(function(response){                       
                    me.adModalEliminarRemesa=0;   
                    me.borrarVariables();
                    me.listarObjetos();                           
                }).catch(function(error){
                    console.log(error);
                });                               
            },
            desvincularRecibo() {
                let me=this;
                me.adModalProcesando=1;                
                me.$http.put('api/Recibos/Desvincular',{
                        'idrecibos':me.idrecibosAct,                        
                        'idremesas':null,                        
                        'idestadopago':1                                                                 
                    }).then(function(response){                        
                        me.adModalDesvincularRecibo=0;
                        me.listarRecibos();
                        me.adModalProcesando=0;                        
                    }).catch(function(error){
                    console.log(error);
                });               
            },            
            mostrarCobro(id){                
                let me=this;
                me.cargarVariables(id);
                me.adRemesa=me.regAct.clave;                
                me.adModalCobrar=1;                
            },
            registrarCobro(fvalor,grupocontable){                
                let me=this;
                me.adModalProcesando=1;
                me.adModalCobrar=0;
                me.regAct.fvalor=fvalor;
                me.regAct.idgruposcontables=grupocontable;                
                me.guardarRemesa();                            
                me.crearApunte();                
                me.listarObjetos;
            },            
            crearApunte() {                
                let me=this;
                me.adModalProcesando=1;                    
                me.$http.post('api/ApuntesContables/Crear',{
                    'fvalor':me.$fechaLarga(me.regAct.fvalor),
                    'detalle':'Cobro Remesa '+me.regAct.clave,
                    'idgruposcontables':me.regAct.idgruposcontables,                
                    'importe':me.importeAct,
                    'idconceptos':me.regAct.idconceptos,
                    'eliminable':false,                                           
                }).then(function(response){
                    me.adModalProcesando=0;
                }).catch(function(error){
                    console.log(error);
                });    
            },            
            cargarVariables(id){
                let me=this;
                const item=me.items.find(obj => {return Object.values(obj)[0] === id});                
                me.regAct.idremesas=item.idremesas;                
                me.importeAct=item.importe;
                if (item.fvalor) me.regAct.fvalor=me.$fechaCorta(item.fvalor,1);                
                me.regAct.clave=item.clave             
                me.regAct.idgruposcontables=item.idgruposcontables;
                me.regAct.idconceptos=item.idconceptos;
                me.regAct.iban=item.iban;                
            },
            borrarVariables(){
                let me=this;
                me.adRemesa='';
                me.importeAct='';
                me.regAct={idremesas:'',clave:'',fvalor:new Date().toISOString().slice(0,10),idgruposcontables:'',idconceptos:'',iban:''};
            }        
        }        
    }
</script>