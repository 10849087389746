<template>
    <v-row align-start>
        <v-col>
            <Cabecera                    
                v-bind:objeto=ADobjetos
                v-bind:años=años
                v-bind:SelAño=SelAño                    
                v-on:Nuevo="nuevoObjeto"                
                @hook:mounted="activarWatcher" 
                ref="cabecera"
                v-model="SelAño"                    
            />
            <v-dialog v-model="adModalProcesando" max-width="300" persistent>
                <Procesando/> 
            </v-dialog>
            <v-dialog v-model="adModalNuevoEditar" max-width="600px" persistent>
                <EditarNuevo                    
                    v-bind:formTitle=formTitle 
                    :regAct=Object.assign({},regAct)
                    v-bind:camposEditables=camposEditables 
                    v-on:ocultarFormEditarNuevo="ocultarFormEditarNuevo"
                    v-on:guardarObjeto="guardarObjeto"               
                />
            </v-dialog>
            <v-dialog v-model="adModalEliminar" max-width="500" persistent>
                <ModalTipo1                
                    :titulo="'Eliminar ' + ADobjeto"
                    :mensaje="'Estás a punto de eliminar ' + ADobjeto + ' <strong>' + 
                        ADnombre + '</strong> ¿Quieres continuar?'"
                    v-bind:aceptarCancelar="true"
                    v-on:cancelarClick="adModalEliminar=0"
                    v-on:aceptarClick="eliminarObjeto"    
                />
            </v-dialog>
            <br>
            <TablaDatos
                padre="Apuntes"
                v-bind:opcionesTabla=opcionesTabla                    
                v-bind:items=origenTabla
                v-bind:headers=headers
                v-bind:search=search                
                v-on:editarObjeto="editarObjeto"
                v-on:mostrarEliminar="mostrarEliminar"
                v-on:listarObjetos="listarObjetos"                    
            />
        </v-col>
    </v-row>
</template>
<script>
    export default {
        data(){
            return {                
                headers: [
                    { text: 'Opciones', value: 'opciones', sortable: false,class: 'my-header-style' },
                    { text: 'Fecha', value: 'fvalor', sortable: true,class: 'my-header-style' },
                    { text: 'Detalle', value: 'detalle', sortable: true,class: 'my-header-style' },
                    { text: 'Actividad', value: 'concepto', sortable: true,class: 'my-header-style' },
                    { text: 'Grupo Contable', value: 'grupocontable', sortable: true,class: 'my-header-style' },
                    { text: 'Importe', value: 'importe', sortable: true,class: 'my-header-style' },
                    { text: 'Saldo', value: 'saldoposterior', sortable: true,class: 'my-header-style' },                               
                ],
                items:[],
                gruposcontables:[],
                conceptos:[],
                años:[],
                origenTabla:[],                
                search: '',                
                adModalEliminar:'',
                adModalProcesando:0,                            
                adModalNuevoEditar: 0,
                editedIndex: -1,
                regAct:{idapuntescontables:'',fvalor:'',detalle:'',idgruposcontables:'',importe:'',
                    idconceptos:'',eliminable:1},
                regAnt:{},
                SelAño:'',                                
                ADobjeto:'Apunte Contable',
                ADobjetos:'Apuntes Contables',
                camposEditables:5,                
                opcionesTabla:{Seleccion:false, AbrirCerrar:false, ActivarBloquear:false, Visualizar:false, Modificar:true, Eliminar:true, Historico:false, Recibos:false,
		Contabilizar:false, Descargar:false},
                ADnombre:''        
            }
        },
        computed: {
            formTitle() {
                let me=this;
                return me.editedIndex === -1 ? 'Nuevo Apunte Contable' : 'Modificar Apunte Contable'
            },
        },
        watch: {
            SelAño (val) {
                let me=this;
                me.adModalProcesando=1;
                me.listarObjetos();
            }  
        },
        created(){
            let me=this;
            me.adModalProcesando=1;
            me.listarGruposContables();
            me.listarConceptos();
            me.listarAños();            
        },
        methods:{
            activarWatcher(){
                let me=this;
                me.$watch(
                "$refs.cabecera.search",
                (new_value, old_value) => (me.search = new_value)
                    );
            },            
            listarObjetos(){
                let me=this;
                me.$http.get('api/ApuntesContables/ListarPorAno/'+me.SelAño).then(function(response){
                    me.items=response.data;
                    me.preparaTabla();
                    me.adModalProcesando=0;
                }).catch(function(error){
                    console.log(error);
                });
            },
            preparaTabla(){
                let me=this;
                me.origenTabla=[];
                me.items.map(function(x){
                    me.origenTabla.push({idapuntescontables: x.idapuntescontables,fvalor: me.$fechaCorta(x.fvalor), detalle:x.detalle, concepto:x.concepto, grupocontable: x.grupocontable, importe: x.importe, saldoposterior:new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(x.saldoposterior),eliminable:x.eliminable});
                })                
            },
            añadirProps(){
                let me=this;
                me.regAct={idapuntescontables:me.regAct.idapuntescontables,                    
                    fvalor:{
                        tipo:'date',etiqueta:'Fecha Valor',valor:me.$fechaCorta(me.regAct.fvalor,1),maxlength: 10,required: true
                    },                    
                    idgruposcontables:{
                        tipo:'sel',opciones:me.gruposcontables,etiqueta:'Grupo Contable',valor:me.regAct.idgruposcontables,maxlength:48,required: true
                    },
                    importe:{
                        tipo:'money',etiqueta:'Importe',valor:me.regAct.importe,maxlength: 3,required: true,disabled: me.regAct.eliminable==0                        
                    },
                    idconceptos:{
                        tipo:'sel',opciones:me.conceptos,etiqueta:'Actividad',valor:me.regAct.idconceptos,maxlength:40,required: false
                    },
                    detalle:{
                        tipo:'text',etiqueta:'Detalle',valor:me.regAct.detalle,maxlength: 50,required: true    
                    },
                    eliminable:me.regAct.eliminable
                };   
            },
            eliminarProps(miObjeto){
                let me=this;              
                Object.defineProperties(miObjeto, { 
                    "fvalor": {
                        value: me.$fechaLarga(miObjeto.fvalor.valor) 
                    },
                    "idgruposcontables": {
                        value: miObjeto.idgruposcontables.valor
                    },
                    "importe": {
                        value: miObjeto.importe.valor
                    },
                    "idconceptos": {
                        value: miObjeto.idconceptos.valor
                    },
                    "detalle": {
                        value: miObjeto.detalle.valor
                    },
                });
                return miObjeto;
            },
            listarAños(){
                let me=this;
                var añosArray=[];
                me.años=[];
                me.$http.get('api/ApuntesContables/ListarAños').then(function(response){
                    añosArray=response.data;
                    añosArray.map(function(x){
                    me.años.push({text: x.año,value:x.año});
                    });                    
                    /* if (!me.SelAño || me.SelAño<añosArray[0].año || me.SelAño>añosArray[0].año) me.SelAño=añosArray[0].año; */
                    if (me.SelAño=='') me.SelAño=añosArray[0].año;
                    me.listarObjetos();                    
                }).catch(function(error){
                    console.log(error);
                });
            },            
            editarObjeto(id) {
                let me=this;
                const item=me.items.find(obj => {return Object.values(obj)[0] === id});
                me.regAct=Object.assign({},item);                
                me.regAnt=Object.assign({},item);
                me.regAnt.fvalor=me.$fechaLarga(me.regAnt.fvalor);
                delete me.regAnt.concepto;
                delete me.regAnt.grupocontable;
                delete me.regAnt.saldoposterior;
                me.añadirProps();
                me.editedIndex=1;
                me.adModalNuevoEditar = 1
            },
            listarGruposContables(){
                let me=this;
                var gruposcontablesArray=[]; 
                me.$http.get('api/GruposContables/Listar').then(function(response){
                    gruposcontablesArray=response.data;
                    gruposcontablesArray.map(function(x){
                    me.gruposcontables.push({text: x.descripcion,value:x.idgruposcontables});
                    }); 
                }).catch(function(error){
                    console.log(error);
                });
            },
            listarConceptos(){
                let me=this;
                var conceptosArray=[]; 
                me.$http.get('api/Conceptos/Listar').then(function(response){
                    conceptosArray=response.data;
                    me.conceptos.push({text:'',value:null});
                    conceptosArray.map(function(x){
                    me.conceptos.push({text: x.descripcion,value:x.idconceptos});
                    }); 
                }).catch(function(error){
                    console.log(error);
                });
            },            
            eliminarObjeto() {
                let me=this;
                me.adModalProcesando=1; 
                me.$http.put('api/ApuntesContables/Eliminar/'+me.regAct.idapuntescontables,{}).then(function(response){
                    me.borrarVariables();
                    if (me.items.length==1) me.SelAño='';
                    me.listarAños();
                    me.adModalEliminar=0;
                    me.adModalProcesando=0;                           
                }).catch(function(error){
                    console.log(error);
                });                                
            },
            ocultarFormEditarNuevo() {
                let me=this;
                me.adModalNuevoEditar = 0;
                me.borrarVariables();
            },
            borrarVariables(){
                let me=this;
                me.regAct={idapuntescontables:'',fvalor:'',detalle:'',idgruposcontables:'',importe:'',
                    idconceptos:'',eliminable:1};
                me.editedIndex=-1
            },
            guardarObjeto(regAct) {                
                let me=this;                                
                me.adModalProcesando=1;
                regAct=me.eliminarProps(regAct);                
                const regTmp=Object.assign({},regAct);/* Creamos un objeto temporal para este metodo, que no afecte al exterior */                 
                if (me.editedIndex > -1) {
                    //Código para editar
                    if (me.$hasChanged(regTmp,me.regAnt)){                            
                        me.$http.put('api/ApuntesContables/Actualizar',regTmp).then(function(response){
                            me.listarAños();
                            me.borrarVariables();
                            me.adModalNuevoEditar=0;
                            me.adModalProcesando=0;                                
                        }).catch(function(error){
                            console.log(error);
                        });
                    }
                    else {
                        me.borrarVariables();
                        me.adModalNuevoEditar=0;
                        me.adModalProcesando=0;
                    }
                } else {
                    //Código para guardar        
                    me.$http.post('api/ApuntesContables/Crear',regTmp).then(function(response){
                        me.listarAños();
                        me.borrarVariables();
                        me.adModalNuevoEditar=0;
                        me.adModalProcesando=0;                        
                    }).catch(function(error){
                        console.log(error);
                    });
                }
            },        
            mostrarEliminar(id){
                let me=this;                       
                const item=me.items.find(obj => {return Object.values(obj)[0] === id});
                me.regAct.idapuntescontables=id;
                me.regAct.fvalor=me.$fechaCorta(item.fvalor,2);
                me.ADnombre=' de fecha ' + me.regAct.fvalor;
                me.adModalEliminar=1;                
            },
            nuevoObjeto() {
                let me=this;                              
                me.añadirProps();
                me.adModalNuevoEditar=1
            },  
        }        
    }
</script>