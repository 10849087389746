<template>
    <v-row align-start>
        <v-col cols="12">
            <v-toolbar dark src="https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg" height='80px'>
                <v-toolbar-title>Informe Económico</v-toolbar-title>
                <v-divider
                class="mx-2"
                inset
                vertical
                ></v-divider>
                <v-spacer></v-spacer>                    
                <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-col cols="2">
                            <v-text-field
                                v-model="dateFormatted1"
                                label="Desde:"
                                hint="DD/MM/AAAA"
                                persistent-hint
                                prepend-icon="mdi-calendar"
                                v-bind="attrs"
                                @blur="date1 = parseDate(dateFormatted1)"
                                v-on="on"
                            ></v-text-field>
                        </v-col>
                    </template>
                    <v-date-picker
                        v-model="date1"
                        no-title
                        @input="menu1 = false"
                        min="2011-11-15"
                        :max="maxDate"
                    ></v-date-picker>
                </v-menu>
                <v-spacer></v-spacer>
                <v-menu
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-col cols="2">
                        <v-text-field
                            v-model="dateFormatted2"
                            label="Hasta:"
                            hint="DD/MM/AAAA"
                            persistent-hint
                            prepend-icon="mdi-calendar"
                            v-bind="attrs"
                            @blur="date2 = parseDate(dateFormatted2)"
                            v-on="on"
                        ></v-text-field>
                        </v-col>
                    </template>
                    <v-date-picker
                        v-model="date2"
                        no-title
                        @input="menu2 = false"
                        :min="minDate"
                        :max="maxDate"
                    ></v-date-picker>
                </v-menu>
                <v-spacer></v-spacer>
                <v-btn color="accent" dark class="mb-2" @click.native="listarApuntes">Cargar</v-btn>
                <v-spacer></v-spacer>                    
                <v-col cols="3">
                    <v-btn small class="mx-2" 
                        rounded                            
                        color="accent"
                        @click="crearPDF()"
                        v-if="mostrarElementos"
                        >
                        <v-icon dark>
                            Pdf
                        </v-icon>
                    </v-btn>     
                    <v-btn small class="mx-2" 
                        rounded                            
                        color="accent"
                        @click="crearPDFdetalle()"
                        v-if="mostrarElementos"
                        >
                        <v-icon dark>
                            Pdf Detalle
                        </v-icon>
                    </v-btn>                   
                </v-col>
                <v-spacer></v-spacer>
            </v-toolbar>
        </v-col>
        <v-col>            
            <v-expansion-panels v-if="mostrarElementos">
                <v-expansion-panel
                    v-for="(item,i) in informe.arrayGrupos"
                    :key="i"
                >
                <v-expansion-panel-header expand-icon="mdi-menu-down">
                    <div >
                        <table width="90%">
                            <tr>
                                <td><strong>{{informe.arrayGrupos[i].grupocontable}}</strong></td>
                                <td align ="right"><strong>{{$formatPrice(informe.arrayGrupos[i].total)}}</strong></td>
                            </tr>
                        </table>
                    </div>                    
                </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-expansion-panels v-if="mostrarElementos">
                            <v-expansion-panel
                                v-for="(item,x) in informe.arrayGrupos[i].arrayConceptos"
                                :key="x"
                            >
                                <v-expansion-panel-header>
                                    <div >
                                        <table width="90%">
                                            <tr>                                                
                                                <td><strong>{{informe.arrayGrupos[i].arrayConceptos[x].concepto}}</strong></td>                                                
                                                <td align ="right"><strong>{{$formatPrice(informe.arrayGrupos[i].arrayConceptos[x].total)}}</strong></td>
                                            </tr>
                                        </table>
                                    </div>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <br>
                                    <v-data-table
                                    :headers="headers"
                                    :items="informe.arrayGrupos[i].arrayConceptos[x].arrayApuntes"                                    
                                    class="elevation-1"
                                    hide-default-footer
                                    dense>
                                    <template slot="item" slot-scope="props">
                                        <tr>
                                            <td>{{ $fechaCorta(props.item.fvalor,2) }}</td>
                                            <td>{{ props.item.detalle}}</td>
                                            <td align ="right">{{$formatPrice(props.item.importe)}}</td>
                                        </tr>
                                    </template>
                                    </v-data-table>
                                </v-expansion-panel-content>                    
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <br>
            <v-simple-table v-if="mostrarElementos">
                <template v-slot:default>                    
                <tbody>
                    <tr>
                    <td><h1><strong>RESULTADO: </strong></h1></td>
                    <td align ="right"><h1>{{$formatPrice(informe.total)}}</h1></td>
                    </tr>
                </tbody>
                </template>
            </v-simple-table>                
        </v-col>
        <v-dialog v-model="adModalProcesando" max-width="300" persistent>
            <Procesando/> 
        </v-dialog>
    </v-row>
</template>
<script>    
    import jsPDF from 'jspdf';
    import autoTable from 'jspdf-autotable'; 
    export default {
        data(){
            return {
                informe:{},
                adModalProcesando:0,                
                date1: (new Date().getFullYear()+'-01-01'),
                date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),                
                dateFormatted1: '01/01/'+(new Date().getFullYear()),
                dateFormatted2: this.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
                menu1: false,
                menu2: false,
                mostrarElementos:0,
                logo: require('@/assets/Armarria.png'),
                headers: [
                    { text: 'F. Valor', value: 'fvalor', sortable: false,class: 'my-header-style' },
                    { text: 'Detalle', value: 'detalle', sortable: false,class: 'my-header-style' },
                    { text: 'Importe', value: 'importe', sortable: false,class: 'my-header-style' },                                                   
                ],                                       
            }
        },
        computed: {
            maxDate() {
                const today = new Date();
                // const dd = today.getUTCDate();
                return today.toISOString().substring(0,10);
            },
            minDate() {
                return this.date1;
            },            
        },
        watch: {
            date1 (val) {
                this.dateFormatted1 = this.formatDate(this.date1)
            },
            date2 (val) {
                this.dateFormatted2 = this.formatDate(this.date2)
            },
        },        
        methods:{          
            listarApuntes(){
                let me=this;
                me.adModalProcesando=1;
                me.$http.get('api/ApuntesContables/ListarPorFechas/'+me.date1+'/'+me.date2).then(function(response){
                    me.apuntes=response.data;
                    /* primero ordenamos por fechas */
                    me.apuntes.sort(function (a,b){
                        var c = new Date(a.fvalor);
                        var d = new Date(b.fvalor);
                        return c-d;
                    });
                    /* luego ordenamos por conceptos */ 
                    me.apuntes.sort(function (a,b){                        
                        return (a.idconceptos-b.idconceptos);
                    });                 
                    /* y finalmente por grupocontable */
                    me.apuntes.sort(function (a,b){                        
                        return (a.idgruposcontables-b.idgruposcontables);
                    });
                    /* Ahora debemos recorrer el array de objetos para extraer informacion y calcular totales */
                    var arrayGrupos=[], arrayConceptos=[], arrayApuntes=[];
                    var totConcepto=0,totGrupoContable=0,totGeneral=0;
                    var antIdConceptos='',antIdGruposcontables='',antConcepto='',antGrupocontable='';
                    me.apuntes.forEach(function(element) {
                        if (arrayApuntes.length==0){
                            arrayApuntes.push({idapuntescontables: element.idapuntescontables,detalle: element.detalle, fvalor: element.fvalor, importe: element.importe});
                            totConcepto += element.importe;
                            antIdConceptos=element.idconceptos;
                            antConcepto=element.concepto || 'SIN ACTIVIDAD ASOCIADA';
                            antIdGruposcontables=element.idgruposcontables;
                            antGrupocontable=element.grupocontable;
                            return; 
                        }
                        if (element.idgruposcontables==antIdGruposcontables){
                            if (element.idconceptos==antIdConceptos){
                                arrayApuntes.push({idapuntescontables: element.idapuntescontables,detalle: element.detalle, fvalor: element.fvalor, importe: element.importe});
                                totConcepto += element.importe;
                            }
                            else {
                                totGrupoContable += totConcepto;                                                                
                                arrayConceptos.push({arrayApuntes: arrayApuntes,idconceptos: antIdConceptos, concepto: antConcepto,total: totConcepto});
                                arrayApuntes=[];
                                arrayApuntes.push({idapuntescontables: element.idapuntescontables,detalle: element.detalle, fvalor: element.fvalor, importe: element.importe});
                                totConcepto = element.importe;
                                antIdConceptos=element.idconceptos;
                                antConcepto=element.concepto || 'SIN ACTIVIDAD ASOCIADA';
                            }

                        }
                        else {
                            totGrupoContable += totConcepto;
                            arrayConceptos.push({arrayApuntes: arrayApuntes,idconceptos: antIdConceptos, concepto: antConcepto,total: totConcepto});
                            arrayApuntes=[];
                            totGeneral += totGrupoContable;
                            arrayGrupos.push({arrayConceptos: arrayConceptos,idgruposcontables: antIdGruposcontables,grupocontable: antGrupocontable, total: totGrupoContable});
                            arrayConceptos=[];
                            arrayApuntes.push({idapuntescontables: element.idapuntescontables,detalle: element.detalle, fvalor: element.fvalor, importe: element.importe});
                            totConcepto = element.importe;
                            totGrupoContable=0;
                            antIdConceptos=element.idconceptos;
                            antIdGruposcontables=element.idgruposcontables;
                            antConcepto=element.concepto || 'SIN ACTIVIDAD ASOCIADA';
                            antGrupocontable=element.grupocontable;
                        }                        
                    });
                    totGrupoContable += totConcepto;
                    arrayConceptos.push({arrayApuntes: arrayApuntes,idconceptos: antIdConceptos, concepto: antConcepto,total: totConcepto});
                    totGeneral += totGrupoContable;
                    arrayGrupos.push({arrayConceptos: arrayConceptos,idgruposcontables: antIdGruposcontables,grupocontable: antGrupocontable, total: totGrupoContable});
                    me.informe=({arrayGrupos: arrayGrupos,total: totGeneral})
                    arrayGrupos=[];
                    arrayConceptos=[];
                    me.adModalProcesando=0;
                    me.mostrarElementos=1;                    
                }).catch(function(error){
                    console.log(error);
                });
            },
            formatDate (date) {
                if (!date) return null
                const [year, month, day] = date.split('-')
                return `${day}/${month}/${year}`
            },
            parseDate (date) {
                if (!date) return null
                const [day, month, year] = date.split('/')
                return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
            },
            crearPDFdetalle(){                
                var me = this;
                var doc = new jsPDF('p', 'pt');
                var titulo= 'Informe Económico desde ' + me.dateFormatted1 + ' hasta ' + me.dateFormatted2;
                //Diseñamos la cabecera
                var img=new Image();
                img.src=me.logo;
                doc.addImage(img, 'JPEG', 60, 20, 40, 50);
                //Definimos el título                
                doc.setFontSize(12);
                doc.text(titulo, 300, 40,'center');
                let rows = [];
                let columnHeader = ['Grupo Contable','Actividad/Concepto','Fecha valor','Detalle','Importe'];
                var temp=[];
                me.informe.arrayGrupos.forEach(elementG => {                    
                    temp=[elementG.grupocontable,
                        '','','',me.$formatPrice(elementG.total)];
                    rows.push(temp);
                    temp=[];
                    elementG.arrayConceptos.forEach(elementC => {
                        temp=['',elementC.concepto,'','',me.$formatPrice(elementC.total)];
                        rows.push(temp);
                        temp=[];
                        elementC.arrayApuntes.forEach(elementA => {
                            temp=['','',
                            me.$fechaCorta(elementA.fvalor,2),
                            elementA.detalle,
                            me.$formatPrice(elementA.importe)];
                            rows.push(temp);
                            temp=[];
                        });
                        temp=['','','','',''];
                        rows.push(temp);
                    });           
                });
                temp=['RESULTADO FINAL','','','',me.$formatPrice(me.informe.total)];
                rows.push(temp);
                autoTable(doc,{head:[columnHeader], body:rows, startY: doc.pageCount > 1? doc.autoTableEndPosY():80,styles:{fontSize:7},columnStyles: {4: {halign: 'right'}},didParseCell: function (hookData) {if (hookData.section === 'body') {if (hookData.row.raw[0] !== '') {for (const cell of Object.values(hookData.row.cells)) {cell.styles.fontStyle = 'bold',cell.styles.fontSize=8;}}}}});
                //Preparamos el pie de página              
                const pages = doc.internal.getNumberOfPages();
                const pageWidth = doc.internal.pageSize.width;  //Optional
                const pageHeight = doc.internal.pageSize.height;  //Optional
                doc.setFontSize(9);                     
                for (let j = 1; j < pages + 1 ; j++) {
                        let horizontalPos = pageWidth / 2;  //Can be fixed number
                        let verticalPos = pageHeight - 10;  //Can be fixed number
                        doc.setPage(j);
                        doc.text(`${j} de ${pages}`, horizontalPos, verticalPos, {align: 'center'});
                };
                doc.save('InformeEconomico_' + me.dateFormatted1 + '-' + me.dateFormatted2 +'.pdf');    
            },
            crearPDF(){                
                var me = this;
                var doc = new jsPDF('p', 'pt');
                var titulo= 'Informe Económico desde ' + me.dateFormatted1 + ' hasta ' + me.dateFormatted2;
                //Diseñamos la cabecera
                var img=new Image();
                img.src=me.logo;
                doc.addImage(img, 'JPEG', 60, 20, 40, 50);
                //Definimos el título                
                doc.setFontSize(12);
                doc.text(titulo, 300, 40,'center');
                let rows = [];
                let columnHeader = ['Grupo Contable','Actividad/Concepto','','','Importe'];
                var temp=[];
                me.informe.arrayGrupos.forEach(elementG => {
                    if (elementG.total!=0){
                        temp=[elementG.grupocontable,
                        '','','',me.$formatPrice(elementG.total)];
                        rows.push(temp);
                        temp=[];
                    };
                    elementG.arrayConceptos.forEach(elementC => {
                        if (elementC.total!=0){
                            temp=['',elementC.concepto,'','',me.$formatPrice(elementC.total)];
                            rows.push(temp);
                            temp=[];                        
                            temp=['','','','',''];
                            rows.push(temp);
                        };
                    });         
                });
                temp=['RESULTADO FINAL','','','',me.$formatPrice(me.informe.total)];
                rows.push(temp);
                autoTable(doc,{head:[columnHeader], body:rows, startY: doc.pageCount > 1? doc.autoTableEndPosY():80,styles:{fontSize:7},columnStyles: {4: {halign: 'right'}},didParseCell: function (hookData) {if (hookData.section === 'body') {if (hookData.row.raw[0] !== '') {for (const cell of Object.values(hookData.row.cells)) {cell.styles.fontStyle = 'bold',cell.styles.fontSize=8;}}}}});
                //Preparamos el pie de página              
                const pages = doc.internal.getNumberOfPages();
                const pageWidth = doc.internal.pageSize.width;  //Optional
                const pageHeight = doc.internal.pageSize.height;  //Optional
                doc.setFontSize(9);                     
                for (let j = 1; j < pages + 1 ; j++) {
                        let horizontalPos = pageWidth / 2;  //Can be fixed number
                        let verticalPos = pageHeight - 10;  //Can be fixed number
                        doc.setPage(j);
                        doc.text(`${j} de ${pages}`, horizontalPos, verticalPos, {align: 'center'});
                };
                doc.save('InformeEconomico_' + me.dateFormatted1 + '-' + me.dateFormatted2 +'.pdf');    
            },                       
        }        
    }
</script>