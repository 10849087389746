<template>
  <v-container fluid >
    <v-row align-start>
      <v-col cols="12">
        <v-toolbar dark src="https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg">
          <v-toolbar-title>
            <v-icon large left>mdi-card-account-mail</v-icon>
            Comunicados
          </v-toolbar-title>
          <v-divider class="mx-2" inset vertical></v-divider>                
          <v-spacer></v-spacer>
          <div class="text-center">            
          </div>
          <v-spacer></v-spacer>
        </v-toolbar>
      </v-col>
    </v-row>
    <v-row align-start>
      <v-col cols="6">
        <v-text-field v-model="asuntoAct" label="Asunto" maxLength="60" clearable autofocus class="shrink" style="width:600px">                                        
        </v-text-field>
      </v-col>
      <v-spacer></v-spacer>
      <v-btn v-if="radioGroup==1||radioGroup==2" color="accent" dark class="mb-2" @click.native="mostrarDestinatarios">Ver destinatari@s</v-btn>
      <v-spacer></v-spacer>
      <v-btn v-if="editorContent.length>0" color="accent" dark class="mb-2" @click.native="guardarTexto">Guardar Texto</v-btn>      
      <v-spacer></v-spacer>
      <v-btn color="accent" dark class="mb-2" @click.native="cargarTexto">Cargar Texto</v-btn>      
      <v-spacer></v-spacer>      
      <v-btn v-bind:color="label=='Ocupado' ? 'red' : 'green'" @click.native="infoDialog=1">
          {{ label }}
        </v-btn>            
    </v-row>
    <v-row>
      <v-col cols="12">
        <VueTrix v-model="editorContent" placeholder="Escribe tu mensaje..." @trix-attachment-add="anadeFichero" @trix-attachment-remove="eliminaFichero"/>     
      </v-col>
    </v-row>
    <v-checkbox
              v-model="modoEnvio"              
              color="orange"              
              hide-details
              disabled
    >
      <template v-slot:label>Envío directo por urgozokomunikatuak@gmail.com</template>
    </v-checkbox>    
    <v-radio-group v-model="radioGroup">
      <v-row class="ml-5">
        <v-col cols="9">
          <v-radio label='Inscrit@s en esta actividad...' :value=1 ></v-radio>
        </v-col>
        <v-col cols="3">          
          <v-select v-model="idconceptoAct" :items="conceptos" label="Actividad" dense v-if="radioGroup==1"></v-select>          
        </v-col>        
      </v-row>      
      <v-row class="ml-5">
        <v-col cols="8">
          <v-radio label="Comunicado General" :value=2 ></v-radio>
        </v-col>          
      </v-row>
      <v-row class="ml-5">
        <v-col cols="7">
          <v-radio label="Solamente al siguiente destinatario:" :value=3 ></v-radio>
        </v-col>
        <v-col cols="5">
          <v-text-field class="shrink" style="width:350px" v-model="emailAct" label="Email" v-if="radioGroup==3" maxLength="35" clearable></v-text-field>
        </v-col>  
      </v-row>
    </v-radio-group>            
    <br><br>
    <v-row align-content="center">
      <v-spacer></v-spacer>
      <v-btn color="accent" dark class="mb-2" text @click.native="borrarDatos" >Borrar datos</v-btn>                                                   
      <v-spacer></v-spacer>
      <v-btn color="accent" dark class="mb-2" @click.native="enviarMensaje" :disabled="!asuntoAct||!editorContent||diferidos>0">Enviar mensaje</v-btn>
      <v-spacer></v-spacer>
    </v-row>
    <v-dialog
      v-model="infoDialog"
      width="1000"
      persistent>
      <v-card>
        <v-card-title class="text-h5 blue" >
          Tareas Pendientes
        </v-card-title>
        <v-card-text class="text-h6 font-weight-bold">
          <br>
          {{ informacion }}
          <br>
          <v-simple-table v-if="label=='Libre'">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Cuando
                </th>
                <th class="text-left">
                  Destinatarios
                </th>
                <th class="text-left">
                  Asunto
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in historicos"
                :key="item.cuando"
              >
                <td>{{ item.cuando | formatDate}}</td>
                <td>{{ item.cuantos }}</td>
                <td>{{ item.asunto }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="accent" text dark class="mb-2" @click.native="adModalCancelar=1" v-if="label=='Ocupado'">Cancelar envío</v-btn>
          <v-btn color="accent" @click="infoDialog = false">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="adModalProcesando" max-width="300" persistent>
            <Procesando/> 
        </v-dialog>      
    <v-dialog v-model="adModalCancelar" max-width="520" persistent>
      <ModalTipo1                
          titulo="¿Cancelar envio?"
          mensaje="Con este proceso se anulará el proceso de envío de mensajes actual. <br><br> ¿Deseas continuar?"
          v-bind:aceptarCancelar="true"
          v-on:cancelarClick="adModalCancelar=0"
          v-on:aceptarClick="cancelarMensaje"    
      />
    </v-dialog>
    <v-dialog v-model="adModalEnviado" max-width="500" persistent>
      <ModalTipo1
        titulo="Mensaje enviado"
        :mensaje="'El mensaje con el asunto <strong>' + asuntoAct + '</strong> ha sido enviado correctamente'"                        
        v-on:cerrarModal="adModalEnviado=0"          
      />  
    </v-dialog>  
    <v-dialog v-model="adModalCancelado" max-width="500" persistent>      
      <ModalTipo1
        titulo="Mensaje cancelado"
        :mensaje="'El mensaje con el asunto <strong>' + asuntoAct + '</strong> ha sido cancelado por el usuario, pulsa en Libre para obtener más detalles'"                        
        v-on:cerrarModal="adModalCancelado=0"          
      />
    </v-dialog>
    <v-dialog v-model="adModalDestinatarios" max-width="1000" persistent>
      <VerDestinatarios
        v-bind:socios=socios
        v-on:obtenerEmails="obtenerEmails"
        v-on:cerrarModal="adModalDestinatarios=0"                  
      />
    </v-dialog>
    <v-dialog v-model="adModalCopiados" max-width="500" persistent>      
      <ModalTipo1
          titulo="Copiados correctamente"
          mensaje="Utiliza las teclas ctrl.+ V para pegar las direcciones en la casilla CCO de tu aplicación de correo"                        
          v-on:cerrarModal="adModalCopiados=0"          
      />
    </v-dialog> 
    <v-dialog
      v-model="dialogEnviando"
      hide-overlay
      persistent
      width="300"
      >
      <v-card
          color="primary"
          dark
      >
          <v-card-text style="font-size:1em">
          Paciencia, puede tardar un minuto.
          <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
          ></v-progress-linear>
          </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
  import VueTrix from "vue-trix"
  import VerDestinatarios from '../Libreria/Modales/VerDestinatarios.vue'  
  export default {
    components: {
        VueTrix,
        VerDestinatarios
        },    
    destroyed(){
      clearInterval(this.interval)
    },
    data() {
      return {
        adModalEnviado:0,
        adModalCancelado:0,
        adModalDestinatarios:0,
        adModalCancelar:0,
        adModalCopiados:0,
        adModalProcesando:0,
        dialogEnviando: false,
        infoDialog: false,
        radioGroup: 1,
        conceptos:[],
        socios:[],
        idconceptoAct:'',
        editorContent: '',
        emailAct:'',
        asuntoAct:'',
        adjuntosAct:[],        
        mensajeFormData:new FormData(),        
        logo: require('@/assets/Armarria2.png'),
        interval: undefined,
        my_url: undefined,        
        diferidos:'',
        totales:'',
        fechaHoraFin:'',
        asunto:'',
        historicos:[],
        label:'Libre',
        informacion:'',
        modoEnvio: true
      }
    },
    created () {
      let me=this;
      me.listarConceptos();
      me.canCopy = !!navigator.clipboard;
      me.consultarEstado();      
      me.activarIntervalo();
    },
    methods:{            
      listarConceptos(){
        let me=this;
        var conceptosArray=[]; 
        me.$http.get('api/Conceptos/ListarParaCorreo').then(function(response){
            conceptosArray=response.data;
            conceptosArray.map(function(x){
            me.conceptos.push({text: x.descripcion,value:x.idconceptos});
            });
          me.idconceptoAct=conceptosArray[0].idconceptos;
        }).catch(function(error){
            console.log(error);
        });
      },      
      enviarMensaje(){
        clearInterval(this.interval)
        let me=this;
        me.guardarTexto()
        me.diferidos=1;
        me.label='Ocupado';
        me.dialogEnviando= true;
        me.mensajeFormData=new FormData();
        me.mensajeFormData.append('body', me.editorContent);
        me.mensajeFormData.append('asunto', me.asuntoAct);
        me.mensajeFormData.append('modoEnvio',me.modoEnvio ? '1' : '0');
        var ins = me.adjuntosAct.length;
        for (var x = 0; x < ins; x++) {
            me.mensajeFormData.append("files", me.adjuntosAct[x]);
        }
        switch(me.radioGroup) {
          case 1:
            me.mensajeFormData.append('destino', me.idconceptoAct);                            
            break;
          case 2:
            me.mensajeFormData.append('destino', 0);                        
            break;
          case 3:
            me.mensajeFormData.append('email', me.emailAct);            
            break;
        }
        me.$http.post('api/Email/enviarMensajes',me.mensajeFormData,{headers: {"Content-Type": "multipart/form-data",},})
          .then(function(response){
            me.dialogEnviando=false;            
            me.adModalEnviado=1;
            me.consultarEstado();
            me.activarIntervalo();                
          }).catch(function(error){
              console.log(error);
          });
      },      
      anadeFichero(event) {
        let me=this;
        let file = event.attachment.file;
        me.adjuntosAct.push(file);        
      },
      eliminaFichero(event) {
        let me=this;
        let file = event.attachment.file;                 
        var indice=me.adjuntosAct.findIndex(element => element === file);
        me.adjuntosAct.splice(indice,1);     
      },
      borrarDatos() {
        let me=this;
        me.adjuntosAct.length=0;
        me.editorContent='';
        me.asuntoAct='';
        me.emailAct='';        
        me.radioGroup=1;
        me.modoEnvio=false;
        me.socios=[];     
      },
      cancelarMensaje(){
        clearInterval(this.interval)
        let me=this;
        me.label='Ocupado';
        me.infoDialog= false,
        me.adModalCancelar=0;
        me.diferidos=0;
        me.$http.put('api/Email/cancelarMensaje')
          .then(function(response){
            me.label='Libre';
            me.adModalCancelado=1;
            me.consultarEstado();
            me.activarIntervalo();              
          }).catch(function(error){
              console.log(error);
          });  
      },
      consultarEstado(){
        let me=this;
        me.$http.get('api/Email/consultarEstado')
            .then(function(response){ 
              me.diferidos=response.data.diferidos;
              me.totales=response.data.totales;
              me.fechaHoraFin=new Date(response.data.fechaHoraFin);
              me.asunto=response.data.asunto;
              me.historicos=response.data.historicos;
              me.label=me.diferidos>0 ? 'Ocupado' : 'Libre';
              if (me.diferidos>0) 
              {
                var mensajeOcupado= 'El sistema se encuentra ocupado. Faltan por enviar '+me.diferidos +' mensajes de un total de ' +me.totales +' con el asunto ' +me.asunto +'. Está previsto que los trabajos finalicen: ' + me.fechaHoraFin.toLocaleDateString() + ' - ' + me.fechaHoraFin.getHours()+':'+me.fechaHoraFin.getMinutes();
              }
              var mensajeLibre= 'No hay tareas pendientes. Estos son los últimos envíos realizados:'
              me.informacion=me.label=='Ocupado' ? mensajeOcupado : mensajeLibre;            
            }).catch(function(error){
                console.log(error);
            });
      },
      activarIntervalo(){
        let me=this;
        me.interval = setInterval(() => {me.consultarEstado()}, 30000 );
      },
      mostrarDestinatarios(){
        let me=this;
        me.adModalProcesando=1;
        me.cargarSocios();
      },
      cargarSocios(){
        let me=this;
        if (me.radioGroup==1)
          {
          me.$http.get('api/Email/EmailsActividad/'+me.idconceptoAct).then(function(response){
                me.socios=response.data;
                me.adModalProcesando=0;
                me.adModalDestinatarios=1;
                }).catch(function(error){
                    console.log(error);
                });
          }
        else {
          me.$http.get('api/Email/EmailsTodos').then(function(response){
                me.socios=response.data;
                me.adModalProcesando=0;
                me.adModalDestinatarios=1;
                }).catch(function(error){
                    console.log(error);
                });
        }
      },
      obtenerEmails(opcion){
        let me=this;
        me.adModalProcesando=1;
        var emails=[],totEmails=0;
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;        
        me.socios.forEach(element => {
          if (element.email1 && re.test(element.email1)) emails.push(element.email1)
          if (element.email2 && re.test(element.email2)) emails.push(element.email2)  
        });
        var conteArchivo = '';
        emails.forEach(element => {
          conteArchivo += element + ";";
        });
        if (opcion==1){
          var element = document.createElement('a');
          element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(conteArchivo));
          element.setAttribute('download', "emails");
          element.style.display = 'none';
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
        }
        else {
          navigator.clipboard.writeText(conteArchivo);
          me.adModalCopiados=1;
        }
        me.adModalProcesando=0;       
      },
      guardarTexto() {
          let me=this;
          me.adModalProcesando=1;
          me.mensajeFormData=new FormData();
          me.mensajeFormData.append('body', me.editorContent);
          me.$http.post('api/Email/GuardarTexto',me.mensajeFormData,{headers: {"Content-Type": "multipart/form-data",},})
          .then(function(response){                      
                me.adModalProcesando=0;                          
            }).catch(function(error){
                console.log(error);
          });
      },
      cargarTexto() {
          let me=this;
          me.adModalProcesando=1;
          me.$http.get('api/Email/CargarTexto').then(function(response){
            me.editorContent=response.data;
            me.adModalProcesando=0;
          }).catch(function(error){
              console.log(error);
          });          
      }            
    }
  }
</script>
<style>
	trix-editor {
		height: 200px !important;
		max-height: 200px !important;
	  	overflow-y: auto !important;
	}
</style>