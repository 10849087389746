<template>  
  <v-app id="app">    
    <ModalIdle v-if="isAppIdle&&logueado"/> <!-- Esta línea hace saltar la expiración de la sesion --> 
    <v-navigation-drawer      
      app
      clipped
      expand-on-hover
      mini-variant
      mini-variant-width=70
      overlay-color=black
      src="https://cdn.vuetifyjs.com/images/backgrounds/bg-2.jpg"
      width="19%"      
      absolute
      v-model="drawer"
      v-if="logueado"
      dark
      @transitionend="collapseSubItems"    
    >
      <v-list dense>
        <template v-if="esAdministrador || esPresidente || esSecretario || esTesorero || esVocal || esSocio">
          <v-list-item :to="{name:'home'}">
            <v-list-item-action color="accent">
              <fa-icon icon="home" style="font-size: 24px; fa-10x; color:'accent'"/>
            </v-list-item-action>
            <v-list-item-title color="accent">
              Inicio
            </v-list-item-title>
          </v-list-item>
        </template>
        <template v-if="esAdministrador || esPresidente || esSecretario || esTesorero || esVocal">
	        <v-list-group v-model="groupOpened1">		
            <v-list-item slot="activator"  color="primary" active:false>
              <v-list-item-action >
                <fa-icon icon="user" style="font-size: 24px; fa-10x;"/>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  SOCIOS
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ name: 'socios'}" color="accent">
              <v-list-item-action >
                <fa-icon icon="address-card" style="font-size: 24px; fa-10x;"/>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Fichas	
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ name: 'recuperarsocio'}" color="accent" v-if="esAdministrador">
              <v-list-item-action >
                <fa-icon icon="address-card" style="font-size: 24px; fa-10x;"/>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Recuperar Socio	
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ name: 'listados_identidad'}" color="accent">
              <v-list-item-action >
                <fa-icon icon="file-alt" style="font-size: 24px; fa-10x;"/>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Listados	
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
	        </v-list-group>
        </template>
        <template v-if="esAdministrador || esPresidente || esSecretario || esTesorero || esVocal">
	        <v-list-group v-model="groupOpened2">		
            <v-list-item slot="activator" color="primary">
              <v-list-item-action >
                <fa-icon icon="bicycle" style="font-size: 24px; fa-10x;"/>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  ACTIVIDADES
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ name: 'inscripciones'}" color="accent">
              <v-list-item-action>
                 <fa-icon icon="file-signature" style="font-size: 24px; fa-10x;"/>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Inscripciones	
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ name: 'conceptos'}" color="accent">
              <v-list-item-action>
                 <fa-icon icon="biking" style="font-size: 24px; fa-10x;"/>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Conceptos	
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ name: 'list_conceptossimples'}" color="accent">
              <v-list-item-action>
                <fa-icon icon="file-alt" style="font-size: 24px; fa-10x;"/>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Listado de Inscripciones
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
	        </v-list-group>
        </template>
        <template v-if="esAdministrador || esPresidente || esSecretario || esTesorero || esVocal">
	        <v-list-group v-model="groupOpened3">		
            <v-list-item slot="activator" color="primary">
              <v-list-item-action >
                <fa-icon icon="address-card" style="font-size: 24px; fa-10x;"/>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  LICENCIAS
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ name: 'solicitudes'}" color="accent">
              <v-list-item-action>
                <fa-icon icon="file-signature" style="font-size: 24px; fa-10x;"/>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Solicitudes	
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ name: 'conceptoslicencias'}" color="accent">
              <v-list-item-action>
                 <fa-icon icon="calendar-alt" style="font-size: 24px; fa-10x;"/>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Temporadas	
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ name: 'list_licencias'}" color="accent">
              <v-list-item-action>
                <fa-icon icon="file-alt" style="font-size: 24px; fa-10x;"/>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Listado de Solicitudes
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
	        </v-list-group>
        </template>
        <template v-if="esAdministrador || esPresidente || esSecretario || esTesorero || esVocal">
	        <v-list-group v-model="groupOpened4">		
            <v-list-item slot="activator" color="primary">
              <v-list-item-action >
                <fa-icon icon="store" style="font-size: 24px; fa-10x;"/>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  TIENDA
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ name: 'pedidos'}" color="accent">
              <v-list-item-action>
               <fa-icon icon="file-signature" style="font-size: 24px; fa-10x;"/>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Pedidos
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ name: 'conceptostienda'}" color="accent">
              <v-list-item-action>
                 <fa-icon icon="suitcase-rolling" style="font-size: 24px; fa-10x;"/>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Campañas	
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ name: 'articulos'}" color="accent">
              <v-list-item-action>
                 <fa-icon icon="tshirt" style="font-size: 24px; fa-10x;"/>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Artículos	
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>            
            <v-list-item :to="{ name: 'list_pedidos'}" color="accent">
              <v-list-item-action>
               <fa-icon icon="file-alt" style="font-size: 24px; fa-10x;"/>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Listado de Pedidos
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
	        </v-list-group>
        </template>
        <template v-if="esAdministrador || esPresidente || esSecretario || esTesorero || esVocal">
	        <v-list-group v-model="groupOpened5">		
            <v-list-item slot="activator" color="primary">
              <v-list-item-action >
                <fa-icon icon="coins" style="font-size: 24px; fa-10x;"/>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  INGRESOS Y GASTOS
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ name: 'cuotaanual'}" color="accent">
              <v-list-item-action>
              <fa-icon icon="calendar" style="font-size: 24px; fa-10x;"/>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Cuota Anual
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ name: 'facturas'}" color="accent">
              <v-list-item-action>
               <fa-icon icon="file-invoice" style="font-size: 24px; fa-10x;"/>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Facturas	
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ name: 'list_facturas'}" color="accent">
              <v-list-item-action>
               <fa-icon icon="file-alt" style="font-size: 24px; fa-10x;"/>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Listado de Facturas	
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>  
            <v-list-item :to="{ name: 'recibos'}" color="accent">
              <v-list-item-action>
               <fa-icon icon="receipt" style="font-size: 24px; fa-10x;"/>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Recibos	
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
 	          <v-list-item :to="{ name: 'remesas'}" color="accent">
              <v-list-item-action>
               <fa-icon icon="layer-group" style="font-size: 24px; fa-10x;"/>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Remesas	Bancarias
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ name: 'list_recibosremesados'}" color="accent">
              <v-list-item-action>
              <fa-icon icon="file-alt" style="font-size: 24px; fa-10x;"/>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Listado Recibos Remesados
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>                      
	        </v-list-group>
        </template>
        <template v-if="esAdministrador || esPresidente || esSecretario || esTesorero || esVocal">
	        <v-list-group v-model="groupOpened6">		
            <v-list-item slot="activator" color="primary">
              <v-list-item-action >
                <fa-icon icon="calculator" style="font-size: 24px; fa-10x;"/>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  CONTABILIDAD
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ name: 'apunte'}" color="accent">
              <v-list-item-action>
                <fa-icon icon="edit" style="font-size: 24px; fa-10x;"/>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Apuntes Contables	
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item> 	          
            <v-list-item :to="{ name: 'list_apuntes'}" color="accent">
              <v-list-item-action>
                <fa-icon icon="file-alt" style="font-size: 24px; fa-10x;"/>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Listado de Apuntes
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ name: 'informeeconomico'}" color="accent">
              <v-list-item-action>
                <fa-icon icon="scroll" style="font-size: 24px; fa-10x;"/>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Informe Económico
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
	        </v-list-group>
        </template>
        <template v-if="esAdministrador || esPresidente || esSecretario || esTesorero || esVocal">
	        <v-list-group v-model="groupOpened7">		
            <v-list-item slot="activator" color="primary">
              <v-list-item-action >
                <fa-icon icon="envelope" style="font-size: 24px; fa-10x;"/>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  COMUNICACIÓN
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>             	          
            <v-list-item :to="{ name: 'enviarcorreo'}" color="accent">
              <v-list-item-action >
                <fa-icon icon="paper-plane" style="font-size: 24px; fa-10x;"/>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Enviar Correo	
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>           
	        </v-list-group>
        </template>
        <template v-if="esAdministrador || esPresidente || esSecretario || esTesorero">
	        <v-list-group v-model="groupOpened8">		
            <v-list-item slot="activator" color="primary">
              <v-list-item-action >
                <fa-icon icon="vector-square" style="font-size: 24px; fa-10x;"/>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  TABLAS AUXILIARES
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ name: 'banco'}" color="accent">
              <v-list-item-action>
                <fa-icon icon="wrench" style="font-size: 24px; fa-10x;"/>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Bancos
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ name: 'estadopago'}" color="accent">
              <v-list-item-action>
                <fa-icon icon="wrench" style="font-size: 24px; fa-10x;"/>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Estados de los Pagos
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ name: 'formapago'}" color="accent">
              <v-list-item-action>
                <fa-icon icon="wrench" style="font-size: 24px; fa-10x;"/>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Formas de Pago
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ name: 'gruposcontables'}" color="accent">
              <v-list-item-action>
                <fa-icon icon="wrench" style="font-size: 24px; fa-10x;"/>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Grupos Contables
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ name: 'municipio'}" color="accent">
              <v-list-item-action>
                <fa-icon icon="wrench" style="font-size: 24px; fa-10x;"/>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Municipios
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ name: 'sistematalla'}" color="accent">
              <v-list-item-action>
                <fa-icon icon="wrench" style="font-size: 24px; fa-10x;"/>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Sistema de Tallas
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ name: 'talla'}" color="accent">
              <v-list-item-action>
                <fa-icon icon="wrench" style="font-size: 24px; fa-10x;"/>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Tallas
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ name: 'tipoapunte'}" color="accent">
              <v-list-item-action>
                <fa-icon icon="wrench" style="font-size: 24px; fa-10x;"/>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Tipos de Apuntes Contables
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ name: 'tipoconcepto'}" color="accent">
              <v-list-item-action>
                <fa-icon icon="wrench" style="font-size: 24px; fa-10x;"/>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Tipos de Conceptos
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ name: 'tipodocumento'}" color="accent">
              <v-list-item-action>
                <fa-icon icon="wrench" style="font-size: 24px; fa-10x;"/>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Tipos de Documento
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ name: 'tipolicencia'}" color="accent">
              <v-list-item-action>
                <fa-icon icon="wrench" style="font-size: 24px; fa-10x;"/>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Tipos de Licencia
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
	        </v-list-group>
        </template>
        <template v-if="esAdministrador || esPresidente || esSecretario">
          <v-list-group v-model="groupOpened9">
            <v-list-item slot="activator" color="primary">
              <v-list-item-action >
                <fa-icon icon="cogs" style="font-size: 24px; fa-10x;"/>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  SISTEMA
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ name: 'roles'}" color="accent">
              <v-list-item-action>
                <fa-icon icon="user-graduate" style="font-size: 24px; fa-10x;"/>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Roles
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ name: 'usuarios'}" color="accent">
              <v-list-item-action>
                <fa-icon icon="user" style="font-size: 24px; fa-10x;"/>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Usuarios
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ name: 'sistema'}" color="accent" v-if="esAdministrador">
              <v-list-item-action>
                <fa-icon icon="wrench" style="font-size: 24px; fa-10x;"/>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Sistema
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </template>        
      </v-list>
    </v-navigation-drawer>
    <v-dialog v-model="userDialog" max-width="600px" persistent>                                                
      <v-card>
          <v-card-title>
          <span class="headline">Ficha de Usuario</span>
          </v-card-title>                
          <v-card-text>
          <v-container grid-list-md v-if="logueado">
            <v-row wrap>
            <v-col cols="4">
                <v-text-field :value="$store.state.usuario.nombre" label="Nombre" readonly>
                </v-text-field>
            </v-col>
            <v-col cols="4">
                <v-text-field :value="$store.state.usuario.rol" label="Rol" readonly>
                </v-text-field>                                    
            </v-col>
            <v-col cols="3">
                <v-text-field value='Castellano' label="Idioma" readonly>
                </v-text-field>
            </v-col>
            <v-col cols="3">
                <v-text-field value='Estandar' label="Aspecto" readonly>
                </v-text-field>
            </v-col>                                
            <v-col cols="9">
                <v-text-field v-model="emailAct" label="Email" maxLength="35" clearable>
                </v-text-field>
            </v-col>
            <v-col cols="4">
                <v-text-field v-model="passwordAct" type="password" label="Nueva Contraseña">
                </v-text-field>
            </v-col>
            <v-col xs12 sm12 md12 v-show="valida">
                <div class="red--text" v-for="v in validaMensaje" :key="v" v-text="v">
                </div>
            </v-col>           
            </v-row>
          </v-container>
          </v-card-text>                
          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="accent" text @click.native="cerrarForm">Cancelar</v-btn>
              <v-btn color="accent" @click.native="guardarUsuario">Guardar</v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
    
    <v-app-bar 
      :color="$colorBarra" 
      dark
      app
      :clipped-left="$vuetify.breakpoint.mdAndUp"
      fixed
    >
      <v-toolbar-title v-if="logueado" style="width: 300px" class="ml-0 pl-3">
       <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <span class="hidden-sm-and-down">MENÚ</span>
      </v-toolbar-title>
      <v-divider        
        vertical
      ></v-divider>
      <v-toolbar-title style="width: 300px" class="ml-0 pl-3">
        {{$store.state.ent_nombre}}
      </v-toolbar-title>
      <v-divider
        vertical
      ></v-divider>
      <v-toolbar-title v-if="logueado" style="width: 300px" >
          {{this.fechaactual}}
      </v-toolbar-title>
      <v-divider
        vertical
      ></v-divider>
      <v-toolbar-title v-if="logueado" style="width: 400px">        
          <v-btn @click="mostrarFichaUsuario">
            {{$store.state.usuario.nombre}} ({{$store.state.usuario.rol}})       
          </v-btn>
      </v-toolbar-title>
      <v-divider
        vertical
      ></v-divider>
      <v-toolbar-title v-if="logueado" style="width: 300px">        
          <a href="https://mail5018.site4now.net/interface/root#/login" target="_blank" rel="noreferrer">
            <fa-icon :icon="['fas', 'envelope']" size="2x" color="white"/>
          </a>        
      </v-toolbar-title>
      <v-divider
        vertical
      ></v-divider> 
      <v-toolbar-title  style="width: 300px">      
        <v-btn @click="salir" v-if="logueado" icon>
          <fa-icon :icon="['fas', 'sign-out-alt']" size="2x"/>Salir        
        </v-btn>
        <v-btn :to="{name: 'login'}" v-else>
          <fa-icon :icon="['fas', 'sign-in-alt']" size="2x" style="width: 60px"/>   Login
        </v-btn>
      </v-toolbar-title>
      <v-divider
        vertical
      ></v-divider> 
    </v-app-bar>
    <v-main>
      <v-container fluid fill-height align-start>
        <v-slide-y-transition mode="out-in">
          <router-view/>
        </v-slide-y-transition>
      </v-container>
    </v-main>
    <v-footer dark height="auto">
      <v-row justify-center>
        <v-col text-xs-center>
          <v-card flat tile color="primary" class="white--text">
            <v-card-text class="white--text pt-0 text-center">
              {{tipoSuscripcion}}             
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>
<script>
import ModalIdle from '@/components/ModalIdle'
export default {
  name: 'App',
  data () {
    return {
      clipped: false,
      drawer: true,
      fixed: false,
      items: [{
        icon: 'bubble_chart',
        title: 'Inspire'
      }],
      miniVariant: false,
      right: true,
      rightDrawer: false,      
      title: 'Vuetify.js',
      userDialog: false,
      idrolAct:'',
      rolAct:'',
      nombreAct:'',                         
      emailAct: '',
      ididiomasAct:'',
      idiomaAct:'',
      idaspectosAct:'',
      aspectoAct:'',
      passwordAct:'',
      actPassword:false,
      passwordAnt:'',
      usuario:{},
      valida: 0,
      validaMensaje:[],
      finsesion:0,      
      groupOpened1:false,
      groupOpened2:false,
      groupOpened3:false,
      groupOpened4:false,
      groupOpened5:false,      
      groupOpened6:false,
      groupOpened7:false,
      groupOpened8:false,
      groupOpened9:false
    }     
  },
  components: {
		ModalIdle
	},
  computed: {    
    logueado(){
      return this.$store.state.usuario;      
    },
    esAdministrador(){
      return this.$store.state.usuario && this.$store.state.usuario.rol =='Administrador';     
    },
    esPresidente(){
      return this.$store.state.usuario && this.$store.state.usuario.rol =='Presidente';
    },
    esSecretario(){
      return this.$store.state.usuario && this.$store.state.usuario.rol =='Secretario';
    },
    esTesorero(){
      return this.$store.state.usuario && this.$store.state.usuario.rol =='Tesorero';
    },
    esVocal(){
      return this.$store.state.usuario && this.$store.state.usuario.rol =='Vocal';
    },
    esSocio(){
      return this.$store.state.usuario && this.$store.state.usuario.rol =='Socio';
    },
    fechaactual(){
      var ahora=new Date();
      var fecha=ahora.getDate()+'/'+(ahora.getMonth()+1)+'/'+ahora.getFullYear();      
      return fecha;
    },
    tipoSuscripcion() {
        let me=this;
        var tipo=null;
        switch (me.$store.state.ent_idtiposuscripcion){
          case 1:
              tipo="Igor AU 2023 Versión Gratuita"
              break;
          case 2:
              tipo="Igor AU 2023 Versión Estandar"
              break;
          case 3:
              tipo="Igor AU 2023 Versión Avanzada"
              break;
          case 4:
              tipo="Igor AU 2023 Versión Personalizada"
              break;
        }
        return tipo
    },
  },
  created(){
    let me=this;    
    me.$store.dispatch("autoLogin");   
  },
  methods:{
    collapseSubItems(){
      let me=this;      
      me.groupOpened1=false;
      me.groupOpened2=false;
      me.groupOpened3=false;
      me.groupOpened4=false;
      me.groupOpened5=false;
      me.groupOpened6=false;
      me.groupOpened7=false;
      me.groupOpened8=false;
      me.groupOpened9=false;
      },
    salir(){
      let me=this;            
      me.$store.dispatch("salir");
    },
    mostrarFichaUsuario(){
      let me=this;      
      me.emailAct=me.$store.state.usuario.email;
      me.passwordAct='';      
      me.actPassword=false;
      me.userDialog = true    
    },
    cerrarForm(){
      let me=this;            
      me.userDialog = false;
    },
    guardarUsuario(){
      let me=this;
      if (this.validarFormulario()){
            return;
        }
      me.cerrarForm();      
      if (me.passwordAct!='') me.actPassword=true;      
      me.$http.put('api/Usuarios/Actualizar',{
          'idusuarios':me.$store.state.usuario.idusuarios,
          'nombre': me.$store.state.usuario.nombre,                               
          'email':me.emailAct,
          'password':me.passwordAct,
          'act_password':me.actPassword                        
      }).then(function(response){                 
      }).catch(function(error){
          console.log(error);
      });
    },
    validarFormulario(){
      let me=this;
      me.valida=0;
      me.validaMensaje=[];
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!me.emailAct){
          me.validaMensaje.push("No has escrito el email");
      } else if(!re.test(me.emailAct)){
          me.validaMensaje.push("El email no es correcto");
      }
      if (me.passwordAct !=''){
        if (!me.clavesegura(me.passwordAct))
          me.validaMensaje.push("La contraseña debe tener longitud mínima de 8, una mayúscula, una minúscula, un número y un carácter especial (-._&%/\!..)");
      }
      if (me.validaMensaje.length){
          me.valida=1;
      }
      return me.valida;
    },
    clavesegura(clave){            
      if(clave.length >= 8)
      {		
        var mayuscula = false;
        var minuscula = false;
        var numero = false;
        var caracter_raro = false;            
        for(var i = 0;i<clave.length;i++)
        {
          if(clave.charCodeAt(i) >= 65 && clave.charCodeAt(i) <= 90)
          {
            mayuscula = true;
          }
          else if(clave.charCodeAt(i) >= 97 && clave.charCodeAt(i) <= 122)
          {
            minuscula = true;
          }
          else if(clave.charCodeAt(i) >= 48 && clave.charCodeAt(i) <= 57)
          {
            numero = true;
          }
          else
          {
            caracter_raro = true;
          }
        }
        if(mayuscula == true && minuscula == true && caracter_raro == true && numero == true)
        {
          return true;
        }
      }
      return false;
    },    
  }           
}
</script>
<style>
  @import './assets/styles/myStyles.css';
</style>