export const hasChanged = (object1,object2) => {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
        return true;
    }
    for (const key of keys1) {
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = isObject(val1) && isObject(val2);
        if (areObjects && !deepEqual(val1, val2) || !areObjects && val1 !== val2) {
        return true;
        }
    }
    return false;
}
export const isObject = (object) => {
    return object != null && typeof object === 'object';
}
export const deepEqual =(x,y) =>{
    if (x === y) {
        return true;
      }
      else if ((typeof x == "object" && x != null) && (typeof y == "object" && y != null)) {        
        if (Object.keys(x).length != Object.keys(y).length)
          return false;
        if (x instanceof Date && y instanceof Date && x!=y) return false; /* Linea añadida para las fechas (porque son objetos) */
        for (var prop in x) {            
          if (y.hasOwnProperty(prop))
          {  
            if (!deepEqual(x[prop], y[prop])) return false;
          }
          else
            return false;
        }        
        return true;
      }
      else 
        return false;
}
export const fechaCorta = (fecha,orden) =>{
    if (!fecha) return null;        
    var datePart = fecha.toString().match(/\d+/g), 
    year = datePart[0], 
    month = datePart[1],
    day = datePart[2];
    if (orden==1){
         var mydate=year+'-'+month+'-'+day;
    }
    else {
        var mydate=day+'-'+month+'-'+year;    
    }                                
    return mydate; 
}
export const fechaLarga = (fecha) =>{
    if (!fecha) return null;                   
    var day=fecha.toString().substring(8,10),
    month=fecha.toString().substring(5,7),
    year=fecha.toString().substring(0,4),                                
    mydate=year+'-'+month+'-'+day,
    objetofecha=new Date(mydate);                
    return objetofecha; 
}
export const calcularTotal = (lineas) =>{
    if (!lineas) return 0;    
    var resultado=0.0;
    for(var i=0;i<lineas.length;i++){
        resultado=resultado+(lineas[i].preciov*lineas[i].cantidad);
    }
    return resultado;
}
export const getnumIBAN = (letra) =>{
    const ls_letras = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    return ls_letras.search(letra) + 10;
}
export const formatPrice = (value) =>{
    let val = (value/1).toFixed(2).replace('.', ',')
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
}
export const validateIBAN = (IBAN) =>{        
    if (!IBAN) return false;
    //Se pasa a Mayusculas
    IBAN = IBAN.toUpperCase();
    //Se quita los blancos de principio y final.
    IBAN = IBAN.trim();
    IBAN = IBAN.replace(/\s/g, ""); //Y se quita los espacios en blanco dentro de la cadena
    var letra1,letra2,num1,num2;
    var isbanaux;
    var numeroSustitucion;
    //La longitud debe ser siempre de 24 caracteres
    if (IBAN.length != 24) {
        return false;
    }
    // Se coge las primeras dos letras y se pasan a números
    letra1 = IBAN.substring(0, 1);
    letra2 = IBAN.substring(1, 2);
    num1 = getnumIBAN(letra1);
    num2 = getnumIBAN(letra2);
    //Se sustituye las letras por números.
    isbanaux = String(num1) + String(num2) + IBAN.substring(2);
    // Se mueve los 6 primeros caracteres al final de la cadena.
    isbanaux = isbanaux.substring(6) + isbanaux.substring(0,6);
    //Se calcula el resto, llamando a la función modulo97, definida más abajo
    const resto = modulo97(isbanaux);
    if (resto == 1){
        return true;
    }else{
        return false;
    }
}
export const modulo97 = (IBAN) =>{
    var parts = Math.ceil(IBAN.length/7);
    var remainer = "";
    for (var i = 1; i <= parts; i++) {
        remainer = String(parseFloat(remainer+IBAN.substr((i-1)*7, 7))%97);
    }
    return remainer;
}
export const calcularEdad = (fecha) => {
    if (fecha==null) return null;
    var hoy = new Date();
    var cumpleanos = new Date(fecha);
    var edad = hoy.getFullYear() - cumpleanos.getFullYear();
    var m = hoy.getMonth() - cumpleanos.getMonth();
    if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
        edad--;
    }
    return edad;
}