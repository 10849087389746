<template>
    <v-container fluid>
    <v-row align-start>
        <v-col>
            <Cabecera
                v-bind:objeto=ADobjetos
                :ocultaNuevo='true'
                :ocultaBusqueda='true'                         
            />
        </v-col>
    </v-row>
    <TablaDatos
        v-bind:opcionesTabla=opcionesTabla                    
        v-bind:items=roles
        v-bind:headers=headers                    
    />
    </v-container>
</template>
<script>
    export default {
        data(){
            return {
                roles:[],
                ADobjetos:'Roles',
                opcionesTabla:{Seleccion:false, AbrirCerrar:false, ActivarBloquear:false, Visualizar:false, Modificar:false, Eliminar:false, Historico:false, Recibos:false,
		Contabilizar:false, Descargar:false},
                headers: [
                    { text: 'Nombre', value: 'nombre' },
                    { text: 'Descripción', value: 'descripcion', sortable: false  },
                    { text: 'Estado', value: 'bloqueado', sortable: false  }                
                ],                           
            }
        },
        created () {
            let me=this;
            me.listarRoles();
        },
        methods:{
            listarRoles(){
                let me=this;
                me.$http.get('api/Roles/Listar').then(function(response){
                    me.roles=response.data;
                });
            },          
        }        
    }
</script>