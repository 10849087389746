<template>
    <v-row align-start>
        <v-col>            
            <v-toolbar dark src="https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg" height='80px'>
                <v-toolbar-title>Pedidos de Ropa</v-toolbar-title>
                <v-divider
                    class="mx-2"
                    inset
                    vertical
                ></v-divider>
                <v-spacer></v-spacer>
                    <v-col cols="1">
                    <v-select v-model="SelAño"                        
                    dense
                    @change="listarCampanas"                                                
                    :items="años" label="AÑO:">
                    </v-select>
                </v-col>                    
                <v-spacer></v-spacer>
                <v-col cols="3">
                    <v-select v-model="Selcanpaña"                        
                    dense
                    @input="listarPedidos"                                              
                    :items="campañas" label="CAMPAÑA:">
                    </v-select>
                </v-col>
                <v-spacer></v-spacer>
                <fa-icon icon="search" style="font-size: 24px; fa-10x;"/>
                <v-text-field class="text-xs-center" v-model="search"  label="Búsqueda" single-line hide-details clearable>                        
                </v-text-field>
                <v-spacer></v-spacer>
                <v-col>
                    <v-btn class="mx-2" 
                        rounded                            
                        color="accent"
                        @click="crearPDFlistadoVenta()"
                        >
                        <v-icon dark>
                            Pdf Soci@s
                        </v-icon>
                    </v-btn>
                    <v-btn class="mx-2" 
                        rounded                            
                        color="accent"
                        @click="crearPDFlistadoCompra()"
                        >
                        <v-icon dark>
                            Pdf Prendas
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-toolbar>
            <br>
            <TablaDatos
                v-bind:opcionesTabla=opcionesTabla                    
                v-bind:items=origenTabla             
                v-bind:headers=headerPedidos
                v-bind:search=search
                v-on:visualizarObjeto="mostrarAlbaran"                    
            />
        </v-col>
            <v-dialog v-model="adModalProcesando" max-width="300" persistent>
                <Procesando/> 
            </v-dialog>
            <v-dialog max-width="1000px" v-model="albaranModal">
                <Albaran
                v-bind:albaranAct="Object.assign({},albaranAct)"                 
                v-on:ocultarAlbaran="albaranModal=0"             
            />
            </v-dialog>        
    </v-row>
</template>
<script>    
    import jsPDF from 'jspdf'
    import autoTable from 'jspdf-autotable'
    import Albaran from '../Libreria/Modales/Albaran.vue'
    import { calcularTotal} from "../Libreria/Funciones"
    export default {
        components: {            
            Albaran                     
        },
        data(){
            return {
                items:[],
                origenTabla:[], 
                headerPedidos: [
                    { text: 'Opciones', value: 'opciones', sortable: false,class: 'my-header-style' },                                       
                    { text: 'Socio', value: 'socio', sortable: true,class: 'my-header-style' },
                    { text: 'Fecha Pedido', value: 'fecha', sortable: true,class: 'my-header-style'  },                    
                    { text: 'Nota', value: 'textolibre', sortable: true,class: 'my-header-style'  },
                    { text: 'Importe', value: 'importe', sortable: true, width: "8%",class: 'my-header-style'  },
                    { text: 'Estado', value: 'idestadopago', sortable: true,class: 'my-header-style'  },
                ],
                search: '',
                años:[],
                SelAño:0,
                campañas:[],
                albaranAct:{},
                Selcanpaña:0,
                albaranNombreSocio:'',
                albaranTelefono1:'',
                albaranEmail1:'',
                albaranFecha:'',
                albaranTitulo:'',
                albaranModal:0,
                adModalProcesando:0,
                lineas:[],
                lineasTodas:[],
                lineasCompraTodas:[],
                logo: require('@/assets/Armarria.png'),
                opcionesTabla:{Seleccion:false, AbrirCerrar:false, ActivarBloquear:false, Visualizar:true, Modificar:false, Eliminar:false, Historico:false, Recibos:false,
		Contabilizar:false, Descargar:false},                                  
            }
        },                
        created() {
            let me=this;
            me.adModalProcesando=1;
            me.listarAños();                         
        },
        methods:{
            calcularTotal,
            listarAños(){
                let me=this;
                var añosArray=[]; 
                me.$http.get('api/Conceptos/ListarAñosCampañas').then(function(response){
                    añosArray=response.data;
                    añosArray.map(function(x){
                    me.años.push({text: x.año,value:x.año});
                    });
                    me.SelAño=añosArray[0].año;
                    me.listarCampanas();                 
                }).catch(function(error){
                    console.log(error);
                });
            },
            listarCampanas(){
                let me=this;
                me.campañas=[];
                me.items=[];
                me.Selcanpaña="";
                var ConceptosArray=[]; 
                me.$http.get('api/Conceptos/ListarCampañasAños/'+me.SelAño,{}).then(function(response){
                    ConceptosArray=response.data;
                    ConceptosArray.map(function(x){
                    me.campañas.push({text: x.descripcion,value:x.idconceptos});
                    });
                    me.Selcanpaña=ConceptosArray[0].idconceptos;
                    me.listarPedidos();              
                }).catch(function(error){
                    console.log(error);
                });
            },
            listarPedidos(){
                let me=this;                                 
                me.$http.get('api/Inscripciones/FiltrarPorConcepto/'+me.Selcanpaña).then(function(response){
                    me.items=response.data;
                    me.listarLineasTodas();
                    me.listarLineasCompraTodas();
                    me.preparaTabla();
                    me.adModalProcesando=0;                                                   
                }).catch(function(error){
                    console.log(error);                   
                });
            },
            preparaTabla(){
                let me=this;
                me.origenTabla=[];
                me.items.map(function(x){
                    me.origenTabla.push({idinscripciones:x.idinscripciones, socio: x.socio,fecha: me.$fechaCorta(x.fecha,2), textolibre: x.textolibre,importe:x.importe,idestadopago:x.idestadopago});
                });             
            },
            listarLineas(id,mostrarAlbaran){
                let me=this;                                
                me.$http.get('api/LineasPedidos/ListarPorPedido/'+id).then(function(response){                    
                    me.lineas=response.data;
                    if (mostrarAlbaran) {
                        me.albaranAct.lineas=me.lineas;
                        me.albaranModal=1;
                        me.adModalProcesando=0;
                    }
                }).catch(function(error){
                    console.log(error);
                });
            },
            listarLineasTodas() {
                let me=this;
                me.$http.get('api/LineasPedidos/LineasDePedidosCampaña/'+me.Selcanpaña).then(function(response){
                    me.lineasTodas=response.data;
                }).catch(function(error){
                    console.log(error);
                });                
            },
            listarLineasCompraTodas() {
                let me=this;
                me.$http.get('api/LineasPedidos/LineasDePedidosCompraCampaña/'+me.Selcanpaña).then(function(response){
                    me.lineasCompraTodas=response.data;
                }).catch(function(error){
                    console.log(error);
                });                
            },             
            mostrarAlbaran(id){
                let me=this;
                me.adModalProcesando=1;
                const item=me.items.find(obj => {return Object.values(obj)[0] === id});
                me.albaranAct={socio: item.socio,telefono:item.telefono1,email:item.email1,fecha:me.$fechaCorta(item.fecha),textolibre: item.textolibre, concepto: me.campañas.find(obj => {return Object.values(obj)[1] === item.idconceptos}).text};
                const nombresocio = me.items.find(obj => {return Object.values(obj)[0] === id});
                me.listarLineas(id,true);                
            },
            crearPDFlistadoVenta() {                
                var me = this;                
                function clone( obj ) {
                    if ( obj === null || typeof obj  !== 'object' ) {
                        return obj;
                    }          
                    var temp = obj.constructor();
                    for ( var key in obj ) {
                        temp[ key ] = clone( obj[ key ] );
                    } 
                    return temp;
                };             
                var registros=clone(me.lineasTodas);
                var doc = new jsPDF('p', 'pt');              
                //Colocamos el logo
                var img=new Image();
                img.src=me.logo;
                doc.addImage(img, 'JPEG', 40, 20, 40, 50);
                //Definimos el título              
                doc.text(me.campañas.find(element => element.value === me.Selcanpaña).text + " (por socios)", 300, 40,'center');              
                //Modificamos algunas columnas que nos interesan:
                
                let euroEU = Intl.NumberFormat("de-DE", {
                        style: "currency",
                        currency: "EUR",
                    });
                    for (var i=0;i<registros.length;i++){
                    delete registros[i].idlineaspedidos;
                    /* registros[i].importe=euroEU.format(registros[i].importe); */
                    if (registros[i].chica) registros[i].chica='Si'; else registros[i].chica='No';
                    if (registros[i].docuok) registros[i].entallado='Si'; else registros[i].entallado='No';
                    };
                    //Preparamos la cabecera
                let header=['Socio', 'Prenda','Talla','Chica','Entall.','Var.','Cant.','Precio','Subtotal']
                
                //Generamos la tabla
                doc.setFontSize(8);
                var values=new Array();
                var importeAnt=0;
                var sumaCant=registros[0].cantidad;            
                var socioAct=registros[0].socio;
                var sumaImporteTotal=0;
                var sumaPrendasTotal=0;
                importeAnt=registros[0].importe;
                registros[0].importe=euroEU.format(parseFloat(registros[0].cantidad) * parseFloat(registros[0].preciov));
                registros[0].preciov=euroEU.format(registros[0].preciov);
                values.push(registros[0]);
                for (var i=1;i<registros.length;i++){
                    if (registros[i].socio==socioAct) {
                        registros[i].socio='';
                        sumaCant=sumaCant+registros[i].cantidad;
                        registros[i].importe=euroEU.format(parseInt(registros[i].cantidad) * parseFloat(registros[i].preciov));
                        registros[i].preciov=euroEU.format(registros[i].preciov);
                        values.push(registros[i]);
                    }
                    else {                 
                        values.push({socio:'', prenda:'', talla:'', chica:'', entallado:'', variante:'Prenda(s)', cantidad: sumaCant, preciov:'Total:', importe: euroEU.format(importeAnt)});
                        sumaImporteTotal+=importeAnt;
                        importeAnt=registros[i].importe;
                        sumaPrendasTotal+=sumaCant; 
                        sumaCant=registros[i].cantidad;
                        socioAct=registros[i].socio;
                        registros[i].importe=euroEU.format(parseFloat(registros[i].cantidad) * parseFloat(registros[i].preciov));
                        registros[i].preciov=euroEU.format(registros[i].preciov);
                        values.push(registros[i]);                    
                    }
                };            
                values.push({socio:'', prenda:'', talla:'', chica:'', entallado:'', variante:'Prenda(s)', cantidad: sumaCant, preciov:'Total:', importe: euroEU.format(importeAnt)});
                sumaPrendasTotal+=sumaCant;
                sumaImporteTotal+=importeAnt;
                values.push({socio:'', prenda:'', talla:'', chica:'', entallado:'', variante:'', cantidad: '', preciov:'', importe: ''});
                values.push({socio:'', prenda:'', talla:'', chica:'', entallado:'', variante:'Prendas Tot.', cantidad: sumaPrendasTotal, preciov:'TOTAL:', importe: euroEU.format(sumaImporteTotal)});
                autoTable(doc, {head: [header],body: values.map((elemento) => Object.values(elemento)),startY: doc.pageCount > 1? doc.autoTableEndPosY():75, styles: { fontSize: 7 }, didDrawCell: function (hookData) {if (hookData.section === 'body') {if (hookData.row.raw[1] === '') {for (const cell of Object.values(hookData.row.cells)) {cell.styles.fontStyle = 'bold';}}}},columnStyles: {2: {halign: 'center'},3: {halign: 'center'},4: {halign: 'center'},6: {halign: 'center'},7: {halign: 'right'},8: {halign: 'right'}},didParseCell: function (data) {var rows = data.table.body;if (data.row.index === rows.length - 1) {data.cell.styles.fillColor = [12, 120, 174];data.cell.styles.fontSize = 8,data.cell.styles.textColor = 'white'}}
                });
                //Preparamos el pie de página              
                const pages = doc.internal.getNumberOfPages();
                const pageWidth = doc.internal.pageSize.width;  //Optional
                const pageHeight = doc.internal.pageSize.height;  //Optional
                doc.setFontSize(9);                     
                for (let j = 1; j < pages + 1 ; j++) {
                        let horizontalPos = pageWidth / 2;  //Can be fixed number
                        let verticalPos = pageHeight - 10;  //Can be fixed number
                        doc.setPage(j);
                        doc.text(`${j} de ${pages}`, horizontalPos, verticalPos, {align: 'center'});
                };
                doc.save('Listado.pdf');
                me.seleccion=[];
            },
            crearPDFlistadoCompra() {                
                var me = this;                
                function clone( obj ) {
                    if ( obj === null || typeof obj  !== 'object' ) {
                        return obj;
                    }          
                    var temp = obj.constructor();
                    for ( var key in obj ) {
                        temp[ key ] = clone( obj[ key ] );
                    } 
                    return temp;
                };             
                var registros=clone(me.lineasCompraTodas);
                var doc = new jsPDF('p', 'pt');              
                //Colocamos el logo
                var img=new Image();
                img.src=me.logo;
                doc.addImage(img, 'JPEG', 40, 20, 40, 50);
                //Definimos el título              
                doc.text(me.campañas.find(element => element.value === me.Selcanpaña).text + " (por prendas)", 300, 40,'center');              
                //Modificamos algunas columnas que nos interesan:
                
                let euroEU = Intl.NumberFormat("de-DE", {
                        style: "currency",
                        currency: "EUR",
                    });
                    for (var i=0;i<registros.length;i++){
                    delete registros[i].idlineaspedidos;
                    if (registros[i].chica) registros[i].chica='Si'; else registros[i].chica='No';
                    if (registros[i].docuok) registros[i].entallado='Si'; else registros[i].entallado='No';
                    };
                    //Preparamos la cabecera
                let header=['Prenda','Talla','Socio','Chica','Entall.','Var.','Cant.','Precio(c)','Subtotal']
                
                //Generamos la tabla
                doc.setFontSize(8);
                var values=new Array();                
                var sumaCantTalla=registros[0].cantidad;
                var sumaCantPrenda=0;
                var sumaImporteTotal=0;
                var sumaPrendasTotal=0;                 
                var prendaAnt=registros[0].prenda;
                var tallaAnt=registros[0].talla;
                var preciocAnt=registros[0].precioc;
                registros[0].importe=euroEU.format(parseInt(registros[0].cantidad) * parseFloat(registros[0].precioc));
                registros[0].precioc=euroEU.format(registros[0].precioc);
                values.push(registros[0]);
                for (var i=1;i<registros.length;i++){
                    if (registros[i].prenda ==prendaAnt) {
                        registros[i].prenda='';
                        if (registros[i].talla == tallaAnt){
                            registros[i].talla='';
                            sumaCantTalla+=registros[i].cantidad;
                            registros[i].importe=euroEU.format(parseInt(registros[i].cantidad) * parseFloat(registros[i].precioc));
                            preciocAnt=registros[i].precioc;
                            registros[i].precioc=euroEU.format(registros[i].precioc);
                            values.push(registros[i]);
                        }
                        else {                            
                            values.push({prenda:'', talla:'', socio:'', chica:'', entallado:'', variante:'T.Talla', cantidad: sumaCantTalla, preciov:'Subtotal:', importe:euroEU.format(sumaCantTalla*parseFloat(registros[i].precioc))});
                            sumaCantPrenda+=sumaCantTalla;
                            sumaCantTalla=registros[i].cantidad;
                            tallaAnt=registros[i].talla;
                            registros[i].importe=euroEU.format(parseInt(registros[i].cantidad) * parseFloat(registros[i].precioc));
                            preciocAnt=registros[i].precioc;
                            registros[i].precioc=euroEU.format(registros[i].precioc);
                            values.push(registros[i]);
                        }
                    }
                    else {
                        values.push({prenda:'', talla:'', socio:'', chica:'', entallado:'', variante:'T.Talla', cantidad: sumaCantTalla, preciov:'Subtotal:', importe:euroEU.format(sumaCantTalla*parseFloat(preciocAnt))});
                        sumaCantPrenda+=sumaCantTalla;                        
                        values.push({prenda:'', talla:'', socio:'', chica:'', entallado:'', variante:'T.Prenda(s)', cantidad: sumaCantPrenda, precioc:'Total:', importe:euroEU.format(sumaCantPrenda*parseFloat(preciocAnt))});
                        sumaImporteTotal+=sumaCantPrenda*parseFloat(preciocAnt);
                        sumaPrendasTotal+=sumaCantPrenda;
                        sumaCantTalla=registros[i].cantidad;
                        sumaCantPrenda=0;
                        prendaAnt=registros[i].prenda;
                        tallaAnt=registros[i].talla;
                        registros[i].importe=euroEU.format(parseInt(registros[i].cantidad) * parseFloat(registros[i].precioc));
                        preciocAnt=registros[i].precioc;
                        registros[i].precioc=euroEU.format(registros[i].precioc);
                        values.push(registros[i]);                    
                    }
                };
                values.push({prenda:'', talla:'', socio:'', chica:'', entallado:'', variante:'T.Talla', cantidad: sumaCantTalla, preciov:'Subtotal:', importe:euroEU.format(sumaCantTalla*parseFloat(preciocAnt))});
                sumaCantPrenda+=sumaCantTalla;                        
                values.push({prenda:'', talla:'', socio:'', chica:'', entallado:'', variante:'T.Prenda(s)', cantidad: sumaCantPrenda, precioc:'Total:', importe:euroEU.format(parseInt(sumaCantPrenda)*parseFloat(preciocAnt))});
                sumaPrendasTotal+=sumaCantPrenda;
                sumaImporteTotal+=sumaCantPrenda*parseFloat(preciocAnt);
                values.push({prenda:'', talla:'', socio:'', chica:'', entallado:'', variante:'', cantidad: '', precioc:'', importe:''});
                values.push({prenda:'', talla:'', socio:'', chica:'', entallado:'', variante:'Prendas Tot.', cantidad: sumaPrendasTotal, precioc:'TOTAL:', importe:euroEU.format(sumaImporteTotal)});
                autoTable(doc, {head: [header],body: values.map((elemento) => Object.values(elemento)),startY: doc.pageCount > 1? doc.autoTableEndPosY():75, styles: { fontSize: 7 },columnStyles: {1: {halign: 'center'},3: {halign: 'center'},4: {halign: 'center'},6: {halign: 'center'},7: {halign: 'right'},8: {halign: 'right'}},didDrawCell: function (hookData) {if (hookData.section === 'body') {if (hookData.row.raw[2] === '') {for (const cell of Object.values(hookData.row.cells)) {cell.styles.fontStyle = 'bold';}}}},didParseCell: function (data) {var rows = data.table.body;if (data.row.index === rows.length - 1) {data.cell.styles.fillColor = [12, 120, 174];data.cell.styles.fontSize = 8,data.cell.styles.textColor = 'white'}}                
                });
                //Preparamos el pie de página              
                const pages = doc.internal.getNumberOfPages();
                const pageWidth = doc.internal.pageSize.width;  //Optional
                const pageHeight = doc.internal.pageSize.height;  //Optional
                doc.setFontSize(9);                     
                for (let j = 1; j < pages + 1 ; j++) {
                        let horizontalPos = pageWidth / 2;  //Can be fixed number
                        let verticalPos = pageHeight - 10;  //Can be fixed number
                        doc.setPage(j);
                        doc.text(`${j} de ${pages}`, horizontalPos, verticalPos, {align: 'center'});
                };
                doc.save('Listado.pdf');
                me.seleccion=[];
            },                                                 
        }        
    }
</script>