<template>
    <v-container fluid>
        <v-row align-start>
            <v-col>                
                <Cabecera
                    v-bind:objeto=ADobjetos                                  
                    v-on:Nuevo="nuevoObjeto"
                    @hook:mounted="activarWatcher"
                    ref="cabecera"                             
                />
                <br>
                <TablaDatos
                    v-bind:opcionesTabla=opcionesTabla                    
                    v-bind:items=origenTabla
                    v-bind:headers=headers
                    v-bind:search=search
                    v-on:mostrarActivarBloquear="mostrarActivarBloquear"
                    v-on:editarObjeto="editarUsuario"
                    v-on:mostrarEliminar="activarEliminar"
                    v-on:listarObjetos="listarObjetos"                    
                />
            </v-col>
        </v-row>
        <v-dialog v-model="adModalProcesando" max-width="300" persistent>
            <Procesando/> 
        </v-dialog>
        <v-dialog v-model="adModalTextoRepetido" max-width="500" persistent>            
            <ModalTipo1
                titulo="Valor Repetido"
                :mensaje="'El valor <strong>' + ADtexto + '</strong> del campo <strong>' + ADcampo + '</strong> está repetido. Ya existe ese valor para otro ' + ADobjeto"                        
                v-on:cerrarModal="adModalTextoRepetido=0"          
            />    
        </v-dialog>
        <v-dialog v-model="adModalUsuarioCreado" max-width="500" persistent>            
            <ModalTipo1
                titulo="Usuario creado"
                :mensaje="'El/la usuario <strong>' + regAct.nombre + '</strong> se ha creado satisfactoriamente, se ha enviado un mensaje de correo con la contraseña provisional de acceso a su dirección <strong>' + regAct.email + '</strong>'"                        
                v-on:cerrarModal="ocultarUsuarioCreado"          
            />    
        </v-dialog>
        <v-dialog v-model="adModalNuevoEditar" max-width="520px">
            <EditarNuevo
                v-if="adModalNuevoEditar"                    
                v-bind:formTitle=formTitle 
                v-bind:regAct=regAct
                v-bind:camposEditables=camposEditables 
                v-on:ocultarFormEditarNuevo="ocultarFormEditarNuevo"
                v-on:guardarObjeto="guardarObjeto"               
            />
        </v-dialog>
        <v-dialog v-model="adModalActivarBloquear" max-width="500">
            <ModalTipo3
                    v-if="adModalActivarBloquear"                
                    v-bind:accion=adAccion
                    v-bind:objeto=ADobjeto 
                    v-bind:nombre=regAct.nombre
                    v-on:cancelarClick="adModalActivarBloquear=0"
                    v-on:aceptarClick="cambiarEstadoObjeto"    
                />
        </v-dialog>
        <v-dialog v-model="adModalEliminar" max-width="500">
            <ModalTipo1                
                :titulo="'Eliminar ' + ADobjeto"
                :mensaje="'Estás a punto de eliminar ' + ADobjeto + ' <strong>' + 
                    regAct.nombre + '</strong> ¿Quieres continuar?'"
                v-bind:aceptarCancelar="true"
                v-on:cancelarClick="adModalEliminar=0"
                v-on:aceptarClick="eliminarObjeto"    
            />
        </v-dialog>        
    </v-container>    
</template>
<script>    
    export default {
        data(){
            return {
                items:[],
                roles:[],
                idiomas:[],
                aspectos:[],
                origenTabla:[],
                regAnt:{},                
                adModalNuevoEditar: 0,
                adModalActivarBloquear: 0,
                adModalEliminar:0,
                adModalProcesando:0,
                adModalTextoRepetido:0,
                adModalUsuarioCreado:0,
                headers: [
                    { text: 'Opciones', value: 'opciones', font: 'bold',class: 'my-header-style' },
                    { text: 'Nombre', value: 'nombre', sortable: true,class: 'my-header-style' },
                    { text: 'Rol', value: 'nombrerol', sortable: true,class: 'my-header-style' },                    
                    { text: 'Email', value: 'email', sortable: true,class: 'my-header-style'  },
                    { text: 'Estado', value: 'bloqueado', sortable: true,class: 'my-header-style' },
                    { text: 'Conexión', value: 'conexion', sortable: true,class: 'my-header-style' }                
                ],
                search: '',
                editedIndex: -1,
                regAct:{},
                passwordAnt:'',                
                opcionesTabla:{Seleccion:false, AbrirCerrar:false, ActivarBloquear:true, Visualizar:false, Modificar:true, Eliminar:true, Historico:false, Recibos:false,
		Contabilizar:false, Descargar:false},
                adAccion: 0,    
                ADobjetos:'Usuarios',
                ADobjeto:'Usuario',
                ADtexto:'',
                ADcampo:'',
                camposEditables:5,
            }
        },
        computed: {
            formTitle () {
                return this.editedIndex === -1 ? 'Nuevo usuario' : 'Actualizar usuario'
            }
        },
        created() {
            let me=this;
            me.adModalProcesando=1;            
            me.listarObjetos();
            me.listarRoles();
            me.listarAspectos();
            me.listarIdiomas();
        },        
        methods:{
            activarWatcher(){
                let me=this;
			    me.$watch(
			    "$refs.cabecera.search",
			    (new_value, old_value) => (me.search = new_value)
                );
            },            
            listarObjetos(){
                let me=this;               
                me.$http.get('api/Usuarios/Listar').then(function(response){                  
                    me.items=response.data;
                    me.preparaTabla();
                    me.adModalProcesando=0;
                }).catch(function(error){
                    console.log(error);
                });
            },
            preparaTabla(){
                let me=this;
                me.origenTabla=[];
                me.items.map(function(x){
                    me.origenTabla.push({idusuarios: x.idusuarios,nombre: x.nombre,nombrerol: x.nombrerol, email:x.email, bloqueado:x.bloqueado, conexion:me.acortarAhora(x.conexion) });
                })                
            },
            listarRoles(){
                let me=this;
                var rolesArray=[];
                me.$http.get('api/Roles/Select').then(function(response){
                    rolesArray=response.data;
                    rolesArray.map(function(x){
                        me.roles.push({text: x.nombre,value:x.idroles});
                    });
                }).catch(function(error){
                    console.log(error);
                });
            },
            listarIdiomas(){
                let me=this;
                var idiomasArray=[];
                me.$http.get('api/Idiomas/Listar').then(function(response){
                    idiomasArray=response.data;
                    idiomasArray.map(function(x){
                        me.idiomas.push({text: x.descripcion,value:x.ididiomas});
                    });                    
                }).catch(function(error){
                    console.log(error);
                });
            },
            listarAspectos(){
                let me=this;
                var aspectosArray=[];
                me.$http.get('api/Aspectos/Listar').then(function(response){
                    aspectosArray=response.data;
                    aspectosArray.map(function(x){
                        me.aspectos.push({text: x.descripcion,value:x.idaspectos});
                    });
                }).catch(function(error){
                    console.log(error);
                });
            },
            editarUsuario(id) {
                let me=this;
                const item=me.items.find(obj => {return Object.values(obj)[0] === id});
                me.regAct=Object.assign({},item);
                me.añadirProps();
                me.regAnt=Object.assign({},item);
                delete me.regAnt.nombrerol;
                delete me.regAnt.conexion;
                delete me.regAnt.aspecto;
                delete me.regAnt.idioma;
                delete me.regAnt.password_hash;
                me.editedIndex=1;
                me.adModalNuevoEditar = 1
            },
            nuevoObjeto() {
                let me=this;                                              
                me.añadirProps();
                me.regAct.idroles.valor=4;
                me.regAct.ididiomas.valor=1;
                me.regAct.idaspectos.valor=1;
                me.adModalNuevoEditar = 1
            },
            añadirProps(){
                let me=this;
                me.regAct= {idusuarios:me.regAct.idusuarios,
                    idroles: {
                        tipo:'sel',opciones:me.roles,etiqueta:'Rol',valor:me.regAct.idroles,maxlength:10,required: true
                    },
                    nombre: {
                        tipo:'text',etiqueta:'Nombre',valor:me.regAct.nombre,maxlength: 15,required: true
                    },
                    email: {
                        tipo:'email',etiqueta:'Email',valor:me.regAct.email,maxlength: 40,required: true
                    },
                    ididiomas: {
                        tipo:'sel',opciones:me.idiomas,etiqueta:'Idioma',valor:me.regAct.ididiomas,maxlength:10,required: true,disabled: true
                    },
                    idaspectos: {
                        tipo:'sel',opciones:me.aspectos,etiqueta:'Aspecto',valor:me.regAct.idaspectos,maxlength:10,required: true,disabled: true
                    },                    
                    bloqueado:me.regAct.bloqueado
                    };
            },
            eliminarProps(miObjeto){
                let me=this;              
                Object.defineProperties(miObjeto, { 
                    "idroles": {
                        value: miObjeto.idroles.valor
                    },
                    "nombre": {
                        value: miObjeto.nombre.valor
                    },
                    "email": {
                        value: miObjeto.email.valor
                    },
                    "ididiomas": {
                        value: miObjeto.ididiomas.valor
                    },
                    "idaspectos": {
                        value: miObjeto.idaspectos.valor
                    },
                    "bloqueado": {
                        value: miObjeto.bloqueado
                    },
                });
                return miObjeto;
            },
            ocultarFormEditarNuevo() {
                let me=this;
                me.adModalNuevoEditar = 0;
                me.borrarVariables();
            },
            borrarVariables(){
                let me=this;
                me.regAct={idusuarios:'',idroles:'',nombre:[],email:[]},               
                me.editedIndex=-1;
            },
            guardarObjeto(regAct) {
                let me=this;                
                me.adModalProcesando=1;
                me.adModalNuevoEditar = 0;
                var existeRepeticion=false;
                regAct=me.eliminarProps(regAct);                
                const regTmp=Object.assign({},me.regAct);/* Creamos un objeto temporal para este metodo, que no afecte al exterior */                
                if (me.encuentraRepetición('idusuarios','nombre',regTmp.nombre)==1) {/* Buscamos repeticiones */
                    me.ADtexto=regTmp.nombre;
                    me.ADcampo='Nombre';
                    existeRepeticion=true;                  
                };
                if (me.encuentraRepetición('idusuarios','email',regTmp.email)==1) {/* Buscamos repeticiones */
                    me.ADtexto=regTmp.email;
                    me.ADcampo='Email';
                    existeRepeticion=true;                 
                };
                if (existeRepeticion){
                    me.adModalProcesando=0;
                    me.adModalTextoRepetido=1;
                    me.regAct=Object.assign({},regAct);
                    me.añadirProps();
                    me.adModalNuevoEditar = 1;
                    return;
                };
                if (me.editedIndex > -1) {
                    //Código para editar
                    if (me.$hasChanged(regTmp,me.regAnt)){
                        me.$http.put('api/Usuarios/Actualizar',regTmp).then(function(response){
                        me.listarObjetos();
                        me.borrarVariables();                        
                    }).catch(function(error){
                        console.log(error);
                    });
                    }
                    else {
                        me.borrarVariables();
                        me.adModalProcesando=0;
                    }                    
                } else {
                    //Código para guardar
                    me.$http.post('api/Usuarios/Crear',regTmp).then(function(response){
                        me.listarObjetos();
                        me.adModalUsuarioCreado=1;                   
                    }).catch(function(error){
                        console.log(error);
                    });
                }
            },
            ocultarUsuarioCreado() {
                let me=this;
                me.adModalUsuarioCreado=0;
                me.borrarVariables(); 
            },
            activarEliminar(id){
                let me=this;
                const item=me.items.find(obj => {return Object.values(obj)[0] === id});
                me.regAct.idusuarios=id;
                me.regAct.nombre=item.nombre;
                me.adModalEliminar=1;               
            },
            eliminarObjeto() {  
                //Código para borrar
                let me=this;
                me.adModalProcesando=1;
                me.$http.put('api/Usuarios/Eliminar/'+me.regAct.idusuarios,{}).then(function(response){                       
                    me.adModalEliminar=0;
                    me.borrarVariables();
                    me.listarObjetos();                           
                }).catch(function(error){
                    console.log(error);
                });                
            },
            mostrarActivarBloquear(accion,id){ 
                let me=this;
                const item=me.items.find(obj => {return Object.values(obj)[0] === id});
                me.regAct.idusuarios=id;
                me.regAct.nombre=item.nombre;
                me.adAccion=accion==1 ? "Bloquear" : "Activar"      
                me.adModalActivarBloquear=1;
            },         
            cambiarEstadoObjeto(){
                let me=this;
                me.adModalProcesando=1;
                if (me.adAccion=="Activar"){
                    me.$http.put('api/Usuarios/Activar/'+me.regAct.idusuarios,{}).then(function(response){
                    me.adModalActivarBloquear=0;
                    me.adModalEliminar=0;
                    me.adAccion='';                    
                    me.listarObjetos();                       
                    }).catch(function(error){
                        console.log(error);
                    });
                }
                else {
                   me.$http.put('api/Usuarios/Bloquear/'+me.regAct.idusuarios,{}).then(function(response){
                    me.adModalActivarBloquear=0;
                    me.adModalEliminar=0;
                    me.adAccion='';
                    me.listarObjetos();                       
                    }).catch(function(error){
                        console.log(error);
                    });
                }
            },
             acortarAhora(dateISOString){
                if (dateISOString==null) return null;
                var ahora = new Date(dateISOString);
                var year = ahora.getFullYear();
                var month = ahora.getMonth()+1;
                var day = ahora.getDate();
                var hour = ahora.getHours();
                var minutes = ahora.getMinutes();
                if (day < 10) {
                    day = '0' + day;
                }
                if (month < 10) {
                    month = '0' + month;
                }
                return `${day}/${month}/${year} - ${hour}h:${minutes}mn`;
            },
            encuentraRepetición(id,campo,texto){
                let me=this;
                var sw=0;
                for(var i=0;i<me.items.length;i++){
                    if (me.items[i][campo].toUpperCase()==texto.toUpperCase() && me.items[i][id]!=me.regAct[id]){
                        sw=1;    
                    }
                }               
                return sw;
            }    
        }        
    }
</script>