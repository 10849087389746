import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import IdleVue from "idle-vue"
import Vuex from 'vuex'
import moment from 'moment'
require('./plugins/fontawesome');
Vue.prototype.$http = axios;
Vue.prototype.$añomin="2010";//variable hauen edozein tokitan jarri daitezke this.$añomin bezala
Vue.prototype.$añomax="2050";
/* activar alternativamente este bloque de dos líneas o el siguiente ************* pruebas/bueno     ******************/

/* Vue.prototype.$urlGeneral="https://urgozo.org/";
Vue.prototype.$colorBarra="warning"; */

Vue.prototype.$urlGeneral="https://junta.urgozo.org/";
Vue.prototype.$colorBarra="primary";

/* funciones globales accesibles desde cualquier componente de forma me.$fechaCorta */
import {fechaCorta} from './components/Libreria/Funciones.js'
Vue.prototype.$fechaCorta = fechaCorta
import {fechaLarga} from './components/Libreria/Funciones.js'
Vue.prototype.$fechaLarga = fechaLarga
import {hasChanged} from './components/Libreria/Funciones.js'
Vue.prototype.$hasChanged = hasChanged
import {calcularEdad} from './components/Libreria/Funciones.js'
Vue.prototype.$calcularEdad = calcularEdad
import {formatPrice} from './components/Libreria/Funciones.js'
Vue.prototype.$formatPrice = formatPrice
Vue.config.productionTip = false;
Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY HH:mm')
  }
});
axios.defaults.baseURL= process.env.NODE_ENV === 'production' ? Vue.prototype.$urlGeneral : 'http://localhost:63038/'
axios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
  }, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  if(error.response.status === 401) {
      console.log(axios.defaults.headers);
      // redirect to login page      
      router.push({name: 'login'}).catch(() => {});
  }  
  else {
    if(error.response.status === 404) {
      router.push({name: 'loginfault'}).catch(() => {});
    }
    else console.log(error.response.status);    
  }
  return Promise.reject(error);
});
const eventsHub = new Vue();
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 600000, // Es el tiempo de expiración (1000 x segundo),
  startAtIdle: false
});
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
Vue.component("Cabecera", () => import("./components/Libreria/Secciones/Cabecera.vue"));
Vue.component("TablaDatos", () => import("./components/Libreria/Secciones/TablaDatos.vue"));
Vue.component("FichaSocio", () => import("./components/Libreria/Modales/FichaSocio.vue"));
Vue.component("Procesando", () => import("./components/Libreria/Modales/Procesando.vue"));
Vue.component("EditarNuevo", () => import("./components/Libreria/Modales/EditarNuevo.vue"));
Vue.component("ModalTipo1", () => import("./components/Libreria/Modales/ModalTipo1.vue"));
Vue.component("ModalTipo2", () => import("./components/Libreria/Modales/ModalTipo2.vue"));
Vue.component("ModalTipo3", () => import("./components/Libreria/Modales/ModalTipo3.vue"));