<template>
    <v-row align-start>
        <v-col>
            <Cabecera
                v-bind:objeto=ADobjetos
                v-bind:años=años                                                
                v-on:Nuevo="adModalNuevoEditar=1"
                @hook:mounted="activarWatcher" 
                ref="cabecera"
                v-model="SelAño"                             
            />
            <br>
            <TablaDatos
                padre="Facturas"
                v-bind:opcionesTabla=opcionesTabla                    
                v-bind:items=origenTabla 
                v-bind:headers=headers
                v-bind:search=search
                v-on:visualizarObjeto="visualizarObjeto"            
                v-on:editarObjeto="editarObjeto"
                v-on:mostrarEliminar="mostrarEliminar"
                v-on:listarObjetos="listarObjetos"
                v-on:contabilizarFactura="contabilizarFactura" 
                v-on:descargarArchivo="descargarArchivo"                    
            />
        </v-col>            
        <v-dialog v-model="adModalNuevoEditar" max-width="600px" persistent>
            <!-- En este caso no usaremos el componente EditarNuevo por lo complejo que sería -->
            <v-card>
                <v-card-title>
                <span class="headline">{{ formTitle }}</span>
                </v-card-title>                
                <v-card-text>
                <v-container grid-list-md>
                    <v-row wrap>
                    <v-col cols="4">
                        <v-text-field label="Fecha Fra.(*)" type="date" 
                        v-model="regAct1.fecha"
                        :disabled="editedIndex==0">
                        </v-text-field>
                    </v-col>
                    <v-col cols="5">
                        <v-text-field label="Número Fra."  
                        v-model="regAct1.numero" maxLength="15" clearable
                        :disabled="editedIndex==0">
                        </v-text-field>
                    </v-col>
                    <v-col cols="3">
                        <v-text-field hide-details single-line type="number" v-model="regAct1.importe" suffix="€" label="Importe(*)"
                        :disabled="editedIndex==0">
                        </v-text-field>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field label="CIF" 
                        v-model="regAct1.cif"
                        maxLength="9" clearable
                        :disabled="editedIndex==0">
                        </v-text-field>
                    </v-col>
                    <v-col cols="7">
                        <v-text-field label="Proveedor(*)" 
                        v-model="regAct1.proveedor" maxLength="30" clearable
                        :disabled="editedIndex==0">
                        </v-text-field>
                    </v-col>                    
                    <v-col cols="4"> 
                        <v-switch
                            v-model="regAct1.subvencionable"
                            :label="'Subvencionable'"
                        :disabled="editedIndex==0"></v-switch>
                    </v-col>
                    <v-col cols="7">
                        Fichero:
                        <br>
                        <v-file-input
                            v-show="editedIndex!=0 && regAct1.fichero==null"
                            @change="validarArchivo"                                        
                            v-model="chosenFile"
                            ref="fileupload"
                        ></v-file-input>
                        <v-btn v-if="regAct1.fichero!=null" color="accent" dark class="mb-2" text @click.native="descargarArchivo(regAct1.idfacturas)">DESCARGAR</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn v-if="editedIndex!=0 &&regAct1.fichero!=null" color="accent" dark class="mb-2" text @click.native="adModalArchivoEliminar=1">ELIMINAR</v-btn>                                    
                    </v-col>
                    <v-col cols="9">
                        <v-text-field v-model="regAct1.detalle" label="Detalle" maxLength="40" clearable
                            :disabled="editedIndex==0">                                        
                        </v-text-field>
                    </v-col>
                                                    
                    <v-col cols="3" v-show="valida">
                        <div class="red--text" v-for="v in validaMensaje" :key="v" v-text="v">
                        </div>
                    </v-col>
                    </v-row>
                </v-container>
                </v-card-text>                
                <v-card-actions>
                    <v-spacer></v-spacer>                                
                    <v-btn v-if="editedIndex!=0" color="accent" dark class="mb-2" text @click.native="ocultarFormEditaNuevo">Cancelar</v-btn>
                    <v-btn v-if="editedIndex!=0" color="accent" dark class="mb-2" @click.native="continuarFactura">Continuar</v-btn>
                    <v-btn v-if="editedIndex==0" color="accent" dark class="mb-2" @click.native="adModalNuevoEditar=0">Cerrar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="adModalEliminar" max-width="500">
            <ModalTipo1                
                :titulo="'Eliminar ' + ADobjeto"
                :mensaje="'Estás a punto de eliminar ' + ADobjeto + ' <strong>' + 
                    ADnombre + '</strong> ¿Quieres continuar?'"
                v-bind:aceptarCancelar="true"
                v-on:cancelarClick="adModalEliminar=0"
                v-on:aceptarClick="eliminarObjeto"    
            />
        </v-dialog>
        <v-dialog v-model="adModalPreguntaConta" max-width="500" persistent>
            <ModalTipo1                
                titulo="Contabilizar factura"
                mensaje="¿Quieres contabilizar ahora el pago de esta factura?"
                v-bind:aceptarCancelar="true"
                v-on:cancelarClick="guardarFactura"
                v-on:aceptarClick="mostrarContabilizar"    
            />
        </v-dialog> 
        <v-dialog v-model="adModalContabilizar" max-width="600">
            <EditarNuevo                    
                formTitle='Contabilizar Pago Factura' 
                :regAct=Object.assign({},regAct2)
                v-bind:camposEditables=camposEditables 
                v-on:ocultarFormEditarNuevo="adModalContabilizar=0"
                v-on:guardarObjeto="guardarAsiento"               
            />
        </v-dialog>        
        <v-dialog v-model="adModalSubidaIncorrecta" max-width="500">            
            <ModalTipo1
                titulo="Archivo NO guardado"
                mensaje=" Existe un problema con este archivo"                        
                v-on:cerrarModal="adModalSubidaIncorrecta=0"          
            />
        </v-dialog>
        <v-dialog v-model="adModalArchivoEliminar" max-width="500">
            <ModalTipo1                
                titulo="Eliminar archivo"
                :mensaje="'Estás a punto de eliminar el archivo <strong>' + regAct1.fichero + ' </strong> ¿Quieres continuar?'"
                v-bind:aceptarCancelar="true"
                v-on:cancelarClick="adModalArchivoEliminar=0"
                v-on:aceptarClick="eliminarArchivo"    
            />
        </v-dialog>
        <v-dialog v-model="adModalProcesando" max-width="300">
            <Procesando/>
        </v-dialog>
    </v-row>
</template>
<script>
    export default {        
        data(){
            return {                
                headers: [
                    { text: 'Opciones', value: 'opciones', sortable: false,class: 'my-header-style' },
                    { text: 'Fecha Fra.', value: 'fecha', sortable: true,class: 'my-header-style' },
                    { text: 'Número Fra.', value: 'numero', sortable: true,class: 'my-header-style' },
                    { text: 'CIF', value: 'cif', sortable: true,class: 'my-header-style' },
                    { text: 'Proveedor', value: 'proveedor', sortable: true,class: 'my-header-style' },
                    { text: 'Importe', value: 'importe', sortable: true,class: 'my-header-style' },
                    { text: 'Pagado', value: 'idapuntescontables', sortable: true,class: 'my-header-style' },
                    { text: 'Fecha Valor', value: 'fvalor', sortable: true,class: 'my-header-style' },
                    { text: 'Subvenc.', value: 'subvencionable', sortable: true,class: 'my-header-style' },                                      
                ],
                items:[],
                origenTabla:[],
                gruposcontables:[],
                conceptos:[],
                años:[],
                conceptosArray:[],                
                regAnt:{},            
                adModalNuevoEditar: 0,
                adModalEliminar:'',                
                adModalPreguntaConta:'',
                adModalContabilizar:0,                
                adModalSubidaIncorrecta:0,
                adModalArchivoEliminar:0,
                adModalProcesando:0,
                search: '',                
                editedIndex: -1,
                regAct1:{idfacturas:'',numero:'',fecha:'',cif:'',proveedor:'',importe:'',idapuntescontables:null,
                    subvencionable:false,fichero:null,detalle:''},
                regAct2:{fvalor:'',idgruposcontables:'',idconceptos:null},
                SelAño:'',
                valida: 0,
                validaMensaje:[],                
                chosenFile: null,
                ADobjeto:'Factura',
                ADobjetos:'Facturas',
                opcionesTabla:{Seleccion:false, AbrirCerrar:false, ActivarBloquear:false, Visualizar:true, Modificar:true, Eliminar:true, Historico:false, Recibos:false,
		Contabilizar:true, Descargar:true},
                camposEditables:3,
                ADnombre:'',
                contabilizando: false,
            }
        },
        computed: {
            formTitle () {
                switch (this.editedIndex) {
                    case -1:
                        return 'Nueva Factura';
                        break;
                    case 0:
                        return 'Visualizar Factura';
                        break;
                    case 1:
                        return 'Modificar Factura';
                        break;
                }
            }                      
        },
        watch: {
            SelAño(val) {
                let me=this;
                me.adModalProcesando=1;
                me.listarObjetos();
            }  
        },
        created() {
            let me=this;
            me.adModalProcesando=1;                        
            me.listarAños();
            me.listarGruposContables();
            me.listarConceptos();            
        },
        methods:{
            activarWatcher(){
                let me=this;
                me.$watch(
                "$refs.cabecera.search",
                (new_value, old_value) => (me.search = new_value)
                    );
            },           
            listarObjetos(){
                let me=this;
                me.$http.get('api/Facturas/ListarPorAno/'+this.SelAño).then(function(response){
                    me.items=response.data;
                    me.preparaTabla();
                    me.adModalProcesando=0;
                }).catch(function(error){
                    console.log(error);
                });
            },
            preparaTabla(){
                let me=this;
                me.origenTabla=[];
                me.items.map(function(x){
                    me.origenTabla.push({idfacturas: x.idfacturas,fecha: me.$fechaCorta(x.fecha,2), numero: x.numero, cif:x.cif, proveedor:x.proveedor, importe:x.importe, pagado: x.fvalor ? 'SI':'NO', fvalor: me.$fechaCorta(x.fvalor,2), subvencionable: x.subvencionable ? 'SI':'NO',fichero:x.fichero,idapuntescontables:x.idapuntescontables});                    
                })                
            },
            listarAños(){
                let me=this;
                var añosArray=[];
                me.años=[]; 
                me.$http.get('api/Facturas/ListarAños').then(function(response){
                    añosArray=response.data;
                    if (añosArray.length) {
                        añosArray.map(function(x){
                        me.años.push({text: x.año,value:x.año});
                        });
                        /* if (!me.SelAño || me.SelAño<añosArray[0].año || me.SelAño>añosArray[0].año) me.SelAño=añosArray[0].año; */
                        if (me.SelAño=='') me.SelAño=añosArray[0].año;                         
                        me.listarObjetos();
                    }
                    else
                    { 
                        me.listarObjetos();
                    }                   
                }).catch(function(error){
                    console.log(error);
                });
            },                     
            editarObjeto(id) {
                let me=this;
                const item=me.items.find(obj => {return Object.values(obj)[0] === id});
                delete item.fvalor;
                delete item.idconceptos;
                delete item.idgruposcontables;
                me.regAct1=Object.assign({},item);                
                me.regAnt=Object.assign({},item);
                me.regAnt.fecha=me.$fechaLarga(me.regAnt.fecha);
                me.regAct1.fecha=me.$fechaCorta(me.regAct1.fecha,1);
                me.editedIndex=1;
                me.adModalNuevoEditar = 1
            },
            visualizarObjeto(id) {
                let me=this;                
                const item=me.items.find(obj => {return Object.values(obj)[0] === id});
                me.regAct1=Object.assign({},item);
                me.regAct1.fecha=me.$fechaCorta(me.regAct1.fecha,1);
                me.editedIndex=0;
                me.adModalNuevoEditar = 1
            },            
            contabilizarFactura(id){
                let me=this;
                const item=me.items.find(obj => {return Object.values(obj)[0] === id});
                me.regAct1=Object.assign({},item);
                me.mostrarContabilizar();                
            },            
            continuarFactura(){
                let me=this;                             
                if (me.validarFormFactura()) return;
                if (me.editedIndex==-1) {
                    me.adModalNuevoEditar = 0;
                    me.adModalPreguntaConta=1;
                }
                else {
                    me.guardarFactura();
                }
            },
            guardarAsiento(regAct2){
                let me=this;                
                me.adModalProcesando=1;                
                me.adModalContabilizar=0;
                me.adModalPreguntaConta=0;
                regAct2=me.eliminarProps(regAct2);                                
                regAct2.detalle='Pago factura '+me.regAct1.numero+' de '+me.regAct1.proveedor;
                regAct2.importe=-me.regAct1.importe;
                regAct2.eliminable=false;
                me.$http.post('api/ApuntesContables/Crear',regAct2).then(function(response){
                    me.regAct1.idapuntescontables=response.data;
                    me.contabilizando=true;                                       
                    me.guardarFactura();
                }).catch(function(error){
                    console.log(error);
                });    
            },
            actualizarAsiento(){
                let me=this;
                me.$http.put('api/ApuntesContables/ActualizarDeFactura',{                        
                        'idapuntescontables': me.regAct1.idapuntescontables,
                        'detalle':'Pago factura '+me.regAct1.numero+' de '+me.regAct1.proveedor,
                        'importe':-me.regAct1.importe,                       
                    }).then(function(response){             
                    }).catch(function(error){
                        console.log(error);
                    });                
            },
            listarGruposContables(){
                let me=this;
                var gruposcontablesArray=[]; 
                me.$http.get('api/GruposContables/GrupoContablePago').then(function(response){
                    gruposcontablesArray=response.data;
                    gruposcontablesArray.map(function(x){
                    me.gruposcontables.push({text: x.descripcion,value:x.idgruposcontables});
                    }); 
                }).catch(function(error){
                    console.log(error);
                });
            },
            listarConceptos(){
                let me=this;
                me.$http.get('api/Conceptos/Listar').then(function(response){
                    me.conceptosArray=response.data;
                    me.conceptos.push({text:'',value:null});
                    me.conceptosArray.map(function(x){
                    me.conceptos.push({text: x.descripcion,value:x.idconceptos});
                    }); 
                }).catch(function(error){
                    console.log(error);
                });
            },            
            eliminarObjeto() {
                let me=this;
                me.adModalProcesando=1;
                me.adModalEliminar=0;
                me.$http.put('api/Facturas/Eliminar/'+me.regAct1.idfacturas,{}).then(function(response){
                    if (me.regAct1.fichero) me.eliminarArchivo(me.regAct1.fichero);
                    me.borrarVariables();
                    me.listarAños();                           
                }).catch(function(error){
                    console.log(error);
                });                                
            },
            ocultarFormEditaNuevo() {
                let me=this;
                me.adModalNuevoEditar = 0;
                me.borrarVariables();
                me.listarObjetos();
            },
            borrarVariables(){                
                let me=this;
                me.regAct1={idfacturas:'',numero:'',fecha:'',cif:'',proveedor:'',importe:'',idapuntescontables:null,
                    subvencionable:false,fichero:null,detalle:''};                
                me.regAct2={idapuntescontables:'',
                    fvalor:{
                        tipo:'date',etiqueta:'Fecha Valor',valor:'',maxlength: 10,required: true
                    },                    
                    idgruposcontables:{
                        tipo:'sel',opciones:me.gruposcontables,etiqueta:'Grupo Contable',valor:'',maxlength:48,required: true
                    },
                    idconceptos:{
                        tipo:'sel',opciones:me.conceptos,etiqueta:'Actividad',valor:null,maxlength:40,required: false
                    }
                };                
                me.editedIndex=-1;
                me.file= null;
                if (me.chosenFile) me.$refs.fileupload.reset();
                me.validaMensaje=[];
                me.valida=0;
                me.contabilizando=false;
            },
            guardarFactura(){
                let me=this;
                me.adModalProcesando=1;
                me.adModalNuevoEditar = 0;
                me.adModalPreguntaConta=0;
                /* eliminar props */
                const regTmp=Object.assign({},me.regAct1);/* Creamos un objeto temporal para este metodo, que no afecte al exterior */                
                regTmp.fecha=me.$fechaLarga(regTmp.fecha);
                if (me.editedIndex > -1) {
                    //Código para editar
                        if (me.$hasChanged(regTmp,me.regAnt)){                                                           
                                me.$http.put('api/Facturas/Actualizar',regTmp).then(function(response){                                
                                if(me.chosenFile) {
                                    me.guardarArchivo();
                                }
                                me.listarAños();                                
                                if (me.regAct1.idapuntescontables && !me.contabilizando) me.actualizarAsiento();
                                me.borrarVariables();
                                me.adModalProcesando=0;
                            }).catch(function(error){
                                console.log(error);
                            });
                        }
                        else {
                            me.borrarVariables();
                            me.adModalProcesando=0;
                        }
                } else {
                    //Código para guardar                             
                    me.$http.post('api/Facturas/Crear',regTmp).then(function(response){  
                        me.regAct1.idfacturas=response.data;                     
                        if (me.SelAño=='') {
                            me.listarAños();
                        }                        
                        if(me.chosenFile) {
                            me.guardarArchivo();
                        }                        
                        me.listarAños();
                        me.borrarVariables();
                        me.adModalProcesando=0;                                                
                    }).catch(function(error){
                        console.log(error);
                    });                    
                }                                
            },
            validarFormFactura(){   
                let me=this;             
                me.valida=0;
                me.validaMensaje=[];
                if (!me.regAct1.fecha){
                    me.validaMensaje.push("Debes indicar la fecha de factura");
                }
                if (!me.regAct1.proveedor){
                    me.validaMensaje.push("Debes indicar el nombre del Proveedor");
                }
                if (!me.regAct1.importe){
                    me.validaMensaje.push("Debes indicar un importe");
                }                             
                if (me.validaMensaje.length){
                    me.valida=1;
                }
                return me.valida;
            },           
            validarArchivo: function(files) {
                let me=this;
                if (!files) return 0;
                me.validaMensaje=[];
                me.valida=0;
                var fileSize = Math.round((files.size / 1024 / 1024) * 100) / 100;
                if (fileSize > 1) {
                    me.validaMensaje.push("El archivo seleccionado es demasiado grande (>1 MB)");                   
                    me.valida=1;
                    me.$refs.fileupload.reset()
                }
                return me.valida;
            },           
            mostrarEliminar(id){
                let me=this;
                const item=me.items.find(obj => {return Object.values(obj)[0] === id});                                
                me.regAct1.idfacturas=item.idfacturas;
                me.regAct1.fichero=item.fichero;
                me.ADnombre=' de ' + item.proveedor + ' de fecha ' + me.$fechaCorta(item.fecha,2);
                me.adModalEliminar=1;            
            },
            descargarArchivo(id){
                let me=this;
                const item=me.items.find(obj => {return obj.idfacturas === id});
                var nombre=item.fichero; 
                me.$http.get('api/Facturas/FileDownload/'+nombre,{ responseType: 'blob' }).then(function(response){                    
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', nombre);
                    document.body.appendChild(link);
                    link.click();
                }).catch(function(error){
                    console.log(error);
                });
            },
            eliminarArchivo(nombre){
                let me=this;
                me.adModalArchivoEliminar=0;
                me.$http.put('api/Facturas/FileDelete/'+nombre,{}).then(function(response){
                    me.file= null;
                    me.regAct1.fichero=null;
                    if (me.editedIndex>-1) me.$refs.fileupload.reset();
                    me.listarObjetos();                           
                }).catch(function(error){
                    console.log(error);
                });                 
            },
            guardarArchivo(){
                let me=this;
                var fichero=me.chosenFile;              
                let formData = new FormData();
                var re = /(?:\.([^.]+))?$/;
                var extFichero = re.exec(fichero.name)[1];   // "txt"
                var nombreFichero="factura_"+me.regAct1.idfacturas+"."+extFichero;
                formData.append('file', fichero,nombreFichero);
                me.$http.post('api/Facturas/FileUpload',
                    formData,
                    {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
                ).then(function(){
                    me.listarObjetos();
                })
                .catch(function(){
                    me.adModalSubidaIncorrecta=1;
                });
            },
            mostrarContabilizar(){
                let me=this;
                if (me.adModalPreguntaConta==0) {
                    me.editedIndex=1;
                }
                else {
                    me.adModalPreguntaConta=0;
                }                
                me.regAct2={idapuntescontables:'',
                    fvalor:{
                        tipo:'date',etiqueta:'Fecha Valor',valor:'',maxlength: 10,required: true
                    },                    
                    idgruposcontables:{
                        tipo:'sel',opciones:me.gruposcontables,etiqueta:'Grupo Contable',valor:'',maxlength:48,required: true
                    },
                    idconceptos:{
                        tipo:'sel',opciones:me.conceptos,etiqueta:'Actividad',valor:null,maxlength:40,required: false
                    }
                };
                me.adModalContabilizar=1;
            },
            eliminarProps(miObjeto){
                let me=this;              
                Object.defineProperties(miObjeto, { 
                    "fvalor": {
                        value: me.$fechaLarga(miObjeto.fvalor.valor) 
                    },
                    "idgruposcontables": {
                        value: miObjeto.idgruposcontables.valor
                    },
                    "idconceptos": {
                        value: miObjeto.idconceptos.valor
                    },
                });
                return miObjeto;
            },
        }        
    }
</script>