<template>  
  <v-container fluid>
    <v-dialog v-model="formularioBloqueo" max-width="500" persistent>
        <v-card>            
            <v-card-title class="headline">¿Cerrar Concepto?</v-card-title>
            <v-card-text style="font-size:1em">                
                El Concepto <strong>{{descripcionConceptoActual}}</strong> tiene la propiedad <strong>autocierre</strong>
                y la fecha de inscripción (<strong>{{fechafinConceptoActual}}</strong>) vencida. 
                <br><br>
                ¿Deseas cerrarlo?
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="accent" text @click="AbrirCerrarCerrar">
                    NO cerrar
                </v-btn>               
                <v-btn color="accent"  @click="desactivarConcepto">
                    SI Cerrar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-slide-y-transition mode="out-in">
      <v-layout column align-center>
        <img src="@/assets/logo.png" alt="Vuetify.js" class="mb-5">
        <img src="@/assets/FONDO3.jpg" alt="Vuetify.js" class="mb-5">
        
        <h1>{{$store.state.ent_nombre}}</h1>   <!-- ****************************** -->
      </v-layout>
    </v-slide-y-transition>
  </v-container>
</template>
<script>
    import axios from 'axios'/* oso garrantzitsua */
    export default {
        data(){
            return {
                conceptos:[], 
                idConceptoActual: '',
                descripcionConceptoActual: '',
                fechafinConceptoActual: '',                
                formularioBloqueo: 0,
                preguntas:0,
                preguntado:false                                          
            }
        },
        computed: {
            tipoSuscripcion() {
                let me=this;
                var tipo=null;
                switch (me.$store.state.ent_idtiposuscripcion){
                case 1:
                    tipo="Versión Gratuita"
                    break;
                case 2:
                    tipo="Versión Estandar"
                    break;
                case 3:
                    tipo="Versión Avanzada"
                    break;
                case 4:
                    tipo="Versión Personalizada"
                    break;
            }
                return tipo
            },
        },
        created(){
            let me=this;
            axios.defaults.headers.common['Authorization'] = "Bearer " + me.$store.state.token; /* oso garrantzitsua */
            /* axios.defaults.headers.common['tenant'] = 1; */
            if (me.$store.state.ent_identidades == null) me.cargarDatosClub(); 
            if (me.$store.state.usuario.rol=="Presidente") me.listarConceptosParaCierre();            
        },
        methods:{   
            cargarDatosClub(){
            let me=this;
            me.$http.get('api/Entidades/Select/'+me.$store.state.usuario.identidades, {}).then((response) =>{
                    var entidad=response.data;
                    me.$store.state.ent_identidades=entidad[0].identidades;
                    me.$store.state.ent_nombre=entidad[0].nombre;
                    me.$store.state.ent_domicilio=entidad[0].domicilio;
                    me.$store.state.ent_cif=entidad[0].cif;
                    me.$store.state.ent_iban=entidad[0].iban;
                    me.$store.state.ent_idtiposuscripcion=entidad[0].idtiposuscripcion;
                    me.$store.state.ent_email=entidad[0].email;
                }).catch(function(error){
                    console.log(error);
                });                       
            },
            listarConceptosParaCierre(){                
                let me=this;
                me.$http.get('api/Conceptos/ListarParaCierre').then(function(response){
                   me.conceptos=response.data;
                   if (me.preguntas==0 & !me.preguntado){
                     me.preguntas=me.conceptos.length;
                   }                                      
                   if (me.preguntas>0){                     
                     me.idConceptoActual=me.conceptos[me.preguntas-1]["idconceptos"];                     
                     me.descripcionConceptoActual=me.conceptos[me.preguntas-1]["descripcion"];
                     me.fechafinConceptoActual=me.$fechaCorta(me.conceptos[me.preguntas-1]["fechafin"],2);                     
                     me.formularioBloqueo=1;
                     me.preguntas--;
                     if (me.preguntas==0) me.preguntado=true;
                   }
                }).catch(function(error){
                    console.log(error);
                });
            },            
            borrarVariables(){
                let me=this;
                me.descripcionConceptoActual="";
                me.idConceptoActual="";
                me.fechafinConceptoActual="";
            },
            AbrirCerrarCerrar(){
                let me=this;
                me.formularioBloqueo=0;
                me.listarConceptosParaCierre();
            },            
            desactivarConcepto(){
                let me=this;
                me.$http.put('api/Conceptos/Desactivar/'+me.idConceptoActual,{}).then(function(response){
                    me.formularioBloqueo=0;
                    me.listarConceptosParaCierre();                       
                }).catch(function(error){
                    console.log(error);
                });
            },                           
        }        
    }
</script>