<template>
  <v-row align-start>
    <v-dialog v-model="adModalProcesando" max-width="300" persistent>
        <Procesando/> 
    </v-dialog>  
    <v-dialog v-model="adModalPregunta1" max-width="500" persistent>
        <ModalTipo1                
            :titulo="'Cuota anual ' + adAñoActual"
            :mensaje="'Con este proceso se creará la actividad <strong>Cuota anual ' + adAñoActual + 
                '</strong> y los recibos a tod@s l@s soci@s del Club para este ejercicio. <br><br>¿Deseas continuar?'"
            v-bind:aceptarCancelar="true"
            v-on:cancelarClick="cancelarAccion"
            v-on:aceptarClick="pedirPrecio"    
        />
    </v-dialog>
    <v-dialog v-model="adModalPregunta2" max-width="500">
        <ModalTipo1                
                :titulo="'Cuota anual ' + adAñoActual"
                :mensaje="'Existen <strong>' + pendientesCuotas + '</strong> cuotas pendientes de generar. <br><br> ¿Deseas generar los recibos?'"
                v-bind:aceptarCancelar="true"
                v-on:cancelarClick="cancelarAccion"
                v-on:aceptarClick="generarPendientes"    
            />
    </v-dialog>
    <v-dialog v-model="adModalPregunta3" max-width="500">
        <ModalTipo2                
            :titulo="'Cuota anual ' + adAñoActual"
            mensaje="¿Cual es el precio de la Cuota fijado para este año?"
            tipoModal="Cuota"
            icono="coins"
            v-on:cancelarClick="cancelarAccion"
            v-on:aceptarClick="crearConcepto"    
        />
    </v-dialog>
    <v-dialog v-model="adModalInforma1" max-width="500">
        <ModalTipo1
            :titulo="'Cuota anual ' + adAñoActual"
            mensaje="No existen recibos pendientes de generar para este concepto"                        
            v-on:cerrarModal="cancelarAccion"          
        />
    </v-dialog>
    <v-dialog v-model="adModalInforma2" max-width="500">        
        <ModalTipo1
            :titulo="'Cuota anual ' + adAñoActual"
            mensaje="Todos los recibos se han generado correctamente"                        
            v-on:cerrarModal="cancelarAccion"                
        />
      </v-dialog>      
    </v-row>      
</template>
<script>    
    export default {
        name: "app-tag",        
    data() {
        return {
        adModalProcesando:0,
        adModalPregunta1:0,
        adModalPregunta2:0,
        adModalPregunta3:0,
        adModalInforma1:0,
        adModalInforma2:0,
        actividadAct:'',
        pendientesCuotas:0,
        precioAct:10,
        }
    },       
    computed: {
        adAñoActual() {
            return new Date().getFullYear(); 
        },              
    },
    created(){            
        let me=this;
        me.existeConcepto();
        },
    methods:{        
        existeConcepto(){
            let me=this;
            me.$http.get('api/Conceptos/ExisteConceptoCuota/'+me.adAñoActual,{}).then(function(response){
                if(response.status==200) {              
                if (response.data.length==0) {
                me.adModalPregunta1=1;
                }
                else {
                me.actividadAct=response.data[0].idconceptos;
                me.existenPendientes();
                };
            }            
        }).catch(function(error){
            console.log(error);
            });          
        },
        existenPendientes() {
            let me=this;       
            me.$http.get('api/Inscripciones/ExistenCuotasPendientes/'+me.actividadAct).then(function(response){
            me.pendientesCuotas=response.data;
            if (me.pendientesCuotas>0) {
                me.adModalProcesando=0;
                me.adModalPregunta2=1;
            }
            else {
                me.adModalInforma1=1;
            };
            }).catch(function(error){
                console.log(error);
            });          
        },             
        cancelarAccion(){
            let me=this;
            me.adModalPregunta1=0;
            me.adModalPregunta2=0;
            me.adModalInforma1=0;
            me.adModalInforma2=0;
            me.$router.push({ name: 'home' });
        },
        pedirPrecio(){
            let me=this;
            me.adModalPregunta1=0;
            me.adModalPregunta3=1;          
        },   
        crearConcepto(precio) {
            let me=this;
            me.adModalProcesando=1;
            me.$http.post('api/Conceptos/Crear',{
                'descripcion': "CUOTA ANUAL "+me.adAñoActual,
                'idtipoconcepto': 1,
                'año':me.adAñoActual,
                'gratuito': false,
                'precio': precio,
                'fechafin':me.$fechaLarga(me.adAñoActual+"/12/31"),
                'fechaevento':me.$fechaLarga(me.adAñoActual+"/01/01"),
                'autocierre':true,
                'idgruposcontables':1,
            }).then(function(response){
                me.adModalPregunta3=0;                
                me.existeConcepto();                     
            }).catch(function(error){
                console.log(error);
            });
        },
        generarPendientes() {
            let me=this;
            me.adModalProcesando=1;
            me.$http.post('api/Inscripciones/GenerarCuotas/'+me.actividadAct+'/'+me.$store.state.usuario.nombre).then(function(response){
                me.adModalPregunta1=0;
                me.adModalPregunta2=0;
                me.adModalProcesando=0;
                me.adModalInforma2=1;
            }).catch(function(error){
                console.log(error);
            });
        }                       
    }
  }
</script>