<template>
    <v-row align-start>
        <v-col>
            <Cabecera
                v-bind:objeto=ADobjetos
                :ocultaNuevo='true'
                :ocultaBusqueda='true'                         
            />
            <br><br>
            <v-btn                               
                @click="DescargarLogActual()"
                >
                Download Log                                
            </v-btn>
            <br><br>
            <v-btn                              
                @click="EncriptarSocios()"
                >
                Encriptar tabla Socios                                
            </v-btn>
            <br><br>
            <v-btn                              
                @click="EncriptarRecibos()"
                >
                Encriptar tabla Recibos                                
            </v-btn>
            <br><br>
            <v-btn                              
                @click="DesencriptarSocios()"
                >
                Desencriptar tabla Socios                                
            </v-btn>
            <br><br>
            <v-btn                              
                @click="DesncriptarRecibos()"
                >
                Desencriptar tabla Recibos                                
            </v-btn>
        </v-col>
    </v-row>
</template>
<script>    
    export default {
        data(){
            return {
                loading3: false,
                ADobjetos:'Sistema',                
            }
        },
        watch: {
            loader (){
            const l = this.loader
            this[l] = !this[l]
            setTimeout(() => (this[l] = false), 3000)
            this.loader = null
            }
        },
        methods:{
            DescargarLogActual(){
                let me=this;
                var contenido;
                me.$http.get('api/Sistema/DescargarLog').then(function(response){                    
                    contenido=response.data;
                    var element = document.createElement('a');
                    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(contenido));
                    element.setAttribute('download', "ultimoLog.txt");
                    element.style.display = 'none';
                    document.body.appendChild(element);
                    element.click();
                    document.body.removeChild(element);
                }).catch(function(error){
                    console.log(error);
                });
            },   
            EncriptarSocios(){
                let me=this;
                me.$http.put('api/Sistema/EncriptarSocios').then(function(response){                    
                });
            },
            EncriptarRecibos(){
                let me=this;
                me.$http.put('api/Sistema/EncriptarRecibos').then(function(response){                    
                });
            },
            DesencriptarSocios(){
                let me=this;
                me.$http.put('api/Sistema/DesencriptarSocios').then(function(response){                    
                });
            },
            DesencriptarRecibos(){
                let me=this;
                me.$http.put('api/Sistema/DesencriptarRecibos').then(function(response){                    
                });
            },                       
        }        
    }
</script>