<template>
    <v-container fill-height fluid>
    <v-row align="center" justify="center">
        <v-col cols="5">            
            <v-card>
                <v-toolbar dark color="primary">
                    <v-toolbar-title>
                        Acceso al Sistema
                    </v-toolbar-title>
                </v-toolbar>
                <v-card-title> El nombre de usuario o contraseña no son correctos
                </v-card-title>
                <v-card-actions>
                    <v-btn @click="dialog=true" color="accent" text>Regenerar Contraseña</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="$router.push({ name: 'login' })" color="accent">volver</v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
    <v-dialog v-model="dialog" max-width="600px" persistent>
        <v-card>
            <v-toolbar dark color="primary">
                <v-toolbar-title>
                    Regenerar contraseña
                </v-toolbar-title>
            </v-toolbar>
            <v-card-title>
            Introduce tu nombre de usuario y recibiras un correo con las instrucciones para regenerar tu contraseña
            </v-card-title>
            <v-container grid-list-md>
                <v-row wrap>
                    <v-col xs6 sm6 md6>
                        <v-text-field v-model="usuario" autofocus maxLength="50" clearable label="Nombre de usuario">
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-container>
            <v-card-actions>
                <v-spacer></v-spacer>                                
                <v-btn color="accent" text @click.native="dialog=false">Cancelar</v-btn>
                <v-btn color="accent" @click.native="regenerar">Aceptar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>    
    </v-container>
</template>
<script>
export default {
    data(){
        return {
            usuario: '',
            password: '',
            error: null,
            dialog:false,            
        }
    },    
    methods :{
        regenerar(){
            let me=this;
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!me.usuario){
                me.$router.push({ name: 'login' }).catch(() => {});;
            } else if(re.test(me.usuario)){
                me.$http.put('api/Usuarios/Regenerar/'+me.usuario)
                .then(function(response){                
                }).catch(function(error){
                    console.log(error);
                });                
            }
            me.$router.push({ name: 'login' }).catch(() => {});;
        }
    }    
}
</script>