<template>
    <v-container fluid>
        <v-row align-start>
            <v-col>
                <Cabecera
                    v-bind:objeto=ADobjetos                                  
                    v-on:Nuevo="nuevoObjeto"
                    @hook:mounted="activarWatcher"
                    ref="cabecera"                             
                />
                <br>
                <TablaDatos
                    v-bind:opcionesTabla=opcionesTabla                    
                    v-bind:items=origenTabla
                    v-bind:headers=headers
                    v-bind:search=search
                    v-on:mostrarActivarBloquear="mostrarActivarBloquear"
                    v-on:editarObjeto="editarObjeto"
                    v-on:mostrarEliminar="mostrarEliminar"
                    v-on:listarObjetos="listarObjetos"                    
                />
            </v-col>
        </v-row>
        <v-dialog v-model="adModalProcesando" max-width="300" persistent>
            <Procesando/> 
        </v-dialog>
        <v-dialog v-model="adModalTextoRepetido" max-width="500" persistent>            
            <ModalTipo1
                titulo="Valor Repetido"
                :mensaje="'El valor <strong>' + ADtexto + '</strong> del campo <strong>' + ADcampo + '</strong> está repetido. Ya existe ese valor para otro ' + ADobjeto"                        
                v-on:cerrarModal="adModalTextoRepetido=0"          
            />    
        </v-dialog>
        <v-dialog v-model="adModalActivarBloquear" max-width="500" persistent>
            <ModalTipo3                
                    v-bind:accion=ADaccion
                    v-bind:objeto=ADobjeto 
                    v-bind:nombre=ADnombre
                    v-on:cancelarClick="adModalActivarBloquear=0"
                    v-on:aceptarClick="cambiarEstadoObjeto"    
                />
        </v-dialog>
        <v-dialog v-model="adModalNuevoEditar" max-width="350px">
            <EditarNuevo
                v-if="adModalNuevoEditar"                    
                v-bind:formTitle=formTitle 
                v-bind:regAct=regAct
                v-bind:camposEditables=camposEditables 
                v-on:ocultarFormEditarNuevo="ocultarFormEditarNuevo"
                v-on:guardarObjeto="guardarObjeto"               
            />
        </v-dialog>
        <v-dialog v-model="adModalEliminar" max-width="500">
            <ModalTipo1                
                :titulo="'Eliminar ' + ADobjeto"
                :mensaje="'Estás a punto de eliminar ' + ADobjeto + ' <strong>' + 
                    ADnombre + '</strong> ¿Quieres continuar?'"
                v-bind:aceptarCancelar="true"
                v-on:cancelarClick="adModalEliminar=0"
                v-on:aceptarClick="eliminarObjeto"    
            />
        </v-dialog>
        <v-dialog v-model="adModalImposibleEliminar" max-width="500">
            <ModalTipo1
                :titulo="'Imposible eliminar ' + ADobjeto"
                :mensaje="'Existe(n) <strong>' + pedidos.length + '</strong> pedido(s) utilizando el dato <strong>' + ADnombre + '</strong>'"                        
                v-on:cerrarModal="adModalImposibleEliminar=0"          
            />
        </v-dialog>
    </v-container>
</template>
<script>    
    export default {
        data(){
            return {
                items:[],            
                adModalNuevoEditar: 0,
                adModalEliminar:0,
                adModalImposibleEliminar:'',
                adModalActivarBloquear: 0,
                adModalTextoRepetido:0,
                adModalProcesando:0,
                headers: [
                    { text: 'Opciones', value: 'opciones', sortable: false,class: 'my-header-style' },
                    { text: 'Descripción', value: 'descripcion', sortable: true,class: 'my-header-style' },
                    { text: 'Sistema Talla', value: 'sistematalla', sortable: true,class: 'my-header-style' },
                    { text: 'Precio de Venta', value: 'preciov', sortable: true,class: 'my-header-style' },
                    { text: 'Precio de Compra', value: 'precioc', sortable: true,class: 'my-header-style' },
                    { text: 'Estado', value: 'bloqueado', sortable: true,class: 'my-header-style' },
                ],
                search: '',
                pedidos:[],
                sistematallas:[],
                origenTabla:[],
                editedIndex: -1,
                regAct:{idarticulos:'',
                    descripcion:null,preciov:null,precioc:null,idsistematalla:null},
                regAnt:{},
                camposEditables:4,
                ADaccion: '',                
                ADnombre: '',
                ADcampo:'',
                ADobjeto:'Artículo',
                ADobjetos:'Artículos',
                ADtexto:'',                                                
                opcionesTabla:{Seleccion:false, AbrirCerrar:false, ActivarBloquear:true, Visualizar:false, Modificar:true, Eliminar:true, Historico:false, Recibos:false,
		Contabilizar:false, Descargar:false},
            }
        },
        computed: {
            formTitle () {
                let me=this;
                return me.editedIndex === -1 ? 'Nuevo artículo' : 'Actualizar artículo'
            }
        },
        created(){
            let me=this;
            me.adModalProcesando=1;           
            me.listarObjetos();
            me.cargarSistemaTallas();
        },
        methods:{            
            activarWatcher(){
                let me=this;
                me.$watch(
                "$refs.cabecera.search",
                (new_value, old_value) => (me.search = new_value)
                    );
            },
            listarObjetos(){
                let me=this;
                me.$http.get('api/Articulos/Listar').then(function(response){
                    me.items=response.data;
                     me.preparaTabla();
                     me.adModalProcesando=0;
                   me.adModalProcesando=0;
                }).catch(function(error){
                    console.log(error);
                });
            },
            preparaTabla(){
                let me=this;
                me.origenTabla=[];
                me.items.map(function(x){
                    me.origenTabla.push({idarticulos: x.idarticulos,descripcion: x.descripcion, sistematalla: x.sistematalla, preciov:new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(x.preciov), precioc:new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(x.precioc),bloqueado:x.bloqueado});
                })                
            },            
            editarObjeto (id){
                let me=this;
                const item=me.items.find(obj => {return Object.values(obj)[0] === id});
                me.regAct=Object.assign({},item);
                me.regAnt=Object.assign({},item);
                me.añadirProps();                                   
                me.editedIndex=1;
                me.adModalNuevoEditar = 1
            },
            nuevoObjeto() {
                let me=this;                                              
                me.añadirProps();
                me.adModalNuevoEditar = 1
            },
            añadirProps(){
                let me=this;
                me.regAct= {idarticulos:me.regAct.idarticulos,
                    descripcion: {
                        tipo:'text',etiqueta:'Descripcion',valor:me.regAct.descripcion,maxlength: 30,required: true
                    },
                    preciov:{
                        tipo:'money',etiqueta:'P. Venta',valor:me.regAct.preciov,maxlength: 3,required: true
                    },
                    precioc:{
                        tipo:'money',etiqueta:'P. Compra',valor:me.regAct.precioc,maxlength: 3,required: true
                    },
                    idsistematalla: {
                        tipo:'sel',opciones:me.sistematallas,etiqueta:'Sistema Talla',valor:me.regAct.idsistematalla,maxlength:10,required: true
                    },
                    bloqueado:me.regAct.bloqueado
                    };
            },
            eliminarProps(miObjeto){
                let me=this;              
                Object.defineProperties(miObjeto, { 
                    "descripcion": {
                        value: miObjeto.descripcion.valor
                    },
                    "preciov": {
                        value: miObjeto.preciov.valor
                    },
                    "precioc": {
                        value: miObjeto.precioc.valor
                    },
                    "idsistematalla": {
                        value: miObjeto.idsistematalla.valor
                    },
                    "bloqueado": {
                        value: miObjeto.bloqueado
                    },
                });
                return miObjeto;
            },
            cargarPedidos(){
                let me=this;
                    me.$http.get('api/LineasPedidos/Filtrar_por_articulo/'+me.regAct.idarticulos,{}).then((response) =>{
                        me.pedidos=response.data;                                          
                    }).catch(function(error){
                        console.log(error);
                    });
            },
            cargarSistemaTallas(){
                let me=this;
                var sistemaArray=[];
                me.$http.get('api/SistemaTalla/Listar').then((response) =>{
                    sistemaArray=response.data;
                    sistemaArray.map(function(x){
                    me.sistematallas.push({text: x.descripcion,value:x.idsistematalla});                                  
                });                                             
                }).catch(function(error){
                    console.log(error);
                });
            },           
            eliminarObjeto() {
                let me=this;
                me.adModalProcesando=1;                                                  
                if (me.pedidos.length==0) {                
                        //Código para borrar
                        me.$http.put('api/Articulos/Eliminar/'+me.regAct.idarticulos,{}).then(function(response){                       
                            me.adModalEliminar=0;   
                            me.borrarVariables();
                            me.listarObjetos();                           
                        }).catch(function(error){
                            console.log(error);
                        });
                }
                else {
                    me.adModalEliminar=0;
                    me.adModalProcesando=0;
                    me.adModalImposibleEliminar=1;
                }
            },
            ocultarFormEditarNuevo () {
                let me=this;
                me.adModalNuevoEditar = 0;
                me.borrarVariables();
            },
            borrarVariables(){
                let me=this;
                me.regAct={idarticulos:null,descripcion:null,preciov:null,precioc:null,idsistematalla:null},                                            
                me.editedIndex=-1;
                me.ADtexto='';
                me.ADcampo='',
                me.ADnombre='',
                me.pedidos=[];                
            },
            guardarObjeto(regAct) {
                let me=this;
                me.adModalProcesando=1;
                me.adModalNuevoEditar = 0;
                regAct=me.eliminarProps(regAct);                
                const regTmp=Object.assign({},me.regAct);/* Creamos un objeto temporal para este metodo, que no afecte al exterior */
                if (me.encuentraRepetición('idarticulos','descripcion',regTmp.descripcion)==1) {/* Buscamos repeticiones */
                    me.ADtexto=regTmp.descripcion;
                    me.ADcampo='Descripción';
                    me.adModalProcesando=0;
                    me.adModalTextoRepetido=1;
                    me.regAct=Object.assign({},regAct);
                    me.añadirProps();
                    me.adModalNuevoEditar = 1;
                    return; /* Avisamos de la incidencia y regresamos al formulario origen */                   
                };                
                
                if (me.editedIndex > -1) {
                    //Código para editar
                    if (me.$hasChanged(regTmp,me.regAnt)){
                        me.$http.put('api/Articulos/Actualizar',regTmp).then(function(response){
                            me.listarObjetos();
                            me.borrarVariables();
                            me.adModalNuevoEditar = 0;
                            me.adModalProcesando=0;                        
                        }).catch(function(error){
                            console.log(error);
                        });
                    }
                    else {
                        me.borrarVariables();
                        me.adModalProcesando=0;
                        me.adModalNuevoEditar = 0;
                    }                         
                } else {
                    //Código para guardar          
                    me.$http.post('api/Articulos/Crear',regTmp).then(function(response){
                        me.listarObjetos();
                        me.borrarVariables();
                        me.adModalNuevoEditar = 0;
                        me.adModalProcesando=0;                        
                    }).catch(function(error){
                        console.log(error);
                    });
                }
            },
            mostrarEliminar(id){
                let me=this;
                const item=me.items.find(obj => {return Object.values(obj)[0] === id});
                me.adModalEliminar=1;
                me.ADnombre=item.descripcion;
                me.regAct.idarticulos=item.idarticulos;
                me.regAct.idsistematalla=item.idsistematalla;
                me.cargarPedidos();
            },
            cambiarEstadoObjeto(){
                let me=this;
                me.adModalProcesando=1;
                if (me.ADaccion=="Activar"){
                    me.$http.put('api/Articulos/Activar/'+me.regAct.idarticulos,{}).then(function(response){
                    me.adModalActivarBloquear=0;
                    me.adModalEliminar=0;
                    me.ADaccion='';
                    me.ADnombre="";
                    me.listarObjetos();                       
                    }).catch(function(error){
                        console.log(error);
                    });
                }
                else {
                   me.$http.put('api/Articulos/Bloquear/'+me.regAct.idarticulos,{}).then(function(response){
                    me.adModalActivarBloquear=0;
                    me.adModalEliminar=0;
                    me.ADaccion='';
                    me.ADnombre="";
                    me.listarObjetos();                       
                    }).catch(function(error){
                        console.log(error);
                    });
                }
            },
            mostrarActivarBloquear(accion,id){ 
                let me=this;
                const item=me.items.find(obj => {return Object.values(obj)[0] === id});
                me.regAct.idarticulos=id;                                
                me.ADnombre=item.descripcion;
                me.ADaccion=accion==1 ? "Bloquear" : "Activar"      
                me.adModalActivarBloquear=1;
            },
            encuentraRepetición(id,campo,texto){
                let me=this;
                var sw=0;
                for(var i=0;i<me.items.length;i++){
                    if (me.items[i][campo].toUpperCase()==texto.toUpperCase() && me.items[i][id]!=me.regAct[id]){
                        sw=1;    
                    }
                }               
                return sw;
            }
        }        
    }
</script>