<template>
    <v-container fluid>
        <v-row align-start>
            <v-col>
                <Cabecera
                    v-bind:objeto=ADobjetos                                  
                    v-on:Nuevo="adModalNuevoEditar=1"
                    @hook:mounted="activarWatcher"
                    ref="cabecera"                             
                />
                <br>
                <TablaDatos
                    padre="Conceptos"
                    v-bind:opcionesTabla=opcionesTabla                    
                    v-bind:items=origenTabla
                    v-bind:headers=headers
                    v-bind:search=search
                    v-on:mostrarAbrirCerrar="mostrarAbrirCerrar"
                    v-on:editarObjeto="editarObjeto"
                    v-on:mostrarEliminar="mostrarEliminar"
                    v-on:listarObjetos="listarObjetos"                    
                />
            </v-col> 
        </v-row>
        <v-dialog v-model="adModalNuevoEditar" max-width="500px" persistent>                        
            <v-card>
                <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                </v-card-title>                
                <v-card-text>
                    <v-container grid-list-md>
                        <v-row wrap>
                            <v-col cols="10">
                                <v-text-field v-model="regAct.descripcion" label="Nombre" autofocus maxLength="35">                                        
                                </v-text-field>
                            </v-col>
                            <v-col cols="3"> 
                                <v-switch
                                    v-model="regAct.gratuito"
                                    :label="'Gratuito'"
                                    disabled
                                ></v-switch>
                            </v-col>
                            <v-col cols="3"  v-if="!regAct.gratuito">
                                <v-text-field disabled type="number" v-model="regAct.precio" prefix="€" reverse label="Precio">
                                </v-text-field>
                            </v-col>
                            <v-spacer></v-spacer>                                
                            <v-col cols="4">
                                <v-text-field label="Fecha fin solicitud" type="date" 
                                    v-model="regAct.fechafin"
                                ></v-text-field>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="3"> 
                                <v-switch
                                    v-model="regAct.autocierre"
                                    :label="'Autocierre'"
                                ></v-switch>
                            </v-col>
                            <v-col cols="9" v-if="editedIndex===1">
                                <v-select v-model="regAct.idgruposcontables"
                                dense
                                :items="GrupoContableCobro" label="Grupo Contable">                                    
                                </v-select>
                            </v-col>                            
                            <v-col cols="12" v-show="valida">
                                <div class="red--text" v-for="v in validaMensaje" :key="v" v-text="v">
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>                
                <v-card-actions class="justify-center">                                                    
                    <v-btn color="accent" text @click.native="ocultaradModalNuevoEditar">Cancelar</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="accent" @click.native="guardarConcepto">Guardar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="adModalAbrirCerrar" max-width="500" persistent>
            <ModalTipo3                
                v-bind:accion=ADaccion
                v-bind:objeto=ADobjeto 
                v-bind:nombre=ADnombre
                v-on:cancelarClick="adModalAbrirCerrar=0"
                v-on:aceptarClick="cambiarEstadoObjeto"    
            />
        </v-dialog>
        <v-dialog v-model="adModalProcesando" max-width="300" persistent>
            <Procesando/> 
        </v-dialog>
        <v-dialog v-model="adModalTextoRepetido" max-width="500" persistent>
            <ModalTipo1
                titulo="Valor Repetido"
                :mensaje="'El valor <strong>' + ADtexto + '</strong> del campo <strong>' + ADcampo + '</strong> está repetido. Ya existe ese valor para otro ' + ADobjeto"                        
                v-on:cerrarModal="adModalTextoRepetido=0"          
            />    
        </v-dialog>
        <v-dialog v-model="adModalEliminar" max-width="500" persistent>
            <ModalTipo1                
                :titulo="'Eliminar ' + ADobjeto"
                :mensaje="'Estás a punto de eliminar ' + ADobjeto + ' <strong>' + 
                    ADnombre + '</strong> ¿Quieres continuar?'"
                v-bind:aceptarCancelar="true"
                v-on:cancelarClick="adModalEliminar=0"
                v-on:aceptarClick="eliminarObjeto"    
            />    
        </v-dialog>                    
        <v-dialog v-model="adModalPregunta" max-width="500" persistent>            
            <ModalTipo1                
                titulo="Cambios importantes"
                :mensaje="'Has realizado cambios importantes en un(a) ' + ADobjeto + ' donde existe(n) <strong>' + 
                    regAct.inscritos + '</strong> inscripcion(es). ¿Quieres aplicar estos cambios en las inscripciones pendientes de pago?'"
                v-bind:aceptarCancelar="true"
                v-on:cancelarClick="ocultarPreguntaCambios"
                v-on:aceptarClick="aplicarCambios"    
            />
        </v-dialog>                   
    </v-container>
</template>
<script>
    export default {        
        data(){
            return {                
                headers: [
                    { text: 'Opciones', value: 'opciones', sortable: false,class: 'my-header-style' },
                    { text: 'Año', value: 'año', sortable: true,class: 'my-header-style' },
                    { text: 'Nombre', value: 'descripcion', sortable: true,class: 'my-header-style' },
                    { text: 'Tipo', value: 'tipoconcepto', sortable: false,class: 'my-header-style'  },                    
                    { text: 'Precio', value: 'precio', sortable: false, class: 'my-header-style'},                    
                    { text: 'Fecha fin solicitud', value: 'fechafin', sortable: true,class: 'my-header-style'},
                    { text: 'Solicitudes', value: 'inscritos', sortable: true, class: 'my-header-style'},
                    { text: 'Estado', value: 'cerrado', sortable: true,class: 'my-header-style'  }                
                ],
                items:[],
                GrupoContableCobro:[],
                origenTabla:[], 
                search: '',
                editedIndex: -1,
                adModalTextoRepetido:0,
                adModalAbrirCerrar: 0,
                adModalNuevoEditar: 0,
                adModalPregunta:0,                
                adModalEliminar:0,
                adModalProcesando:0,
                regAct:{idconceptos:'',
                    descripcion:[],idtipoconcepto:'',año:'',gratuito:true,precio:'',inscritos:0,fechafin:'',
                    fechaevento:'',autocierre:false,idgruposcontables:''},
                regAnt:{},
                precioAnt:'',
                idgruposcontablesAnt:'',              
                valida: 0,
                validaMensaje:[],                
                ADaccion: 0,                
                ADnombre:'',
                ADobjeto:'Temporada',
                ADobjetos:'Temporadas',
                ADcampo:'',
                ADtexto:'',
                opcionesTabla:{Seleccion:false, AbrirCerrar:true, ActivarBloquear:false, Visualizar:false, Modificar:true, Eliminar:true, Historico:false, Recibos:false,
		Contabilizar:false, Descargar:false},                           
            }
        },
        computed: {
            formTitle () {
                let me=this;
                return me.editedIndex === -1 ? 'Nueva Temporada' : 'Actualizar Temporada'
            }
        },        
        created () {
            let me=this;            
            me.listarObjetos();
            me.listarGrupoContableCobro();           
        },
        methods:{            
            activarWatcher(){
                let me=this;
			    me.$watch(
			    "$refs.cabecera.search",
			    (new_value, old_value) => (me.search = new_value)
                );
            },        
            listarObjetos(){
                let me=this;
                me.$http.get('api/Conceptos/ListarComplejo/2').then(function(response){
                   me.items=response.data;
                   me.preparaTabla();
                   me.adModalProcesando=0;                                                         
                }).catch(function(error){
                    console.log(error);
                });
            },
            preparaTabla(){
                let me=this;
                me.origenTabla=[];
                me.items.map(function(x){
                    me.origenTabla.push({idconceptos: x.idconceptos,año: x.año, descripcion: x.descripcion, tipoconcepto:x.tipoconcepto, precio:x.precio, fechafin: me.$fechaCorta(x.fechafin,2), inscritos: x.inscritos, cerrado: x.cerrado});
                })                
            },
            listarGrupoContableCobro(){
                let me=this;
                var GrupoContableCobroArray=[]; 
                me.$http.get('api/GruposContables/GrupoContableCobro').then(function(response){
                    GrupoContableCobroArray=response.data;
                    GrupoContableCobroArray.map(function(x){
                    me.GrupoContableCobro.push({text: x.descripcion,value:x.idgruposcontables});
                    });                    
                }).catch(function(error){
                    console.log(error);
                });
            },     
            editarObjeto(id) {
                let me=this;
                const item=me.items.find(obj => {return Object.values(obj)[0] === id});
                me.regAct=Object.assign({},item);                
                me.regAnt=Object.assign({},item);                
                me.regAct.fechafin=me.$fechaCorta(item.fechafin,1);
                /* me.regAct.fechaevento=me.$fechaCorta(item.fechaevento,1); */
                me.precioAnt=item.precio;                
                me.idgruposcontablesAnt=item.idgruposcontables;                
                me.editedIndex=1;
                me.adModalNuevoEditar = 1
            },
            ocultaradModalNuevoEditar() {
                let me=this;
                me.adModalNuevoEditar = 0;
                me.borrarVariables();
            },
            borrarVariables(){
                let me=this;
                me.regAct=Object.create({idconceptos:'',descripcion:[],idtipoconcepto:'',año:'',gratuito:true,
                    precio:'',inscritos:0,fechafin:'',fechaevento:'',autocierre:false,idgruposcontables:''});
                me.precioAnt="";
                me.idgruposcontablesAnt="";
                me.ADtexto='';
                me.ADcampo='';          
                me.editedIndex=-1;
            },
            guardarConcepto() {
                let me=this;
                me.adModalProcesando=1;
                const regTmp=Object.assign({},me.regAct);/* Creamos un objeto temporal para este metodo, que no afecte al exterior */
                if (me.encuentraRepetición('idconceptos','descripcion',regTmp.descripcion)==1) {/* Buscamos repeticiones */
                    me.ADtexto=regTmp.descripcion;
                    me.ADcampo='Descripción';
                    me.adModalProcesando=0;
                    me.adModalTextoRepetido=1;
                    return; /* Avisamos de la incidencia y regresamos al formulario origen */                   
                };                             
                if (me.validarFormulario()){
                    me.adModalProcesando=0;
                    return; /* Si no pasa la validación de campos regresamos al formulario */
                }
                if (me.editedIndex > -1) {                    
                    //Código para editar
                    var cambioSensible=0;
                    if (regTmp.inscritos>0 && (regTmp.precio!=me.precioAnt || regTmp.idgruposcontables!=me.idgruposcontablesAnt)) cambioSensible=1;
                    regTmp.año=me.$fechaLarga(regTmp.fechafin).getFullYear();
                    regTmp.fechafin=me.$fechaLarga(regTmp.fechafin);
                    regTmp.fechaevento=me.$fechaLarga(regTmp.año+"-12-31")// Último día del año actual
                    /* regTmp.fechaevento=me.$fechaLarga(regTmp.fechaevento); */
                    if (me.$hasChanged(regTmp,me.regAnt)){                        
                        me.$http.put('api/Conceptos/Actualizar',regTmp
                        ).then(function(response){
                            if (cambioSensible==1) {
                                me.adModalProcesando=0;                            
                                me.mostrarPreguntaCambios();}
                            else {                            
                                me.borrarVariables();
                                me.listarObjetos();
                                me.adModalNuevoEditar=0;
                                me.adModalProcesando=0;
                                }                          
                        }).catch(function(error){
                            console.log(error);
                        });
                    }
                    else {
                        me.borrarVariables();
                        me.adModalProcesando=0;
                        me.formEditarNuevo = 0;
                    }
                } else {
                    //Código para guardar
                    if (regTmp.gratuito) regTmp.precio=null;
                    regTmp.idgruposcontables=29; // El 29 corresponde al grupo contable de Licencia                   
                    regTmp.fechafin=me.$fechaLarga(regTmp.fechafin);
                    regTmp.año=regTmp.fechafin.getFullYear();
                    regTmp.fechaevento=me.$fechaLarga(regTmp.año+"-12-31")// Último día del año actual
                    regTmp.idtipoconcepto=2 // El 2 corresponde al tipo de concepto de Licencia                    
                    me.$http.post('api/Conceptos/Crear',regTmp).then(function(response){
                        me.listarObjetos();
                        me.borrarVariables();
                        me.adModalNuevoEditar = 0;
                        me.adModalProcesando=0;                        
                    }).catch(function(error){
                        console.log(JSON.parse(JSON.stringify(error)));
                    });
                }
            },            
            validarFormulario(){
                let me=this;                
                me.valida=0;
                me.validaMensaje=[];
                if (me.regAct.descripcion.length<3){
                    me.validaMensaje.push("El nombre debe tener más de 2 caracteres");
                }
                if (me.regAct.fechafin.length!=10 || parseInt(me.regAct.fechafin.substring(0,4))<me.$añomin || parseInt(me.regAct.fechafin.substring(0,4))>me.$añomax){
                    me.validaMensaje.push("La FECHA FIN DE LA INSCRIPCIÓN no es correcta");
                }
                if (me.regAct.precio<0 || me.regAct.precio>1000){
                    me.validaMensaje.push("El PRECIO tiene un valor incorrecto");
                }                
                if (me.validaMensaje.length){
                    me.valida=1;
                }
                return me.valida;
            },
            mostrarAbrirCerrar(accion,id){
                let me=this;
                me.regAct.idconceptos=id;
                me.ADnombre=me.items.find(obj => {return Object.values(obj)[0] === id}).descripcion;
                me.ADaccion=accion==1 ? "Cerrar" : "Abrir"                 
                me.adModalAbrirCerrar=1;
            },
            abrirObjeto(){
                let me=this;
                me.adModalProcesando=1;
                me.$http.put('api/Conceptos/Abrir/'+me.regAct.idconceptos,{}).then(function(response){
                    me.adModalAbrirCerrar=0;
                    me.ADaccion='';
                    me.ADnombre='';
                    me.regAct.idconceptos='';
                    me.listarObjetos();                       
                }).catch(function(error){
                    console.log(error);
                });
            },
            cerrarObjeto(){
                let me=this;
                me.adModalProcesando=1;                
                me.$http.put('api/Conceptos/Cerrar/'+me.regAct.idconceptos,{}).then(function(response){
                    me.adModalAbrirCerrar=0;
                    me.ADaccion='';
                    me.ADnombre='';
                    me.regAct.idconceptos='';
                    me.listarObjetos();                       
                }).catch(function(error){
                    console.log(error);
                });
            },
            cambiarEstadoObjeto(){
                let me=this;
                me.adModalProcesando=1;
                if (me.ADaccion=="Abrir"){
                    me.$http.put('api/Conceptos/Abrir/'+me.regAct.idconceptos,{}).then(function(response){
                    me.adModalAbrirCerrar=0;
                    me.ADaccion='';
                    me.ADnombre='';
                    me.regAct.idconceptos='';
                    me.listarObjetos();                       
                    }).catch(function(error){
                        console.log(error);
                    });
                }
                else {
                    me.$http.put('api/Conceptos/Cerrar/'+me.regAct.idconceptos,{}).then(function(response){
                    me.adModalAbrirCerrar=0;
                    me.ADaccion='';
                    me.ADnombre='';
                    me.regAct.idconceptos='';
                    me.listarObjetos();                       
                    }).catch(function(error){
                        console.log(error);
                    });
                }
            },
            mostrarPreguntaCambios(){
                let me=this;
                me.adModalPregunta=1;
            },
            ocultarPreguntaCambios(){
                let me=this;
                me.adModalProcesando=1;                
                me.listarObjetos();
                me.borrarVariables();
                me.adModalPregunta=0;
                me.adModalNuevoEditar = 0;
                me.adModalProcesando=0;
            },
            aplicarCambios(){
                let me=this;
                me.$http.put('api/Conceptos/ActualizarCambios/'+me.regAct.idconceptos,{}).then(function(response){                       
                        me.borrarVariables();
                        me.listarObjetos();
                        me.adModalPregunta=0;
                        me.adModalNuevoEditar = 0;
                        me.adModalProcesando=0;                          
                    }).catch(function(error){
                        console.log(error);
                    });    
            },
            mostrarEliminar(id){
                let me=this;
                const item=me.items.find(obj => {return Object.values(obj)[0] === id});
                me.regAct.idconceptos=id;                                
                me.ADnombre=item.descripcion;                
                me.adModalEliminar=1;
            },
            eliminarObjeto () {                
                let me=this;
                me.adModalProcesando=1;                                    
                me.$http.put('api/Conceptos/Eliminar/'+me.regAct.idconceptos,{}).then(function(response){                       
                    me.adModalEliminar=0;   
                    me.ADnombre="";
                    me.regAct.idconceptos="";
                    me.listarObjetos();                           
                }).catch(function(error){
                    console.log(error);
                });                                    
            },
            encuentraRepetición(id,campo,texto){
                let me=this;
                var sw=0;
                for(var i=0;i<me.items.length;i++){
                    if (me.items[i][campo].toUpperCase()==texto.toUpperCase() && me.items[i][id]!=me.regAct[id]){
                        sw=1;    
                    }
                }               
                return sw;
            }                          
        }        
    }
</script>